import React, {useEffect, useState} from 'react';
import {ArrowDownIcon, ArrowUpIcon, CursorArrowRaysIcon, EnvelopeOpenIcon, UsersIcon} from "@heroicons/react/24/solid";
import {clsx} from "clsx";
import {useApi, useTenant} from "../../hooks";
import Skeleton from "react-loading-skeleton";
import {formatCurrency, minutesToMs} from "../../utils/helpers";
import useSWR from "swr";
import {useCookies} from "react-cookie";
import cookieJar from "../../utils/cookieJar";
import {useActiveTenant, useTenantTheme} from "../../context/useSystemTenantStore";


const stats = [
    { id: 1, name: 'Total Subscribers', stat: '243' },
    { id: 2, name: 'Subscribers Lost', stat: '10'},
    { id: 3, name: 'Income', stat: '58.16%'},
]

const StatType = {
   number: 1,
   currency: 2
}


function DashboardCard({ title, value, statType, isLoading }) {
    const theme = useTenantTheme();


    function formatStat(stat) {
        switch(statType) {
            case StatType.currency:
                return formatCurrency(stat, true);
            case StatType.number:
            default:
                return stat.toLocaleString();
        }
    }


  return (
    <div key={title} className="px-4 py-2 sm:p-6">
      <dt className="text-base font-bold text-gray-900">{title}</dt>
      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex w-full">
        {isLoading ? (
          <Skeleton
            className={"w-full h-full"}
            containerClassName={"w-1/4 h-full"}
            baseColor={`${theme.primaryHexColor}33`}
          />
        ) : (
          <div
            style={{ color: theme.primaryHexColor }}
            className="flex items-baseline text-2xl font-semibold text-indigo-600"
          >
            {formatStat(value)}
          </div>
        )}
      </dd>
    </div>
  );
}

const SYSTEM_TENANT_DASHBOARD_URL = "systemTenant/SystemTenantDashboardInfo";
function DashboardStats(props) {
    const { fetch } = useApi();
    const [cookies] = useCookies([cookieJar.ITEMS.activeSystemTenantId]);
    const activeTenant = useActiveTenant();

    const { data, isLoading, mutate } = useSWR(cookies.activeSystemTenantId
        ? SYSTEM_TENANT_DASHBOARD_URL
        : null, fetch, {
        revalidateOnFocus: false,
        revalidateIfStale: false,
    });

    useEffect(()=>{
      mutate();
    }, [activeTenant]);

    return (
        <div className={clsx("flex flex-col justify-start mt-4")}>
            <h3 className="text-base font-semibold leading-6 text-neutral-700 ml-1">
                Last 30 days
            </h3>
            <dl
        className={clsx(
          "mt-2 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg border",
          "bg-white shadow md:grid-cols-4 md:divide-x md:divide-y-0",
          "shadow-md shadow-r-0.5 shadow-black shadow-spread-0.5",
        )}
      >
                <DashboardCard title={'Total Subscriptions'}
                               value={data?.subscriberCount || 0}
                               isLoading={isLoading}
                               statType={StatType.number}/>
                <DashboardCard title={'Subscriptions Gained'}
                               isLoading={isLoading}
                               value={data?.newSubscriberCount || 0}
                               statType={StatType.number}/>
                <DashboardCard title={'Subscriptions Lost'}
                               value={data?.lostSubscriberCount || 0}
                               isLoading={isLoading}
                               statType={StatType.number}/>
                <DashboardCard title={'Monthly Income'}
                               value={data?.monthlyIncomeAmount || 0}
                               isLoading={isLoading}
                               statType={StatType.currency}/>
        {/*{stats.map((item) => (*/}
        {/*    <DashboardCard title={item.name} item={item} isLoading={true} statType={StatType.currency} />*/}
    {/*//       <div key={item.name} className="px-4 py-2 sm:p-6">*/}
    {/*//         <dt className="text-base font-bold text-gray-900">{item.name}</dt>*/}
    {/*//         <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">*/}
    {/*//           <div*/}
    {/*//             style={{ color: theme.primaryHexColor }}*/}
    {/*//             className="flex items-baseline text-2xl font-semibold text-indigo-600"*/}
    {/*//           >*/}
    {/*//       //       {item.stat}*/}
    {/*//       //     </div>*/}
    {/*//       //   </dd>*/}
    {/*//       // </div>*/}
        {/*))}*/}
       </dl>
     </div>
  );
}

export default DashboardStats;