import React, {useEffect, useState} from 'react';
import {CardForm} from "./index";
import {isValidEmail, padCardInfo} from "../../utils/helpers";
import {useRegistrationStore} from "../../context";
import useAdminRegistrationStepStore from "../../context/useAdminRegistrationStepStore";
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import {useAlert} from "../../hooks";

function AccountRegistrationPayment(props) {
    const alert = useAlert();
    const { registrationAccount, setRegistrationAccount } = useRegistrationStore();
    const cardInfo = registrationAccount.paymentMethod || null;
    const [localPaymentMethod, setLocalPaymentMethod] = useState(cardInfo);
    const {completeStep} = useAdminRegistrationStepStore();

    function isValid(){
        if (!localPaymentMethod || !localPaymentMethod.id) {
            alert("Missing payment information", "Please add payment information to proceed", "warning");
            return false;
        }
        return true;
    }

    const storePaymentMethod = (paymentMethod) => {
        if(!paymentMethod || !paymentMethod.id) return;
        setLocalPaymentMethod(paymentMethod);
    }

    useEffect(() => {
        if(localPaymentMethod) {
            handleSave();
        }
    }, [localPaymentMethod]);

    const handleSave = async () => {
        if (!isValid()) return;
        setRegistrationAccount({
            ...registrationAccount,
            paymentMethod: localPaymentMethod
        });
        completeStep();
    };



    const [isCardHovered, setIsCardHovered] = useState(false);
    return (
        <div className={"w-10/12 md:w-9/12 space-y-4"}>
            <div className="text-primaryColor text-lg font-semibold flex flex-row justify-start border-b">Payment
                Information
            </div>
            <CardForm savePaymentMethod={storePaymentMethod}
                      paymentInfo={registrationAccount.paymentMethod}/>
        </div>
    );
}

export default AccountRegistrationPayment;