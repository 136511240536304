import { ArrowLongLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router";
import { useTenantTheme } from "../../context/useSystemTenantStore";

export default function GoBack({ display = "Go back", navigateTo }) {
  const navigate = useNavigate();
  const theme = useTenantTheme();

  return (
    <div 
      className="flex cursor-pointer hover:opacity-70 text-primaryColor my-2" 
      onClick={() => navigate(navigateTo || -1)}
      style={{color: theme ? theme.secondaryHexColor : undefined}}
    >
      <ArrowLongLeftIcon className="w-5 mr-2" />
      {display}
    </div>
  );
}
