import {
    useLayoutStore, useLoaderStore, useSubdomain,
    useTenantCustomization,
    useTenantCustomizationStore,
    useThemeConfig,
    useThemeActions, useUserStore, useTenantLoader
} from "../../context";
import {SideNavMenu, TenantTopNavMenu, TopNavMenu} from "../Menus";
import {classNames} from "../../utils/helpers";
import {Navigate, Outlet, redirect, useLocation, useNavigate} from "react-router-dom";
import useSWR from "swr";
import {useApi, useTenant, useTheme} from "../../hooks";
import React, {useEffect, useState} from "react";
import {isEqual, isNull} from "lodash";
import {isNil} from "lodash/lang";
import {useActiveTenant, useTenantTheme} from "../../context/useSystemTenantStore";
import {SubdomainGuard} from "../PreAuthorization";
import {PulseLoader} from "react-spinners";
import constants from "../../utils/constants";


export default function SystemTenantLayout({systemTenantSlug}) {
    const navigate = useNavigate();
    const location = useLocation();
    const {currentUser} = useUserStore();
    const { fetch } = useApi();
    // const theme = useTheme();
    const {subdomain, error } = useTenant();
    const theme = useTenantTheme();
    const fetchKey = subdomain ? `/systemTenant/${subdomain}` : null;
    const themeUrl = "/systemTenant/theme";
    const onNotFoundRoute = location.pathname.includes('not-found');
    const [themeCriteria, setThemeCriteria] = useState(null);
    const {tenantIsLoading} = useTenantLoader();



    if(!isNil(error) && error.status === 404 && !location.pathname.includes("not-found")) {
        return <Navigate to='/not-found' replace relative="route"/>;
    }

    return (
        <>
            <TenantTopNavMenu/>
            <div className="min-h-screen h-full w-full">
                <div className="h-full w-full">
                    <SubdomainGuard>
                        {tenantIsLoading
                            ? (<div
                                className={classNames(tenantIsLoading ? "" : "hidden", "text-xl bg-gray-50 opacity-60 z-50 absolute h-screen w-screen flex justify-center items-center")}>
                                <PulseLoader
                                    color={theme.primaryHexColor ?? constants.APP_PRIMARY_COLOR}
                                    size={40}
                                    speedMultiplier={0.75}
                                />
                            </div>)
                            : <Outlet/>}

                    </SubdomainGuard>
                </div>
            </div>
        </>

    );
}