import {
  ArrowPathIcon,
  PlusCircleIcon,
  XMarkIcon,
  CheckIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import {useState, useEffect, useMemo} from "react";
import { useNavigate } from "react-router-dom";
import { TextInput, ToggleInput, CheckboxInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { ActiveFilter, PrimaryTable } from "../components/Tables";
import { useLoaderStore } from "../context";
import {useAlert, useApi, useTenant} from "../hooks";
import constants from "../utils/constants";
import dateHelpers from "../utils/dateHelpers";
import { formatCurrency, formatPhoneNumber, switchSortDirection } from "../utils/helpers";
import {AddButton, PrimaryButton} from "../components/Buttons";
import { Popover } from "@headlessui/react";
import useSWR from "swr";
import {isNil} from "lodash/lang";
import {useCookies} from "react-cookie";
import cookieJar from "../utils/cookieJar";
import {ProductAdminSlideOver} from "../components/Menus";
import {isNull} from "lodash";
import { useRenderInfo } from "@uidotdev/usehooks";
import {ConfirmationModal, PromptModal} from "../components/Modals";
import {useActiveTenant, useTenantTheme} from "../context/useSystemTenantStore";
import useDevManager from "../context/useDevManager";

const EMPTY_FILTERS = {
  activeOnly: false,
};



export default function Products() {
  const info = useRenderInfo("Products");
  const navigate = useNavigate();
  const alert = useAlert();
  const { post, fetch } = useApi();
  const { setShowLoader } = useLoaderStore();
  const [cookies] = useCookies([cookieJar.ITEMS.activeSystemTenantId]);
  const theme = useTenantTheme();
  const { IS_DEV } = useDevManager();
  const activeTenant = useActiveTenant();
  const [activeFilters, setActiveFilters] = useState({
    ...EMPTY_FILTERS,
    activeOnly: true,
  });
  const [stagedFilters, setStagedFilters] = useState(activeFilters);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState(
    constants.SORT_DIRECTIONS.ASCENDING
  );
  const [sortField, setSortField] = useState("id");
  const [displayCount, setDisplayCount] = useState(
    constants.DISPLAY_PER_PAGE ?? 25
  );
  const SYSTEM_TENANT_ID = cookies?.activeSystemTenantId;



  const PRODUCT_LIST_URL = "product/productList";



  const payloadFilters = useMemo(() => {
    return {
      currentPage,
      sortDirection,
      ...activeFilters,
      pageSize: displayCount,
      systemTenantId: SYSTEM_TENANT_ID,
      sortField,
    }
  }, [activeFilters, sortField, currentPage, sortDirection, displayCount, SYSTEM_TENANT_ID])

  const {data, isLoading, mutate, error} = useSWR([PRODUCT_LIST_URL, payloadFilters],
      ([PRODUCT_LIST_URL, payloadFilters]) =>
      fetch(PRODUCT_LIST_URL, payloadFilters), {
    revalidateIfStale: true,
    revalidateOnFocus: false,
  });

  const [productData, setProductData] = useState(null);

  const [selectedProductId, setSelectedProductId] = useState(null);
  const [productToDelete, setProductToDelete] = useState(null);

  useEffect(() => {
    if(!isNil(data) && !error) {
      setProductData(data);
    }
  }, [data]);



  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(switchSortDirection(sortDirection));
    } else {
      setSortField(field);
    }
  };


const toggleActiveStatus = (product) => {
    setShowLoader(true);
    post(`product/DeleteProduct/${product.productId}/${SYSTEM_TENANT_ID}`)
      .then((res) => {
        alert("Success",
          res.message || "Product active status toggled",
          "success");
          mutate();
      })
      .catch((err) => {
        alert("Error",
          err.data.message || "Product active status could not be toggled",
          "error", true);
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
};
  // Insert toggleActiveStatus here

  const [showConfirmModal, setShowConfirmModal] = useState(false);


  function handlePromptModalDismiss(skipDialog = false) {
    if (skipDialog) {
      setSelectedProductId(null);
      mutate();
    } else {
      setShowConfirmModal(true);
    }
  }

  return (
    <div>
      {/* Header */}
      <PromptModal
        modalOpen={showConfirmModal}
        setModalOpen={() => setShowConfirmModal(false)}
        title={"Discard Changes"}
        message={
          "You have unsaved changes. Are you sure you want to discard them?"
        }
        onConfirm={() => setSelectedProductId(null)}
      />

      {!isNil(selectedProductId) && (
        <ProductAdminSlideOver
          open={!isNil(selectedProductId)}
          productId={selectedProductId}
          theme={theme}
          onClose={handlePromptModalDismiss}
        />
      )}
      <div className="flex justify-between items-center mt-4 px-4">
        <PageHeader title="Products" ignoreSpacing />
        <div className="mr-2">
          <AddButton
            text={"Add Product"}
            onClicked={() => setSelectedProductId(0)}
          />
        </div>
      </div>

      {/* Filter section */}
      <ProductFilterSection
        stagedFilters={stagedFilters}
        setStagedFilters={setStagedFilters}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
      />

      {/* Table */}
      <div className="px-2">
        <PrimaryTable
          isLoading={isLoading}
          totalCount={productData?.totalCount}
          displayCount={displayCount}
          currentPage={currentPage}
          setDisplayCount={setDisplayCount}
          setCurrentPage={setCurrentPage}
          header={
            <PrimaryTable.Header>
              {IS_DEV &&
              <PrimaryTable.HeaderColumn
                display="Id"
                onSort={() => handleSort("id")}
                isSortingBy={sortField === "id"}
                sortDirection={sortDirection}
              />}
              <PrimaryTable.HeaderColumn
                display="Name"
                onSort={() => handleSort("name")}
                isSortingBy={sortField === "name"}
                sortDirection={sortDirection}
              />
              <PrimaryTable.HeaderColumn
                display="Price"
                onSort={() => handleSort("price")}
                isSortingBy={sortField === "price"}
                sortDirection={sortDirection}
              />
              <PrimaryTable.HeaderColumn
                  display="Product Type"
                  onSort={() => handleSort("productType")}
                  isSortingBy={sortField === "productType"}
                  sortDirection={sortDirection}
              />
              <PrimaryTable.HeaderColumn
                  display="Duration"
                  onSort={() => handleSort("durationCount")}
                  isSortingBy={sortField === "durationCount"}
                  sortDirection={sortDirection}
              />
              <PrimaryTable.HeaderColumn
                  display="Duration Type"
                  onSort={() => handleSort("durationType")}
                  isSortingBy={sortField === "durationType"}
                  sortDirection={sortDirection}
              />
              {/* Insert column headers here */}
              <PrimaryTable.HeaderColumn display="" small/>
            </PrimaryTable.Header>
          }
        >
          {productData && 
            productData.list &&
            productData.list.map((x, i) => (
              <PrimaryTable.Row
                key={`${x.productId}-${i}`}
                index={i}
                onClick={(e) => {
                  if (!x.deactivatedAt) {
                    if (x.productId) {
                      setSelectedProductId(x.productId);
                    }
                    // navigate(`/products/${x.productId}`);
                  }
                }}
              >
                {IS_DEV && <PrimaryTable.Cell>{x.productId}</PrimaryTable.Cell>}
                <PrimaryTable.Cell>{x.name}</PrimaryTable.Cell>
                <PrimaryTable.Cell>{x.price === 0 ? "Free" : formatCurrency(x.price, true)}</PrimaryTable.Cell>
                <PrimaryTable.Cell>{x.productType}</PrimaryTable.Cell>
                <PrimaryTable.Cell>{x.durationCount}</PrimaryTable.Cell>
                <PrimaryTable.Cell>{x.durationType}</PrimaryTable.Cell>
                {/* Insert cells here */}
                <PrimaryTable.Cell center isAction>
                  <div className="flex">
                    {x.deactivatedAt ? (
                      <ArrowPathIcon
                        title="Activate"
                        className="w-4 mx-1 text-white bg-green-500 rounded-md p-0.5 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleActiveStatus(x);
                        }}
                      />
                    ) : (
                      <XMarkIcon
                        title="Deactivate"
                        className="w-6 mx-1 text-white bg-delete rounded-md p-0.5 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setProductToDelete(x);
                        }}
                      />
                    )}
                    {/* Insert flip active status here */}
                  </div>
                </PrimaryTable.Cell>
              </PrimaryTable.Row>
            ))}
        </PrimaryTable>
      </div>
      {!isNil(productToDelete) &&
        <ConfirmationModal modalOpen={productToDelete !== null}
          onCancel={() => setProductToDelete(null)}
          setModalOpen={() => setProductToDelete(null)}
          textToMatch={productToDelete?.name}
          confirmButtonText={"Delete Product"}
          message={"Type the product name to delete."}
          onConfirm={() => toggleActiveStatus(productToDelete)}
        />}
    </div>
  );
}

const ProductFilterSection = ({
  stagedFilters,
  setStagedFilters,
  activeFilters,
  setActiveFilters,
}) => {
  return (
    <div className="relative mb-4 rounded-xs shadow-sm bg-white p-4">
      <Popover as="div" className="relative">
        {({ open }) => (
          <>
            <Popover.Button className="flex items-center text-sm bg-gray-100 px-3 py-2 rounded-sm hover:bg-gray-200 relative focus:outline-none">
              <PlusIcon className="w-4 mr-1" />
              FILTERS
            </Popover.Button>
            <Popover.Panel
              as="div"
              className="absolute grid gap-y-4 z-50 w-full sm:w-1/3 origin-bottom-right bg-white rounded-md p-4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none mt-1"
            >
              <div>
                <CheckboxInput
                  name="active-only"
                  label="Show only active products"
                  checked={stagedFilters.activeOnly === true}
                  onChange={() =>
                    setStagedFilters({
                      ...stagedFilters,
                      activeOnly: stagedFilters.activeOnly ? false : true,
                    })
                  }
                />
              </div>
              <div>
                <TextInput
                  label="Name"
                  placeholder="Enter Name"
                  value={stagedFilters.name}
                  onChange={(e) =>
                    setStagedFilters({
                      ...stagedFilters,
                      name: e.target.value || undefined,
                    })
                  }
                />
              </div>
              <div>
                <TextInput
                  label="Price Greater Than"
                  placeholder="Enter Price"
                  value={stagedFilters.priceGreaterThan}
                  onChange={(e) =>
                    setStagedFilters({
                      ...stagedFilters,
                      priceGreaterThan: e.target.value || undefined,
                    })
                  }
                />
              </div>
              <div>
                <TextInput
                  label="Price Less Than"
                  placeholder="Enter Price"
                  value={stagedFilters.priceLessThan}
                  onChange={(e) =>
                    setStagedFilters({
                      ...stagedFilters,
                      priceLessThan: e.target.value || undefined,
                    })
                  }
                />
              </div>
              {/* Add filters here */}
              <PrimaryButton
                text="Apply filters"
                onClick={() => setActiveFilters(stagedFilters)}
              />
            </Popover.Panel>
          </>
        )}
      </Popover>

      {/* Selected filters */}
      {Object.values(activeFilters).find(
        (val) => val !== undefined && val !== false
      ) ? (
        <div className="flex items-center space-x-2 pt-6 text-sm">
          <div>Selected:</div>
          <ActiveFilter
            display="Show active only"
            value={
              activeFilters.activeOnly ? (
                <CheckIcon className="w-4 text-green-600" />
              ) : undefined
            }
            onClear={() =>
              setActiveFilters({
                ...activeFilters,
                activeOnly: false,
              })
            }
          />

          <ActiveFilter
            display="Name"
            value={activeFilters.name}
            onClear={() =>
              setActiveFilters({
                ...activeFilters,
                name: undefined,
              })
            }
          />
          <ActiveFilter
            display="Price greater than"
            value={activeFilters.priceGreaterThan}
            onClear={() =>
              setActiveFilters({
                ...activeFilters,
                price: undefined,
              })
            }
          />
          <ActiveFilter
            display="Price less than"
            value={activeFilters.priceLessThan}
            onClear={() =>
              setActiveFilters({
                ...activeFilters,
                priceLessThan: undefined,
              })
            }
          />

          <div
            className="cursor-pointer bg-gray-100 px-3 py-2 text-xs hover:bg-gray-200"
            onClick={() => {
              setActiveFilters({ ...EMPTY_FILTERS });
            }}
          >
            Clear all
          </div>
        </div>
      ) : (
        <div className="pt-6 text-sm text-gray-400">No active filters</div>
      )}
    </div>
  );
};
