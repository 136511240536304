import { useUserStore } from "../../context";
import { CheckIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { Listbox, Transition } from "@headlessui/react";
import { useState, Fragment, useEffect } from "react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import useSWR from "swr";
import { useApi, useTenant } from "../../hooks";
import { isNil } from "lodash/lang";
import {
  useActiveTenant,
  useTenantActions,
  useSystemTenantLists,
  useTenantTheme,
} from "../../context/useSystemTenantStore";
import Skeleton from "react-loading-skeleton";

export default function SystemTenantSelect() {
  const activeTenant = useActiveTenant();
  const theme = useTenantTheme();
  const tenantList = useSystemTenantLists();
  const { isLoading } = useTenant();
  const { setActiveSystemTenant } = useTenantActions();
  const [disabled, setDisabled] = useState(true);
  const PRIMARY_COLOR = theme?.primaryHexColor;
  const PRIMARY_BACKGROUND = `bg-[${PRIMARY_COLOR}]`;

  useEffect(() => {
    setDisabled(tenantList.length === 0);
  }, [tenantList]);

  // const dropdownDisabled = isLoading || error;
  return (
    <Listbox
      value={activeTenant}
      onChange={setActiveSystemTenant}
      disabled={disabled}
    >
      {({ open }) => (
        <>
          <div className="relative mt-2 w-full">
            <Listbox.Button className="mix-blend-normal w-full cursor-default rounded-md bg-transparent py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
              {isLoading ? (
                <Skeleton />
              ) : (
                <>
                  <span className="flex items-center mix-blend-normal">
                    {activeTenant?.logo ? (
                      <img
                        src={activeTenant?.logo}
                        // alt={`${activeTenant?.name}-logo`}
                        className="h-5 w-5 flex-shrink-0 rounded-full"
                      />
                    ) : null}
                    <span className="ml-3 block truncate font-bold text-onPrimary">
                      {activeTenant?.name}
                    </span>
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </>
              )}
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                static
              >
                {tenantList && tenantList.length
                  ? tenantList?.map((sysTenant) => (
                      <Listbox.Option
                        key={sysTenant.id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? `bg-gray-100 italic cursor-pointer`
                              : "",
                            "relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900",
                          )
                        }
                        value={sysTenant}
                      >
                        {({ selected, active }) => (
                          <>
                            <div className="flex items-center">
                              {sysTenant?.logo ?
                                  <img
                                    src={sysTenant?.logo}
                                    // alt={`${sysTenant?.name}-logo`}
                                    className="h-5 w-5 flex-shrink-0 rounded-full border-transparent"
                                  /> : null}
                              <span
                                className={classNames(
                                  sysTenant === activeTenant
                                    ? "font-semibold"
                                    : "font-normal",
                                  "ml-3 block truncate",
                                )}
                              >
                                {sysTenant?.name}
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4",
                                )}
                              >
                                {/*<CheckIcon className="h-5 w-5" aria-hidden="true" />*/}
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))
                  : null}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
