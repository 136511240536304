import React, {Fragment} from 'react';
import { Dialog, Transition } from '@headlessui/react'
import {Cog8ToothIcon, XMarkIcon} from "@heroicons/react/24/solid";
import {classNames} from "../../utils/helpers";
import {clsx} from "clsx";

function InfoSlideOver({title, children, open, onClose, initialFocusRef}) {

    return (
        <Fragment>
            <div
                className={clsx({"w-64 shadow-md shadow-l-0.5 shadow-black shadow-spread-0.5 border-l": open},
                    {"w-0 display-hidden": !open},
                    "fixed top-0 right-0 flex min-h-0 flex-1 flex-col  border-gray-200 bg-white float-right z-10",
                    "h-full transform duration-200 ",
                )}
            >
                <div className="flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
                    {title}
                </div>
                <hr />
                <div>
                    {children}
                </div>
            </div>
        </Fragment>
    );
}

export default InfoSlideOver;