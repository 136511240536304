import {useSubdomain, useThemeConfig} from "../../context";
import {Link} from "react-router-dom";
import {isNil} from "lodash/lang";
import {useActiveTenant, useTenantTheme} from "../../context/useSystemTenantStore";
import {useApi, useTenant} from "../../hooks";
import Skeleton from "react-loading-skeleton";


export default function SystemTenantNameDisplay() {
    const { isLoading } = useTenant();
    const activeTenant = useActiveTenant();
    const theme = useTenantTheme();
    const {fetch} = useApi();
    const onNotFoundRoute = window.location.pathname.includes("not-found");



    if(isNil(theme)) return <></>;
    if(onNotFoundRoute) {
        return (
            <div className="grid-cols-2 gap-2 flex">
                <Link to="/" className="flex flex-shrink-0 items-center">
                    {isLoading
                        ? <Skeleton/>
                        : theme?.logo ? <img className="h-10 object-contain" src={theme?.logo} alt="logo"/> : null}
                </Link>

                <div className="flex items-center ml-2">
                    <h2 className="font-bold mb-1 text-2xl text-white">
                        {activeTenant?.name || <Skeleton/>}
                    </h2>
                </div>
            </div>
        );
    }
    return (
        <div className="grid-cols-2 gap-2 flex">
            <Link to="/" className="flex flex-shrink-0 items-center">
                {isLoading
                    ? <Skeleton/>
                    : theme?.logo ? <img className="h-10 object-contain" src={theme?.logo} alt="logo"/> : null}
                    </Link>

                    <div className="flex items-center ml-2">
                    <h2 className="font-bold mb-1 text-2xl text-white">
                {activeTenant?.name || <Skeleton/>}
            </h2>
        </div>
{/*{theme?.systemTenantName &&*/
}
{/*(<div className="flex items-center">*/
}
{/*    <h2 className="font-bold mb-1 text-2xl">*/}
    {/*        {theme?.systemTenantName}*/}
    {/*    </h2>*/}
    {/*</div>)}*/}
        </div>
    );

}