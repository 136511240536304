function checkCardForExpiration(month, year) {
    if (month === undefined || year === undefined) return true;
  
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // Months are zero-based (0 = January, 1 = February, ...)
  
    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      return true;
    }
  
    return false;
};

function getCardExpiration(month, year) {
    if (month === undefined || year === undefined) return "";
    const formattedMonth = month.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const formattedYear = (year % 100).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  
    return `${formattedMonth}/${formattedYear}`;
  }

export function isCardExpired(card){
  return checkCardForExpiration(
    card?.card?.expMonth || card?.card?.exp_month,
    card?.card?.expYear || card?.card?.exp_year
  );
};

export function formatStripeExpiration(card) {
  return getCardExpiration(
    card?.card?.expMonth || card?.card?.exp_month,
    card?.card?.expYear || card?.card?.exp_year
  );
}