import { create } from "zustand";
import { JsonTryParse } from "../utils/helpers";
import storage from "../utils/storage";
import { persist } from "zustand/middleware";
import cookieJar from "../utils/cookieJar";
import { isEqual, isNil } from "lodash";

const BASIC_SYS_TENANT = {
  id: null,
  name: null,
  logo: null,
  imageKey: null,
  primaryHexColor: null,
  secondaryHexColor: null,
  tertiaryHexColor: null,
  slug: null,
  accountId: null,
  header: null,
  subHeader: null
};

const useTenantStore = create(
  persist(
    (set, get) => ({
      systemTenants: [],
      activeSystemTenant: BASIC_SYS_TENANT,
      actions: {
        setActiveSystemTenant: (activeSystemTenant) => {
          set(() => ({ activeSystemTenant: activeSystemTenant }));
          cookieJar.setItem("activeSystemTenantId", activeSystemTenant.id);
        },
        setSystemTenants: (systemTenants) => {
          if (isNil(systemTenants)) return;
          if (isEqual(get().activeSystemTenant, BASIC_SYS_TENANT)) {
            const firstSystemTenant = systemTenants[0];
            set(() => ({ activeSystemTenant: firstSystemTenant, systemTenants }));
            cookieJar.setItem("activeSystemTenantId", firstSystemTenant.id);
          } else {
            set(() => ({ systemTenants }));
          }
        },
        clearSystemTenants: () => {
          set(() => ({
            systemTenants: [],
            activeSystemTenant: BASIC_SYS_TENANT,
          }));
        },
        hasActiveTenant: () => {
          return get().activeSystemTenant !== BASIC_SYS_TENANT;
        },
      },
    }),
    {
      name: "tenant-store",
      partialize: ({ actions, ...rest }) => rest,
    },
  ),
);

export const useActiveTenant = () =>
  useTenantStore((state) => state.activeSystemTenant);

export const useSystemTenantLists = () =>
  useTenantStore((state) => state.systemTenants);

export const useTenantActions = () => useTenantStore((state) => state.actions);

export const useTenantTheme = () => useTenantStore((state) => {
  return {
    primaryHexColor: state.activeSystemTenant.primaryHexColor,
    secondaryHexColor: state.activeSystemTenant.secondaryHexColor,
    tertiaryHexColor: state.activeSystemTenant.tertiaryHexColor,
    logo: state.activeSystemTenant.logo,
    header: state.activeSystemTenant.header,
    subHeader: state.activeSystemTenant.subHeader
  }
});

export default useTenantStore;
