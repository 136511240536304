import { LOGO } from "../assets/images";

const APP_NAME = "Markt";
const APP_PRIMARY_COLOR = "#8b572a";
const APP_SLOGAN =
  "This is a placeholder slogan. Have the client write something cool here.";
const APP_LOGO = LOGO;
const DEV_DOMAIN = "localtest";

const SORT_DIRECTIONS = {
  ASCENDING: "ASC",
  DESCENDING: "DESC",
};

const DISPLAY_PER_PAGE = 25;

const ROLES = [
  { label: "System Administrator", value: 1 },
  { label: "Admin", value: 2 },
  { label: "Customer", value: 3 },
];

const ROLE_IDS = {
  SYS_ADMIN: 1,
  ADMIN: 2,
  CUSTOMER: 3,
};

const NOTIFICATION_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
};

const DURATION_TYPES = [
  { label: "Minutes", value: "Minutes" },
  { label: "Hours", value: "Hours" },
  { label: "Days", value: "Days" },
  { label: "Months", value: "Months" },
  { label: "Years", value: "Years" },
];
// const DURATIONTYPES = [
//   { label: "Minutes", value: 1 },
//   { label: "Hours", value: 2 },
//   { label: "Days", value: 3 },
//   { label: "Months", value: 4 },
//   { label: "Years", value: 5 },
// ];

const PRODUCT_TYPES = [
  { label: "Primary License", value: "PrimaryLicense" },
  { label: "Feature Supplement", value: "FeatureSupplement" },
  { label: "Item", value: "Item" },
];
// const PRODUCTTYPES = [
//   { label: "Primary License", value: 1 },
//   { label: "Feature Supplement", value: 2 },
//   { label: "Item", value: 3 },
// ];

const NOTIFICATIONMETHODS = [
  { label: "Email", value: 1 },
  { label: "SMS", value: 2 },
  { label: "Email and SMS", value: 3 },
  { label: "None", value: 4 },
];

const OWNEDPRODUCTSTATUSS = [
  { label: "Pending", value: 1 },
  { label: "Paid", value: 2 },
  { label: "Failed", value: 3 },
  { label: "Refunded", value: 4 },
  { label: "Partial Refund", value: 5 },
  { label: "Delinquent", value: 6 },
  { label: "Expired", value: 7 },
];

const MAX_PAYMENT_TYPES = 3;

const INTERVAL_TYPES = [
  { label: "Day", value: 1 },
  { label: "Week", value: 2 },
  { label: "Month", value: 3 },
  { label: "Year", value: 4 },
  { label: "YTD", value: 5 },
];

const REFUND_REASONS = [
  { label: "Requested By Customer", value: "RequestedByCustomer" },
  { label: "Duplicate", value: "Duplicate" },
  { label: "Fraudulent", value: "Fraudulent" },
];

const Explanation = {
  renew: `This defines the window of time prior to primary subscription expiration during which automatic renewal payments will be attempted.
      Say Jason's subscription expires on Friday morning at 8AM and he has indicated that he'd like to renew.
      We will begin attempting to process his payment on file per his intent this number of minutes prior to that expiration.
      If this setting is 1440 (aka 1 day) then the attempt to purchase the renewal subscription will occur a bit after 8AM on Thursday.
      It is recommended that at least 2 or 3 days of time be set here so that any payment issues can be corrected prior to license expiration. (Default set to 4 days)`,
  warnCancel: `This defines the window of time prior to primary subscription expiration during which a pending expiration/cancellation notification will be sent to someone NOT intending to renew.
Say Jason's subscription expires on Friday morning at 8AM and he has indicated that he will not be renewing to any subscription product.
We may send a cancellation notification to him according to his defined preferences beginning at Monday at 8AM if this setting is 7200 (aka 5 days). (Default set to 7 days)`,
  warnRenewal: `This defines the window of time prior to primary subscription expiration during which a pending renewal notification will be sent to someone intending to renew.
Say Jason's subscription expires on Friday morning at 8AM and he has indicated that he'd like to renew.
We may send a renewal notification to him according to his defined preferences beginning at Monday at 8AM if this setting is 7200 (aka 5 days).
Note that this warning of renewal notification will only ever be sent once, at most, per subscription renewal.
It is also necessary to choose a setting here that is more than the Process Renewal Minutes Threshold value because we no longer attempt to
warn of pending renewal once the renewal processing window threshold has passed.
For instance, if the Process Renewal Minutes Threshold is 3 days and the Warning of Renewal Minutes Threshold is 4 days only the 1 day of additional
anticipation time will be considered usable for sending the warning notification. (Default set to 7 days)`,
  grace: `This is the number of extra minutes of access permitted after a primary subscription license has expired before the expired license is actually cancelled.
A scenario in which this would be relevant is a failed renewal payment.
Say Tom's payment instrument on file is declined when it was processed for a renewal 2880 minutes (aka 2 days) prior to its expiration.
(By the way, that 2880 minutes is the Process Renewal Threshold Minutes setting.) Tom will receive a delinquent notification and given a chance to
provide a working payment instrument for this period of time before he loses his subscription and his access.
Note that we will only re-attempt delinquent payments once every 24 hours. (Default set to 4 days)`,
  emailNotification: `This is an email address used only when a notification or other processing failure occurs for this account. Email will be used preferentially if it is provided.`,
  mobileNotification: `This is a mobile phone number used only when a notification or other processing failure occurs for this account. Email will be used preferentially if it is provided.`,
};


const DEFAULT_EMPTY_TAG = {
  description: null,
  imageFileKey: null
};

const US_TIMEZONES = [
  { label: "Eastern Standard Time", value: "Eastern Standard Time"},
  { label: "Central Standard Time", value: "Central Standard Time"},
  { label: "US Mountain Standard Time", value: "US Mountain Standard Time"},
  { label: "Mountain Standard Time", value: "Mountain Standard Time"},
  { label: "Pacific Standard Time", value: "Pacific Standard Time"},
  { label: "Alaskan Standard Time", value: "Alaskan Standard Time"},
];

const constants = {
  APP_NAME,
  APP_SLOGAN,
  APP_PRIMARY_COLOR,
  APP_LOGO,
  DEV_DOMAIN,
  DISPLAY_PER_PAGE,
  INTERVAL_TYPES,
  MAX_PAYMENT_TYPES,
  NOTIFICATION_TYPES,
  SORT_DIRECTIONS,
  ROLES,
  DURATION_TYPES,
  NOTIFICATIONMETHODS,
  PRODUCT_TYPES,
  OWNEDPRODUCTSTATUSS,
  REFUND_REASONS,
  ROLE_IDS,
  Explanation,
  DEFAULT_EMPTY_TAG,
  US_TIMEZONES
};

export default constants;
