import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { GoBack, PrimaryButton } from "../components/Buttons";
import { CollapsibleSection } from "../components/Cards";
import { PhoneInput, SelectInput, TextInput, NumberInput, CheckboxInput, DateInput, PasswordInput, TelephoneInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { useLoaderStore } from "../context";
import {useAlert, useApi, useTenant} from "../hooks";
import constants from "../utils/constants";
import { convertToIdNameList, isValidEmail, convertToLabelValueList, convertToLabelValue } from "../utils/helpers";
import {useActiveTenant} from "../context/useSystemTenantStore";
import { isPossiblePhoneNumber } from "react-phone-number-input";

export default function SystemTenant() {
  let { id } = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const isCreatingNew = id === "0";

  const { fetch, post } = useApi();
  const { setShowLoader } = useLoaderStore();

  const [systemTenant, setSystemTenant] = useState({ id });
  const [slugChanged, setSlugChanged] = useState(false);
  const [freeProducts, setFreeProducts] = useState([]);
  const activeTenant = useActiveTenant();
  const [selectedCountry, setSelectedCountry] = useState('US');

  const SYSTEM_TENANT_ID = activeTenant?.id;

  // add state variables here

  useEffect(() => {
    if (isCreatingNew) return;
    setShowLoader(true);
    getSystemTenant();
    getFreeProducts();
  }, []);

  // add swr calls here
  const getSystemTenant = () => {
    fetch(`systemTenant/systemTenantInfo/${id}/${true}`)
      .then((res) => {
        setSystemTenant(res);
      })
      .catch(() => {
        alert("Server error", "Could not retrieve systemTenant", "error");
      })
      .finally(() => setShowLoader(false));
  };

  const getFreeProducts = () => {
    if (id === "0") return;
    fetch(`systemTenant/GetSystemTenantFreeProducts/${id}`)
      .then((res) => {
        let productList = convertToLabelValueList(res);
        setFreeProducts(productList);
      })
      .catch(() => {
        alert("Server error", "Could not retrieve free product list", "error");
      })
      .finally(() => setShowLoader(false));
  };

  const handleChange = (property, value) => {
    
    setSystemTenant({
      ...systemTenant,
      [property]: value,
    });
  };

  const handleCredentialChange = (property, value) => {
    let copy = {
      ...systemTenant
    };
    if(!copy.credentials) copy.credentials = {};
    copy.credentials[property] = value;
    setSystemTenant(copy);
  };

  const handleSave = () => {
    if (!isValid()) return;

    setShowLoader(true);
    post(`systemTenant/saveSystemTenant/${SYSTEM_TENANT_ID}`, systemTenant)
      .then((res) => {
        alert("Success", "Saved systemTenant", "success");
        navigate("/systemTenants");
      })
      .catch((err) => {
        alert(
          "Unable to save systemTenant",
          err.data.message || "Please try again",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = () => {
    if (!systemTenant.name || !systemTenant.name.trim()) {
      alert("Form invalid", "Name must have a value", "warning");
      return false;
    }
    if (!systemTenant.processRenewalMinutesThreshold) {
      alert("Form invalid", "Process Renewal Minutes Threshold must have a value", "warning");
      return false;
    }
    if (!systemTenant.accountId) {
      alert("Form invalid", "Account Id must have a value", "warning");
      return false;
    }
    
    if (!systemTenant.adminEmail || !systemTenant.adminEmail.trim() || !isValidEmail(systemTenant.adminEmail)) {
      alert("Invalid input", "Please enter a valid email", "warning");
      return false;
    }
    if (!systemTenant.adminMobilePhone || !systemTenant.adminMobilePhone.trim()) {
      alert("Invalid input", "Please enter a valid phone number", "warning");
      return false;
    }
    if (!isPossiblePhoneNumber(systemTenant.adminMobilePhone.trim(), selectedCountry)) {
      alert("Invalid input", "Please enter a valid phone number", "warning");
      return false;
    }
    if (
      systemTenant.processRenewalMinutesThreshold < 0 ||
      systemTenant.processRenewalMinutesThreshold > 43200
    ) {
      alert(
        "Invalid input",
        "Renewal hours must be between 0 and 43200 (1 month)",
        "warning",
      );
      return false;
    }

    return true;
  };

  return (
    <div>
      {/* Header */}
      <PageHeader
        title={
          isCreatingNew || !systemTenant ? "Create new systemTenant" : `Manage systemTenant ${systemTenant.id}`
        }
      />
      <GoBack display="Manage all systemTenants" navigateTo="/systemTenants" />

      <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
        {/* Form */}
        <CollapsibleSection title="Info">
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
            <div>
              <TextInput
                label="Name"
                value={systemTenant?.name ?? ""}
                onChange={(e) => handleChange("name", e.target.value)}
                required
                maxLength={200}
              />
            </div>
            <div>
              <SelectInput
                label="Free Default Product License Id"
                options={freeProducts}
                value={freeProducts.find(x => x.value === systemTenant?.freeDefaultProductLicenseId)}
                onChange={(e) => handleChange("freeDefaultProductLicenseId", e.value)}
                onClear={() => handleChange("freeDefaultProductLicenseId", null)}
                isSearchable
              />
            </div>
            <div>
              <TextInput
                label="Email"
                value={systemTenant?.adminEmail ?? ""}
                onChange={(e) => handleChange("adminEmail", e.target.value)}
                maxLength={150}
              />
            </div>
            <div>
              <TelephoneInput
                label="Mobile Phone"
                value={systemTenant?.adminMobilePhone ?? ""}
                onChange={(e) => handleChange("adminMobilePhone", e.target.value)}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                maxLength={20}
              />
            </div>
            {/* <div>
              <PasswordInput
                label="Stripe Api Key"
                value={systemTenant?.credentials?.stripeApiKey ?? ""}
                onChange={(e) => handleCredentialChange("stripeApiKey", e.target.value)}
                required
                maxLength={150}
              />
            </div> */}
            {/* <div>
              <NumberInput
                label="Delinquent Grace Period Minutes"
                value={systemTenant?.delinquentGracePeriodMinutes ?? ""}
                onChange={(e) => handleChange("delinquentGracePeriodMinutes", e.target.valueAsNumber)}
              />
            </div>
            <div>
              <NumberInput
                label="Warn Renewal Minutes Threshold"
                value={systemTenant?.warnRenewalMinutesThreshold ?? ""}
                onChange={(e) => handleChange("warnRenewalMinutesThreshold", e.target.valueAsNumber)}
              />
            </div>
            <div>
              <NumberInput
                label="Warn Renewal Cancellation Threshold"
                value={systemTenant?.warnCancellationMinutesThreshold ?? ""}
                onChange={(e) => handleChange("warnCancellationMinutesThreshold", e.target.valueAsNumber)}
              />
            </div>
            <div>
              <NumberInput
                label="Process Renewal Minutes Threshold"
                value={systemTenant?.processRenewalMinutesThreshold ?? ""}
                onChange={(e) => handleChange("processRenewalMinutesThreshold", e.target.valueAsNumber)}
                required
              />
            </div> */}
          </div>
        </CollapsibleSection>

        {/* Submit button */}
        <div className="mt-12 pb-4 px-4">
          <PrimaryButton
            text={isCreatingNew ? "Create" : "Save"}
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
}
