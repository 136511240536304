import {clsx} from "clsx";

export default function PageHeader({
  title,
  className,
  ignoreSpacing,
}) {
  return(
    <div className={clsx({"ml-4 mt-4": !ignoreSpacing}, className)}>
      <h4 className="text-xl w-full py-2 text-gray-500 uppercase">{title}</h4>
    </div>
  );
}