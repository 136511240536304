import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { GoBack, PrimaryButton } from "../components/Buttons";
import { CollapsibleSection } from "../components/Cards";
import { PhoneInput, SelectInput, TextInput, NumberInput, CheckboxInput, DateInput, FileUpload, Label } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { useLoaderStore } from "../context";
import {useAlert, useApi, useTenant} from "../hooks";
import constants from "../utils/constants";
import { convertToIdNameList, isValidEmail, convertToLabelValueList, convertToLabelValue, getFormData, JsonTryParse } from "../utils/helpers";
import {useActiveTenant} from "../context/useSystemTenantStore";

export default function Product() {
  let { id } = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const isCreatingNew = id === "0";

  const { fetch, post, postFormData } = useApi();
  const activeTenant = useActiveTenant();
  const { setShowLoader } = useLoaderStore();
  const SYSTEM_TENANT_ID = activeTenant?.id;
  const [product, setProduct] = useState({ productId: id, systemTenantId: SYSTEM_TENANT_ID });
  const [pictureFiles, setPictureFiles] = useState([]);
  const [imgUrl, setImgUrl] = useState();

  // add state variables here

  useEffect(() => {
    if (isCreatingNew) return;
    setShowLoader(true);
    fetch(`product/productInfo/${id}/${SYSTEM_TENANT_ID}`)
      .then((res) => {
        var fetchedProduct = Object.assign({}, product, res);
        fetchedProduct.durationType = constants.DURATION_TYPES.find(x => x.label === fetchedProduct.durationType)?.value || fetchedProduct.durationType;
        fetchedProduct.productType = constants.PRODUCT_TYPES.find(x => x.label === fetchedProduct.productType)?.value || fetchedProduct.productType;
        fetchedProduct.tag = JsonTryParse(fetchedProduct.tag) || {};
        setProduct(fetchedProduct);
        getProductImgUrl(fetchedProduct);
      })
      .catch(() => {
        alert("Server error", "Could not retrieve product", "error");
      })
      .finally(() => setShowLoader(false));
  }, []);

  // useEffect(() => {
  //   getProductImgUrl();
  // }, []);

  const handleChange = (property, value) => {
    setProduct({
      ...product,
      [property]: value,
    });
  };

  const handleTagChange = (property, value) => {
    let copy = {...product};
    copy.tag[property] = value; 
    setProduct(copy);
  };

  const handleSave = () => {
    saveProduct();
    if (pictureFiles.length !== 0) {
      saveProductImage();
    }
  }

  const saveProductImage = () => {
    let payload = getFormData(pictureFiles[0], "Photo");
    payload.append("ProductId", product?.productId);
    postFormData("product/SaveProductImage", payload)
    .then(res => {
      let copy = {...product};
      copy.tag.imageFilekey = res;
      setPictureFiles([]);
      saveProduct(copy);
    })
    .catch(err => {
      alert("Server error", "Unable to save product image", "error");
    });
  }

  const saveProduct = (overrideProduct = undefined) => {
    if (!isValid()) return;

    setShowLoader(true);
    let payload = Object.assign({}, overrideProduct || product, { tag: JSON.stringify(product.tag) });
    post(`product/saveProduct/${SYSTEM_TENANT_ID}`, payload)
      .then((res) => {
        alert("Success", "Saved product", "success");
        var fetchedProduct = Object.assign({}, product, res);
        fetchedProduct.durationType = constants.DURATION_TYPES.find(x => x.label === fetchedProduct.durationType)?.value || fetchedProduct.durationType;
        fetchedProduct.productType = constants.PRODUCT_TYPES.find(x => x.label === fetchedProduct.productType)?.value || fetchedProduct.productType;
        fetchedProduct.tag = JsonTryParse(fetchedProduct.tag) || {};
        setProduct(fetchedProduct);
        getProductImgUrl(fetchedProduct);
      })
      .catch((err) => {
        alert(
          "Unable to save product",
          err.data.message || "Please try again",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = () => {
    if (!product.systemTenantId) {
      alert("Form invalid", "System Tenant Id must have a value", "warning");
      return false;
    }
    if (!product.name || !product.name.trim()) {
      alert("Form invalid", "Name must have a value", "warning");
      return false;
    }
    if (product.price === null || product.price === undefined || product.price < 0 ) {
      alert("Form invalid", "Price must have a value", "warning");
      return false;
    }
    if (!product.productType) {
      alert("Form invalid", "Product Type Id must have a value", "warning");
      return false;
    }
    // if (!product.durationType) {
    //   alert("Form invalid", "Duration Type Id must have a value", "warning");
    //   return false;
    // }
    // if (!product.durationCount) {
    //   alert("Form invalid", "Duration Count must have a value", "warning");
    //   return false;
    // }
    // if (!product.supplementalFeatureQty) {
    //   alert("Form invalid", "Supplemental Feature Qty must have a value", "warning");
    //   return false;
    // }

    return true;
  };

  const getProductImgUrl = (productParam) => {
    if (!productParam?.tag?.imageFilekey) return;
    fetch(`product/GetProductSignedUrl/${productParam.tag.imageFilekey}`)
    .then(res => {
      setImgUrl(res);
    })
    .catch(err => {
      alert("Server error", "Unable to fetch product image", "error")
    });
  };

  return (
    <div>
      {/* Header */}
      <PageHeader
        title={
          isCreatingNew || !product ? "Create new product" : `Manage product ${product.productId}`
        }
      />
      <GoBack display="Manage all products" navigateTo="/products" />

      <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
        {/* Form */}
        <CollapsibleSection title="Info">
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
            {imgUrl && (<div className="col-span1 md:col-span2 lg:col-span-3 flex justify-self-center">
              <img
                className="max-w-xs lg:max-w-md justify-content-center p-4"
                src={imgUrl}
              />
            </div>)}
            {/* <div>
              <NumberInput
                label="System Tenant Id"
                value={product?.systemTenantId ?? ""}
                onChange={(e) => handleChange("systemTenantId", e.target.valueAsNumber)}
                required
              />
            </div> */}
            <div>
              <TextInput
                label="Name"
                value={product?.name ?? ""}
                onChange={(e) => handleChange("name", e.target.value)}
                required
                maxLength={250}
              />
            </div>
            <div>
              <NumberInput
                label="Price"
                value={product?.price ?? ""}
                onChange={(e) => handleChange("price", e.target.valueAsNumber)}
                required
                min={0}
              />
            </div>
            <div>
              <SelectInput
                label={"Duration Interval"}
                options={constants.DURATION_TYPES}
                value={constants.DURATION_TYPES.find(x => x.value === product?.durationType)}
                onChange={(e) => handleChange("durationType", e.value)}
                onClear={() => handleChange("durationType", null)}
                isSearchable
                required
              />
            </div>
            <div>
              <NumberInput
                label="Duration Count"
                value={product?.durationCount ?? ""}
                onChange={(e) => handleChange("durationCount", e.target.valueAsNumber)}
                required
                min={0}
              />
            </div>
            <div>
              <SelectInput
                label={"Product Type"}
                options={constants.PRODUCT_TYPES}
                value={constants.PRODUCT_TYPES.find(x => x.value === product?.productType)}
                onChange={(e) => handleChange("productType", e.value)}
                onClear={() => handleChange("productType", null)}
                isSearchable
                required
              />
            </div>
            <div>
              <NumberInput
                label="Supplemental Feature Qty"
                value={product?.supplementalFeatureQty ?? ""}
                onChange={(e) => handleChange("supplementalFeatureQty", e.target.valueAsNumber)}
                required
              />
            </div>
            <div>
              <TextInput
                label="Description"
                value={product?.tag?.description ?? ""}
                onChange={(e) => handleTagChange("description", e.target.value)}
                maxLength={1000}
              />
            </div>
            <div className="col-start-1">
              <Label label={"Image"} />
              <FileUpload
                files={pictureFiles}
                setFiles={setPictureFiles}
                allowMultipleFiles={false}
                maxFileCount={1}
              />
            </div>
          </div>
        </CollapsibleSection>

        {/* Submit button */}
        <div className="mt-12 pb-4 px-4">
          <PrimaryButton
            text={isCreatingNew ? "Create" : "Save"}
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
}
