import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { Bank2, CreditCardFill, Icon } from "react-bootstrap-icons";
import { ModalWrapper } from ".";
import {useAlert, useApi, useTenant} from "../../hooks";
import { classNames } from "../../utils/helpers";
import { PrimaryButton } from "../Buttons";
import { CheckboxInput } from "../Inputs";
import { useThemeConfig, useUserStore } from "../../context";
import {useTenantTheme} from "../../context/useSystemTenantStore";

const PaymentMethodOption = ({
  isSelected,
  title,
  Icon,
  disabled,
  onClick,
  primaryColor
}) => {
  return (
    <div
      onClick={disabled ? () => {} : onClick}
      style={{borderColor: primaryColor}}
      className={classNames(
        "mr-3 w-36 rounded-md bg-backgroundColor border p-4",
        isSelected ? `` : "hover:bg-gray-200",
        disabled ? "cursor-not-allowed" : "cursor-pointer "
      )}
    >
      <Icon
        style={{color: primaryColor}}
        className={classNames(
          "h-5 w-5",
          isSelected ? `` : "text-gray-700"
        )}
      />
      <div
        style={{color: primaryColor}}
        className={classNames(
          "mt-1.5 text-sm",
          isSelected ? `` : "text-gray-700"
        )}
      >
        {title}
      </div>
      {disabled && (
        <div className="text-sm italic text-gray-500">Not available yet</div>
      )}
    </div>
  );
};

const CardInput = ({ label, children }) => {
  return (
    <div>
      <div className="mb-2">{label}</div>
      <div className="rounded-md bg-gray-100 p-2 border border-gray-200 shadow-sm outline-1 outline-fabricPink hover:bg-gray-200 focus:outline">
        {children}
      </div>
    </div>
  );
};

export default function PaymentMethodModal({
  modalOpen,
  existingPaymentMethodCount,
  refreshData,
  setModalOpen,
  getPaymentMethodCallback,
  savePaymentMethod
}) {
  const alert = useAlert();
  const { post } = useApi();
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTenantTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(true);
  const [paymentMethodType, setPaymentMethodType] = useState(
    "card"
  );
  const PRIMARY_COLOR = theme?.primaryHexColor ? theme.primaryHexColor : `primaryColor`;
  const handleCardSubmit = async () => {
    setIsLoading(true);
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);

    if (!cardNumberElement || !cardCvcElement || !cardExpiryElement) return;

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });

    if (result.error) {
      alert(
        "Error",
        result.error.message ||
          "An error occurred while attaching the payment method",
        "ERROR"
      );
      setIsLoading(false);
      return;
    }
    if(getPaymentMethodCallback) {
      getPaymentMethodCallback(result.paymentMethod);
      setIsLoading(false);
      return;
    } 
    savePaymentMethod(result.paymentMethod.id, defaultPaymentMethod);
    setIsLoading(false);
  }
  
  return (
    <ModalWrapper open={modalOpen} setOpen={setModalOpen}>
      <div className="w-[85vw] lg:w-[35vw]">
        {/* Payment type options */}
        {/* <div className="mb-6 flex">
          <PaymentMethodOption
            isSelected={paymentMethodType === "card"}
            title="Card"
            Icon={CreditCardFill}
            onClick={() => setPaymentMethodType("card")}
            primaryColor={PRIMARY_COLOR}
          />
          <PaymentMethodOption
            isSelected={paymentMethodType === "ach"}
            title="US bank account"
            Icon={Bank2}
            onClick={() => setPaymentMethodType("ach")}
            disabled
            primaryColor={PRIMARY_COLOR}
          />
        </div> */}

        {paymentMethodType === "card" && (
          <>
            <div>
              <div className="grid gap-y-4">
                <CardInput label="Card number">
                  <CardNumberElement options={{ showIcon: true }} />
                </CardInput>
                <div className="grid grid-cols-4 gap-x-4">
                  <div className="col-span-4 md:col-span-2">
                    <CardInput label="Expiration date">
                      <CardExpiryElement />
                    </CardInput>
                  </div>
                  <div className="col-span-4 md:col-span-1 mt-2 md:mt-auto">
                    <CardInput label="Security code (CVC)">
                      <CardCvcElement />
                    </CardInput>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <CheckboxInput
                label="Set as default payment method"
                checked={defaultPaymentMethod ? "checked" : "unchecked"}
                onChange={() => setDefaultPaymentMethod(!defaultPaymentMethod)}
              />
            </div>
            <div className="mt-8">
              <PrimaryButton
                text="Add payment method"
                onClick={handleCardSubmit}
                disabled={isLoading}
              />
            </div>
          </>
        )}
      </div>
    </ModalWrapper>
  );
}
