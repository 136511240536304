import { useEffect, useMemo, useState } from "react";
import {XCircleIcon} from "@heroicons/react/24/outline";
import {CheckCircleIcon, CheckIcon, Square2StackIcon} from "@heroicons/react/24/solid";
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline";
import {useAlert, useApi, useTenant} from "../../hooks";
import { useNavigate, useParams } from "react-router";
import { useLoaderStore, useSystemTenantStore, useTheme, useUserStore } from "../../context";
import { PageHeader } from "../../components/Layouts";
import dateHelpers from "../../utils/dateHelpers";
import { formatCurrency } from "../../utils/helpers";
import { isNil } from "lodash";
import { ConfirmationModal, ModalWrapper } from "../../components/Modals";
import { NumberInput, SelectInput, TextInput } from "../../components/Inputs";
import { GoBack, PrimaryButton } from "../../components/Buttons";
import constants from "../../utils/constants";
import {useActiveTenant, useTenantTheme} from "../../context/useSystemTenantStore";

function SystemTenantCustomer() {
    const { id } = useParams();
    const { fetch, post } = useApi();
    const alert = useAlert();
    const { currentUser, impersonating, impersonate} = useUserStore();
    const { setShowLoader } = useLoaderStore();
    const navigate = useNavigate();
    const activeTenant = useActiveTenant();
    const theme = useTenantTheme();
    const [customer, setCustomer] = useState();
    const [selectedProduct, setSelectedProduct] = useState();
    const [payments, setPayments] = useState([])
    const [activeProducts, setActiveProducts] = useState([])
    const [pastProducts, setPastProducts] = useState([])
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const [refundModalOpen, setRefundModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        getCustomer();
        getCustomerPurchaseHistory();
    }, []);

    function getCustomer() {
        fetch(`systemTenant/GetSystemTenantCustomer/${id}/${activeTenant?.id}`)
        .then(res => {
            setCustomer(res);
        })
        .catch(err => {
            alert("Server error", "Error fetching customer information", "error");
        });
    }

    function getCustomerPurchaseHistory(){
        fetch(`systemTenant/GetCustomerPurchaseHistoryBySystemTenant/${id}/${activeTenant?.id}`)
        .then(res => {
            if (res.payments && res.payments.length) {
                res.payments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setPayments(res.payments);
            }
            if(res.products && res.products.length) {
                let oldProducts = res.products.filter(p => (
                    !res.activeProducts.some(ap => ap.productId === p.productId)));
                oldProducts.sort((a, b) => new Date(b.effectiveAt) - new Date(a.effectiveAt));
                setPastProducts(oldProducts);
            }
            if (res.activeProducts && res.activeProducts.length) {
                setActiveProducts(res.activeProducts);
                let subscriptions = res.activeProducts.filter(x => x.productType === "Primary License");
                let paidProducts = subscriptions.filter(x => x.price > 0);
                if (paidProducts.length) {
                    paidProducts.sort((a, b) => a.effectiveAt - b.effectiveAt);
                    // setActiveSubscription(paidProducts[0]);
                    return;
                }
                subscriptions.sort((a, b) => a.effectiveAt - b.effectiveAt);
                // setActiveSubscription(subscriptions[0]);
            }
        })
        .catch(err => {
            alert("Server error", "Unable to retrieve purchase history", "error");
        })
    }

    function sendForgotPasswordEmail() {
        post("public/forgotPassword", customer.email)
        .then((res) => {
            alert("Success!", "Check your inbox for a reset password email", "success", false);
        })
        .catch((err) => {
            alert("Server error", "Unable to send a reset password email", "error");
        })
        .finally(() => {
        // setShowLoader(false);
        });
    }

    function processRefund(refundAmount, refundReason) {
        if(isLoading) return;
        if (!selectedProduct) {
            alert("Error", "Could not identify which product to refund", "warning");
            return;
        }
        if (!refundAmount) {
            alert("Error", "Invalid refund amount", "warning");
            return;
        }
        if (refundAmount > selectedProduct.price) {
            alert("Error", "Invalid refund amount, amount is greater than the product's price", "warning");
            return; 
        }
        let payload = {
            PartialRefundAmount: refundAmount,
            RefundReason: refundReason,
            PartialRefund: refundAmount < selectedProduct.price,
            OwnedProductId: selectedProduct.ownedProductId,
            NotifyTenantUsers: true
        };
        setIsLoading(true);
        post(`product/processRefund/${activeTenant?.id}`, payload)
        .then(res => {
            alert("Product refunded", "Payment has been refunded to customer", "success");
            setSelectedProduct(undefined);
            setRefundModalOpen(false);
            window.location.reload();
        })
        .catch(err => {
            alert("Server error", "Failed to refund product payment to customer", "error");
        })
        .finally(() => {
            setIsLoading(false);
        });
    }

    function cancelSubscription() {
        if(isLoading) return;
        setIsLoading(true);
        fetch(`product/cancelSubscription/${selectedProduct.ownedProductId}/${activeTenant?.id}/`)
        .then(res => {
            alert("Product removed", `Product has been canceled and will expire at ${dateHelpers.utcToLocalShortDate(selectedProduct.effectiveThrough)}`, "success");
            setSelectedProduct(undefined);
            setCancelModalOpen(false);
            window.location.reload();
        })
        .catch(err => {
            alert("Server error", "Failed to remove product from customer", "error");
        })
        .finally(() => {
            setIsLoading(false);
        });
    }

    const handleImpersonation = (user) => {
        if (impersonating) {
          alert(
            "Already impersonating",
            "Stop impersonating to impersonate a different user",
            "warning"
          );
          return;
        }
        setShowLoader(true);
    
        post(`user/impersonate/${id}`)
          .then((res) => {
            impersonate(res.user, res.token);
            alert("Success", `Impersonating ${res.username}`, "success");
            // NAVIGATE HERE PLUS SLUG IN THE BEGINNING - window.location.host
            let baseUrl = window.location.host;
            let subdomain = activeTenant?.slug;
            let productHomePage = `${window.location.protocol}//${subdomain}.${baseUrl}`;
            window.location.href = productHomePage;
          })
          .catch((err) => {
            alert(
              "Error",
              err.data.message || "Could not impersonate user",
              "error"
            );
            console.error(err);
          })
          .finally(() => {
            setShowLoader(false);
          });
      };

    if(!customer) return null;
    return (
        <div className="p-4">
            <div className="mt-4">
                <div className="ml-4">
                    <GoBack display="Return to customers" navigateTo="/customers" />
                </div>
                <PageHeader title={`${customer.firstName} ${customer.lastName}`} />
                <div className="bg-white rounded-lg flex flex-col p-4">
                    <DetailLine label="Email:" value={customer.email}/>
                    <div className="mt-2">
                        <button 
                            className="rounded-full inline-block inline-block px-6 py-3  font-semibold text-white bg-primaryColor"
                            style={{backgroundColor: theme?.primaryHexColor}}
                            onClick={sendForgotPasswordEmail}
                        >
                            Send Forgot Password Email
                        </button>
                    </div>
                    <div className="mt-2">
                        <button 
                            className="rounded-full inline-block inline-block px-6 py-3  font-semibold text-white bg-violet-700"
                            onClick={handleImpersonation}
                        >
                            Impersonate
                        </button>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <PageHeader title={`Active Products and Subsriptions`} />
                <div className="bg-white rounded-lg flex flex-col p-4 divide-y-2 divide-slate-100">
                    <GridHeaders headerList={["Name", "Type", "Effective At", "Expires At", "Qty", "Unit Qty", "Price", "Will Renew", "Status", ""]}/>
                    {activeProducts.map((product, idx) => (
                        <ProductLine
                            key={`activeproduct-${idx}`}
                            productName={product.name}
                            productType={product.productType}
                            effectiveAt={product.effectiveAt}
                            effectiveThrough={product.effectiveThrough}
                            qty={product.qty}
                            unitQty={product.supplementalFeatureQty || "N/A"}
                            price={product.price}
                            willRenew={!isNil(product.renewalProductId)}
                            status={product.status}
                            setCancelOpen={setCancelModalOpen}
                            setRefundOpen={setRefundModalOpen}
                            setSelectedProduct={setSelectedProduct}
                            product={product}
                            isActive
                        />
                    ))}
                </div>
            </div>
            <div className="mt-4">
                <PageHeader title={`Past Products and Subsriptions`} />
                <div className="bg-white rounded-lg flex flex-col p-4 divide-y-2 divide-slate-100">
                    <GridHeaders headerList={["Name", "Type", "Effective At", "Expires At", "Qty", "Unit Qty", "Price", "Will Renew", "Status"]}/>
                    {pastProducts.map((product, idx) => (
                        <ProductLine
                            key={`pastproduct-${idx}`}
                            productName={product.name}
                            productType={product.productType}
                            effectiveAt={product.effectiveAt}
                            effectiveThrough={product.effectiveThrough}
                            qty={product.qty}
                            unitQty={product.supplementalFeatureQty || "N/A"}
                            price={product.price}
                            willRenew={!isNil(product.renewalProductId)}
                            status={product.status}
                        />
                    ))}
                </div>
            </div>
            <div className="mt-4">
                <PageHeader title={`Payment History`} />
                <div className="bg-white rounded-lg flex flex-col justify-center p-4 divide-y-2 divide-slate-100">
                    <GridHeaders headerList={["Succeded", "Amount", "Product", "Date", "Failed Reason"]}/>
                    {payments && payments.length && payments.map((payment, idx) => (
                        <PaymentLine 
                            key={`payment-${idx}`}
                            amount={payment.amount} 
                            productName={payment.ownedProductName}
                            completedAt={payment.succeededAt || payment.failedAt}
                            succeeded={!payment.failedAt}
                            failMessage={payment.failureMessage}
                        />))}
                </div>
            </div>
            {/* <div className="grid grid-cols-8"></div> */}
            <ConfirmationModal
                title={"Cancel Subscription"}
                message={`Are you sure you want to cancel this subscription?`}
                textToMatch={currentUser?.username}
                modalOpen={cancelModalOpen}
                setModalOpen={setCancelModalOpen}
                confirmButtonText={"Yes"}
                onConfirm={cancelSubscription}
            />
            <RefundConfirmationModal
                title={"Refund"}
                message={`Are you sure you want to cancel and refund this subscription?`}
                productPrice={selectedProduct?.price}
                textToMatch={currentUser?.username}
                modalOpen={refundModalOpen}
                setModalOpen={setRefundModalOpen}
                confirmButtonText={"Confirm"}
                onConfirm={processRefund}
            />
            {/* <div className="grid grid-cols-8 grid-cols-9"></div> */}
        </div>
    )
}

function DetailLine({label, value}) {
    if (!value) return null;
    return (<div className="grid grid-cols-3 gap-4">
        <div className="lg:col-span-1 col-span-3 text-lg font-semibold">{label}</div>
        <div className="lg:col-span-2 col-span-3 text-lg">{value}</div>
    </div>);
};

function GridHeaders({label, headerList = []}) {
    let numberOfColumns = headerList.length;
    return (
        <div className={`grid grid-cols-${numberOfColumns} gap-4 pb-2 border-b-2`}>
            {headerList.map((header, idx) => (
                <div key={`header-${label}-${header}-${idx}`} className={`lg:col-span-1 col-span-${numberOfColumns} text-lg`}>{header}</div>    
            ))}
        </div>
    );
}

function PaymentLine({amount, productName, completedAt, succeeded, failMessage}) {
    return (<div className="grid grid-cols-5 gap-4 py-4">
        <div className="lg:col-span-1 col-span-4 self-center">{succeeded 
            ? (<CheckCircleIcon className="w-9 text-success" />)
            : (<XCircleIcon className="w-9 text-error" />)}</div>
        <div className="lg:col-span-1 col-span-4 text-lg self-center">{formatCurrency(amount, true)}</div>
        <div className="lg:col-span-1 col-span-4 text-lg self-center">{productName}</div>
        <div className="lg:col-span-1 col-span-4 text-lg self-center">{dateHelpers.utcToLocalShortDate(completedAt)}</div>
        <div className="lg:col-span-1 col-span-4 text-lg self-center">{failMessage}</div>
    </div>);
};

function ProductLine({productName, price, productType, effectiveAt, effectiveThrough, qty, unitQty, willRenew, status, isActive, setCancelOpen, setRefundOpen, setSelectedProduct, product}) {
    return (<div className={`grid ${isActive ? "grid-cols-10" : "grid-cols-9"} gap-4 py-4 border-b-2 border-slate-100`}>
        <div className="lg:col-span-1 col-span-10 text-lg self-center">{productName}</div>
        <div className="lg:col-span-1 col-span-10 text-lg self-center">{productType}</div>
        <div className="lg:col-span-1 col-span-10 text-lg self-center">{dateHelpers.utcToLocalShortDate(effectiveAt)}</div>
        <div className="lg:col-span-1 col-span-10 text-lg self-center">{dateHelpers.utcToLocalShortDate(effectiveThrough)}</div>
        <div className="lg:col-span-1 col-span-10 text-lg self-center">{qty}</div>
        <div className="lg:col-span-1 col-span-10 text-lg self-center">{unitQty}</div>
        <div className="lg:col-span-1 col-span-10 text-lg self-center">{price ? formatCurrency(price, true) : "free"}</div>
        <div className="lg:col-span-1 col-span-10 text-lg self-center">{willRenew ? "Yes" : "No"}</div>
        <div className="lg:col-span-1 col-span-10 text-lg self-center">{status}</div>
        {isActive && (
            <div className="lg:col-span-1 col-span-10 text-lg flex lg:flex-row lg:justify-center">
                {product.productType.toLowerCase() === "primary license" && (<button className="border-2 p-2 rounded-lg text-white bg-error shadow-md mr-2" onClick={()=>{
                    setSelectedProduct(product);
                    setCancelOpen(true);
                }}>Remove</button>)}
                <button className="border-2 p-2 rounded-lg text-white bg-amber-600 shadow-md" onClick={()=>{
                    setSelectedProduct(product)
                    setRefundOpen(true);
                }}>Refund</button>
            </div>)}
    </div>);
};

function RefundConfirmationModal({
    modalOpen,
    title,
    message,
    textToMatch,
    confirmButtonText,
    setModalOpen,
    onConfirm,
    productPrice
  }) {
    const [userInput, setUserInput] = useState("");
    const [refundAmount, setRefundAmount] = useState();
    const [refundReason, setRefundReason] = useState();
  
    const userInputMatches = useMemo(
      () => textToMatch === userInput,
      [textToMatch, userInput]
    );
  
    return (
      <ModalWrapper open={modalOpen} setOpen={setModalOpen}>
        <div className="w-[85vw] lg:w-[35vw]">
          <div className="pb-4 text-center flex items-center flex-col">
            {title}
          </div>
          {message && (
            <div className="pb-8 mx-6 text-center text-sm text-gray-500">
              {message}
            </div>
          )}
  
          <div className="mb-8">
            <TextInput
              label={"Enter username to confirm"}
              placeholder={textToMatch}
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              iconColor={userInputMatches ? "success" : "notification"}
              Icon={userInputMatches ? CheckIcon : ExclamationTriangleIcon}
            />
          </div>
          <div className="mb-8">
            <SelectInput
                label={"Refund reason (optional)"}
                placeholder={constants.REFUND_REASONS[0].label}
                options={constants.REFUND_REASONS}
                value={refundReason}
                onChange={(reason) => {setRefundReason(reason)} }
                onClear={() => {setRefundReason(undefined)}}
            />
          </div>
          <div className="mb-8">
            <NumberInput
              placeholder={productPrice}
              label={"Enter refund amount as pennies"}
              value={refundAmount}
              onChange={(e) => setRefundAmount(isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber)}
              iconColor={userInputMatches ? "success" : "notification"}
              Icon={userInputMatches ? CheckIcon : ExclamationTriangleIcon}
            />
          </div>
          {refundAmount && refundAmount !== 0 
          ? (<div className="mb-8">
                <span>Amount: </span>
                <span>{formatCurrency(refundAmount, true)}</span>
            </div>) 
          : null}
  
          <div className="mt-3">
            <PrimaryButton
              text={confirmButtonText}
              onClick={() => {onConfirm(refundAmount, refundReason?.value)}}
              disabled={!userInputMatches}
            />
          </div>
        </div>
      </ModalWrapper>
    );
}

export default SystemTenantCustomer;