import { useNavigate } from "react-router-dom";
import { useAlert, useApi, useTenant, useTheme } from "../../hooks";
import { PageHeader } from "../../components/Layouts";
import { clsx } from "clsx";
import { create } from "zustand";
import { ComingSoon } from "../index";
import AccountDetails from "./AccountDetails";
import { Tab } from "@headlessui/react";
import SystemTenantCustomization from "./SystemTenantCustomization";
import {
  BuildingOfficeIcon,
  CreditCardIcon,
  SwatchIcon,
} from "@heroicons/react/24/solid";
import classNames from "classnames";
import AccountBilling from "./AccountBilling";
import useSWR from "swr";
import { Fragment, useEffect, useState } from "react";
import { isNil } from "lodash/lang";
import {useActiveTenant, useTenantTheme} from "../../context/useSystemTenantStore";

function TabButton({ name, onClick, selected, tabIcon }) {
  const { theme } = useTenant();
  return (
    <a
      key={name}
      onClick={onClick}
      style={{
        borderColor: selected ? theme?.secondaryHexColor : null,
        color: selected ? theme?.secondaryHexColor : null,
      }}
      className={clsx(
        "cursor-pointer",
        "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium text-gray-500 gap-x-1",
      )}
      aria-current={selected ? "page" : undefined}
    >
      <div className="flex items-center justify-between items-center px-2">
        {tabIcon}
        <span>{name}</span>
      </div>
    </a>
  );
}

export default function Settings() {
  const navigate = useNavigate();
  const alert = useAlert();
  const { post, fetch } = useApi();
  const activeTenant = useActiveTenant();
  const theme = useTenantTheme();
  const { data, isLoading, mutate } = useSWR(
    `account/${activeTenant.id}`,
    fetch,
    {
      revalidateOnFocus: false,
      refreshWhenOffline: false,
      revalidateOnReconnect: true,
      keepPreviousData: true,
    },
  );
  const [account, setAccount] = useState(null);

  // Insert toggleActiveStatus here

  useEffect(() => {
    if (!isNil(data)) {
      setAccount(data);
    }
  }, [data]);
  
  
  function updateAccountDetails(accountDetails) {
      mutate(accountDetails);
  }

  return (
    <div className="max-h-full max-w-full flex flex-col">
      {/* Header */}
      <Tab.Group>
        <div className="flex flex-col h-30 bg-backgroundColor sticky px-2">
          <PageHeader title="Settings" />
          <div>
            <Tab.List className={""}>
              {/*Account Details Tab*/}
              <Tab as={Fragment}>
                {({ selected }) => (
                  <TabButton
                    name={"Account Details"}
                    tabIcon={
                      <BuildingOfficeIcon
                        style={{
                          color: selected ? theme.secondaryHexColor : null,
                        }}
                        className={classNames(
                          // tab.current
                          //   ? "text-indigo-500"
                          //   : "text-gray-400 group-hover:text-gray-500",
                          "-ml-0.5 mr-2 h-5 w-5",
                        )}
                        aria-hidden="true"
                      />
                    }
                    selected={selected}
                  />
                )}
              </Tab>
              {/*Account Billing Tab*/}
              <Tab as={Fragment}>
                {({ selected }) => (
                  <TabButton
                    name={"Account Billing"}
                    tabIcon={
                      <CreditCardIcon
                        style={{
                          color: selected ? theme.secondaryHexColor : null,
                        }}
                        className={classNames(
                          // tab.current
                          //   ? "text-indigo-500"
                          //   : "text-gray-400 group-hover:text-gray-500",
                          "-ml-0.5 mr-2 h-5 w-5",
                        )}
                        aria-hidden="true"
                      />
                    }
                    selected={selected}
                  />
                )}
              </Tab>
              {/*System Tenant Customization*/}
              <Tab as={Fragment}>
                {({ selected }) => (
                  <TabButton
                    name={"System Tenant Customization"}
                    tabIcon={
                      <SwatchIcon
                        style={{
                          color: selected ? theme.secondaryHexColor : null,
                        }}
                        className={classNames(
                          // tab.current
                          //   ? "text-indigo-500"
                          //   : "text-gray-400 group-hover:text-gray-500",
                          "-ml-0.5 mr-2 h-5 w-5",
                        )}
                        aria-hidden="true"
                      />
                    }
                    selected={selected}
                  />
                )}
              </Tab>
            </Tab.List>
          </div>
        </div>
        {/*<div className="overflow-y-auto">{tabStore.currentTab.page}</div>*/}
        <div className="overflow-y-auto">
          <Tab.Panels>
            <Tab.Panel>
              <AccountDetails accountDetails={account} 
                isLoading={isLoading}
                updateAccountDetails={updateAccountDetails}/>
            </Tab.Panel>
            <Tab.Panel>
              <AccountBilling accountDetails={account} />
            </Tab.Panel>
            <Tab.Panel>
              <SystemTenantCustomization />
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  );
}
