import {Switch} from "@headlessui/react";
import {AuthLayout, PublicLayout, SystemTenantLayout} from "./index";
import React, {useEffect} from "react";
import {Home, SystemTenantCustomer, SystemTenantCustomers, SystemTenantProducts} from "../../pages/system-tenants";
import {redirect, Route, Routes} from "react-router-dom";
import {isNil} from "lodash/lang";
import {
    Account,
    Accounts,
    AdminRegistration, CustomerHome, CustomerPurchase, Dashboard, Demo,
    EmailVerification,
    ForgotPassword,
    Login,
    NotFound404, Product, Products, Profile,
    Register,
    ResetPassword, SystemTenant, SystemTenants, TenantNotFound404, User, Users
} from "../../pages";
import {useLoaderStore, useUserStore} from "../../context";
import {tryGetSubdomain, userHasRole} from "../../utils/helpers";
import constants from "../../utils/constants";
import {useApi, useSubdomain, useTenant} from "../../hooks";
import Settings from "../../pages/AccountSettings";
import {SubdomainGuard} from "../PreAuthorization";


export default function ViewController() {
    const subdomain = tryGetSubdomain();
    const {fetch} = useApi();
    const {setShowLoader} = useLoaderStore();
    const { currentUser } = useUserStore();

    // useEffect(() => {
    //     setShowLoader(subdomainLoading);
    // }, [subdomainLoading]);



    const showTenantAuthenticatedRoutes = subdomain && currentUser;
    const showTenantUnAuthenticatedRoutes = subdomain && !currentUser;
    const showAdminAuthenticatedRoutes = !subdomain && currentUser &&  userHasRole(currentUser, [constants.ROLE_IDS.ADMIN, constants.ROLE_IDS.SYS_ADMIN]);
    const showAdminUnAuthenticatedRoutes = !subdomain && !currentUser;


    if(showTenantAuthenticatedRoutes) {
        return TenantAuthenticatedRoutes();
    }
    else if(showTenantUnAuthenticatedRoutes) {
        return TenantUnAuthenticatedRoutes();
    }
    else if(showAdminAuthenticatedRoutes) {
        return AdminAuthenticatedRoutes();
    }
    else if(showAdminUnAuthenticatedRoutes) {
        return AdminUnAuthenticatedRoutes();
    } else {
        return (
            <Routes>
                <Route element={<PublicLayout/>}>
                    <Route path="*" element={<NotFound404/>}/>
                </Route>
            </Routes>
        )
    }



    async function TenantLoader() {
        await fetch('public/ping')
            .then((res) => {
            }).catch((err) => {
                if(err.status === 404) {
                    return redirect('/not-found');
                }
            });
    }

    //Tenant Subdomain
    function TenantAuthenticatedRoutes() {
        return (
                <Routes 
                    // location={constants.ROLE_IDS.ADMIN}
                >
                    <Route loader={TenantLoader} element={<SystemTenantLayout systemTenantSlug={subdomain}/>}>
                        <Route path="/customerPurchase/:productId/:isLicense"  element={<CustomerPurchase/>}/>
                        <Route path="/customerPurchase/:productId/:isLicense/:ownedProductId"  element={<CustomerPurchase/>}/>
                        <Route path="/profile/:id" element={<Profile/>}/>
                        <Route path="/"  element={<SystemTenantProducts/>}/>
                        <Route path="*" element={<NotFound404 />}/>
                    </Route>
                </Routes>

        );
    }

    function TenantUnAuthenticatedRoutes() {
        return (
            <Routes>
            <Route loader={TenantLoader} element={<SystemTenantLayout/>}>
                <Route path="/" element={<SystemTenantProducts />} />
                <Route path="/login" element={<Login isAdmin={isNil(subdomain)} />} />
                <Route path="/forgotPassword" element={<ForgotPassword />} />
                <Route path="/resetPassword/:token" element={<ResetPassword />} />
                <Route path="/verifyEmail/:token" element={<EmailVerification />} />
                <Route path="/register" element={<Register />} />
                <Route path="/adminRegistration" element={<AdminRegistration />} />
                <Route path="/customerPurchase/:productId/:isLicense, " element={<CustomerPurchase />} />
                <Route path="/customerPurchase/:productId/:isLicense/:ownedProductId, " element={<CustomerPurchase />} />
                <Route path="*" element={<NotFound404 />} />
            </Route>
        </Routes>
                );
    }


    //Admin
    function AdminAuthenticatedRoutes(){
        return (
            <Routes>
                <Route element={<AuthLayout/>}>
                    <Route path="/" element={<Dashboard/>}/>
                    <Route path="/users" element={<Users/>}/>
                    <Route path="/users/:id" element={<User/>}/>
                    <Route path="/users/:id/:accountId" element={<User/>}/>
                    <Route path="/profile/:id" element={<Profile/>}/>
                    <Route path="/demo" element={<Demo/>}/>
                    <Route path="/accounts" element={<Accounts/>}/>
                    {/*<Route path="/accountPlan" element={<MargotAccountDetails/>}/>*/}
                    {/*<Route path="/accounts/:id" element={<Account/>}/>*/}
                    <Route path="/customers" element={<SystemTenantCustomers/>}/>
                    <Route path="/customers/:id" element={<SystemTenantCustomer/>}/>
                    <Route path="/systemTenants" element={<SystemTenants/>}/>
                    <Route path="/systemTenants/:id" element={<SystemTenant/>}/>
                    <Route path="/products" element={<Products/>}/>
                    <Route path="/products/:id" element={<Product/>}/>
                    <Route path="/settings" element={<Settings/>}/>
                    {/* Add more routes here */}
                    <Route path="*" element={<NotFound404/>}/>
                </Route>
                <Route path="/tenant/not-found" element={<TenantNotFound404 />} />
            </Routes>
        )
    }

    function AdminUnAuthenticatedRoutes() {
        return (
            <Routes>
                <Route element={<PublicLayout />}>
                    <Route path="/" element={<Login isAdmin={isNil(subdomain)} />} />
                    <Route path="/forgotPassword" element={<ForgotPassword />} />
                    <Route path="/resetPassword/:token" element={<ResetPassword />} />
                    <Route path="/verifyEmail/:token" element={<EmailVerification />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/adminRegistration" element={<AdminRegistration />} />
                    <Route path="*" element={<NotFound404 />} />
                </Route>
                <Route path="/tenant/not-found" element={<TenantNotFound404 />} />
            </Routes>
        );
    }
}