import {useThemeConfig, useUserStore} from "../../context";
import {useEffect, useState} from "react";
import {RadioGroup} from "@headlessui/react";
import classNames from "classnames";
import {XCircleIcon, ExclamationCircleIcon} from "@heroicons/react/24/outline";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import { ConfirmationModal } from "../../components/Modals";
import {ProductCard} from "../../components/Cards";
import {useAlert, useApi, useSubdomain, useTenant} from "../../hooks";
import {isNil} from "lodash/lang";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";
import dateHelpers from "../../utils/dateHelpers";
import { JsonTryParse, formatCurrency } from "../../utils/helpers";
import {useActiveTenant, useTenantTheme} from "../../context/useSystemTenantStore";

const product = {
    id: undefined,
    name: undefined,
    description: undefined,
    price: undefined
}

const frequencies = [
    { value: 'months', label: 'Monthly', priceSuffix: '/month' },
    { value: 'years', label: 'Annually', priceSuffix: '/year' },
]

export default function SystemTenantProducts() {
    const navigate = useNavigate();
    const { fetch, post } = useApi();
    const alert = useAlert();
    const activeTenant = useActiveTenant();
    const theme = useTenantTheme();
    
    const { currentUser } = useUserStore();
    const [cancelModelOpen, setCancelModalOpen] = useState(false);
    const [frequency, setFrequency] = useState(frequencies[0]);
    const [productList, setProductList] = useState(null);
    const {data, error, mutate} = useSWR(activeTenant.id ? 'product/CustomerProductList' : null, fetch, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        refreshWhenHidden: false,
    });
    const [activeSubscription, setActiveSubscription] = useState();
    const [activeProducts, setActiveProducts] = useState();
    const [payments, setPayments] = useState([]);
    // const {data, isLoading} = useSWR(activeTenant?.id ? `product/productList/${activeTenant.id}` : null, fetch);
    const themePrimaryStyle = {color: theme?.primaryHexColor};
    const themeSecondaryStyle = {color: theme?.secondaryHexColor};
    let [filteredProducts, setFilteredProducts] = useState([]);
    const [activeSubscriptionTag, setActiveSubscriptionTag] = useState();

    useEffect(()=>{
        if(isNil(theme) || isNil(activeTenant)) return;
        getPurchaseHistory();
    }, []);
    
    useEffect(()=>{
        if (!isNil(productList)) {
            setFilteredProducts(productList.filter((product) => product.durationType.toLowerCase() === frequency.value));
        }
    }, [frequency, productList]);

    function themeNotLoaded() {
        return !theme ? true : false;
    }

    function getProducts(){
        if(!currentUser || themeNotLoaded()) return; 
        fetch(`product/productList/${activeTenant?.id}`)
        .then(res => {
            res.sort((a, b) => (a.price - b.price))
            setProductList(res);
        })
        .catch(err => {
            alert("Server error", "Unable to retrieve product list", "error");
        })
    }
    
    function getPurchaseHistory() {
        if(!currentUser || !activeTenant || themeNotLoaded()) return;
        fetch(`systemTenant/GetPurchaseHistoryBySystemTenant`)
        .then(res => {
            if (res.payments && res.payments.length) {
                res.payments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setPayments(res.payments.sort((a, b) => b.createdAt - a.createdAt));
            }
            if (res.activeProducts && res.activeProducts.length) {
                let subscriptions = res.activeProducts.filter(x => x.productType === "Primary License");
                let paidProducts = subscriptions.filter(x => x.price > 0);
                if (paidProducts.length) {
                    paidProducts.sort((a, b) => a.effectiveAt - b.effectiveAt);
                    setActiveSubscription(paidProducts[0]);
                    parseProductTag(paidProducts[0]);
                    return;
                }
                if (subscriptions.length) {
                    subscriptions.sort((a, b) => a.effectiveAt - b.effectiveAt);
                    setActiveSubscription(subscriptions[0]);
                    parseProductTag(subscriptions[0]);       
                }
            }
        })
        .catch(err => {
            alert("Server error", "Unable to retrieve purchase history", "error");
        })
    }

    function parseProductTag(product) {
        let parsedTag = JsonTryParse(product.tag) || {};
        setActiveSubscriptionTag(parsedTag);
        // getProductImgUrl(parsedTag);
    }

    function purchaseProduct(productId ) {
        if(!currentUser || themeNotLoaded()) return;
        let payload = {
            tenantId: currentUser.id,
            productId: productId,
            qty: 1,
            autorenew: true
        };
       post(`product/purchaseProduct/${activeTenant?.id}`, payload)
       .then(res => {
        getPurchaseHistory();
       })
       .catch(err => {
            alert("Server error", "Unable to execute purchase", "error");
        })
    }

    useEffect(() => {
        if(data) {
            data.sort((a, b) => (a.price - b.price))
            setProductList(data);
        }
    }, [data]);

    function getRenewalMessage(currentSubscription) {
        if (currentSubscription?.productId === currentSubscription?.renewalProductId) {
            return `Autorenews on ${dateHelpers.toDateString(
                currentSubscription?.effectiveThrough || ""
            )}`;
        }
        if (currentSubscription?.renewalProductId) {
            return `You will be switched to ${
                currentSubscription?.renewalProductName
            } on ${dateHelpers.toDateString(
                currentSubscription?.renewalEffectiveAt || ""
            )}`;
        }
        return `Expires on ${dateHelpers.toDateString(
            currentSubscription?.effectiveThrough || ""
        )}`;
    }

    function cancelSubscription() {
        fetch(`product/CancelSubscription/${activeSubscription?.ownedProductId}/${activeTenant?.id}`)
        .then(res => {
            alert("Cancelled", res, "success");
            setCancelModalOpen(false);
            getPurchaseHistory();
        })
        .catch(err => {
            alert("Server error", "Failed to cancel subscription", "error");
        });
    }

    return (
        <div style={{}} className="bg-gray-100 sm:py-32 h-full" id='sys-ten-prod'>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                    {console.log("current theme", theme)}
                    <p style={themePrimaryStyle} className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                        {theme?.header}
                    </p>
                    {/* <p style={themePrimaryStyle} className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                        Pricing plans for teams of&nbsp;all&nbsp;sizes
                    </p> */}
                </div>
                <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-500">
                    {theme?.subHeader}
                </p>
                {/* <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-500">
                    Choose an affordable plan that’s packed with the best features for engaging your audience, creating customer
                    loyalty, and driving sales.
                </p> */}
                <div className="mt-16 flex justify-center">
                    <RadioGroup
                        value={frequency}
                        onChange={setFrequency}
                        className="grid grid-cols-2 gap-x-1 rounded-full bg-slate-300 p-1 text-center text-xs font-semibold leading-5 text-white"
                    >
                        <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
                        {frequencies.map((option) => (
                            <RadioGroup.Option
                                key={option.value}
                                value={option}
                                className={({ checked }) =>
                                    classNames(checked ? 'bg-indigo-500' : '', 'cursor-pointer rounded-full px-2.5 py-1')
                                }
                                style={{backgroundColor: option === frequency ? theme.primaryHexColor : undefined}}
                            >
                                <span>{option.label}</span>
                            </RadioGroup.Option>
                        ))}
                    </RadioGroup>
                </div>
                <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {filteredProducts.map((product, i) => (
                        <ProductCard
                            key={`product-${i}`}
                            product={product}
                            currentSubscription={activeSubscription}
                            onPurchase={(productId) => {navigate(`/customerPurchase/${productId}`)}}
                            onRefresh={getPurchaseHistory}
                        />
                    ))}
                </div>
            </div>
            {activeSubscription && (
                <div className="mx-auto max-w-7xl px-6 lg:px-8 mt-4">
                <div className="flex flex-col bg-white w-full p-4 rounded-lg">
                    <div style={themeSecondaryStyle} className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl justify-center">
                        Your Current Plan
                    </div>
                    <div className="text-slate-900 font-semibold">{activeSubscription.name}</div>
                    <div className="text-slate-900">{activeSubscriptionTag?.description || " "}</div>
                    <div className="text-slate-900 flex flex-row justify-between">
                        {getRenewalMessage(activeSubscription)}
                        <span
                            className="flex flex-row text-red-600 gap-x-2 mt-2 hover:opacity-50 cursor-pointer"
                            onClick={()=>{setCancelModalOpen(true)}}
                        >
                            <XCircleIcon className="w-5 text-red-600"/> Cancel
                        </span>
                    </div>
                    <ConfirmationModal
                        textToMatch={currentUser?.username}
                        modalOpen={cancelModelOpen}
                        setModalOpen={setCancelModalOpen}
                        title={"Cancel Subscription"}
                        message={"Are you sure you want to cancel? (Enter username to confirm)"}
                        confirmButtonText={"Confirm"}
                        Icon={ExclamationCircleIcon}
                        iconColor={"red"}
                        onConfirm={cancelSubscription}
                    />
                </div>
                <div>
                    <div style={themeSecondaryStyle} className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl justify-center">
                        Payment History
                    </div>
                        <ul className="mt-4">
                            {payments.map((x, i) => {
                                const isSuccess = x.succeededAt;
                                const timeStamp = isSuccess ? x.succeededAt : x.failedAt;
                                const message = isSuccess ? x.message : x.failureMessage;
                            return (
                            <li key={`payment-${i}`}>
                                <div className="relative pb-8">
                                {i !== payments.length - 1 ? (
                                    <span
                                    className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                                    aria-hidden="true"
                                    />
                                ) : null}
                                <div className="relative flex space-x-3">
                                    <div>
                                    <span
                                        className={classNames(
                                        "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-backgroundColor"
                                        )}
                                    >
                                        {isSuccess ? (
                                        <CheckCircleIcon className="w-8 h-8 text-success bg-backgroundColor" />
                                        ) : (
                                        <XCircleIcon className="w-8 h-8 text-error bg-backgroundColor" />
                                        )}
                                    </span>
                                    </div>
                                    <div className="pt-1.5">
                                    <p>
                                        {timeStamp && dateHelpers.toDateString(timeStamp)} &#40;
                                        {/* {timeStamp && dateHelpers.localDateTime(timeStamp)} &#40; CANT USE LOCAL DATETIME BECAUSE MESSAGE CONTAINS UTC TIMES*/}
                                        {formatCurrency(x.amount, true)} &#41;
                                    </p>
                                    <p className="text-sm text-gray-500">{message} </p>
                                    </div>
                                </div>
                                </div>
                            </li>
                            );
                        })}
                        </ul>
                    <div>
                    </div>
                </div>
            </div>
            )}
        </div>
    );


}