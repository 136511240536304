import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { classNames } from "../../utils/helpers";
import { InfoButton } from "../Buttons";
import { Label } from ".";
import {useTenant} from "../../hooks";
import {Tooltip} from "react-tooltip";

export default function PasswordInput({
  label,
  name,
  onChange,
  placeholder = "",
  required = false,
  value,
  maxLength,
  showInfo,
  onKeyDown,
    tabIndex,
}) {
  const [showPassword, setShowPassword] = useState(false);
    const { theme } = useTenant();

  return (
    <div>
      <div className="flex">
        {/* Label/required asterisk */}
        {label && <Label label={label} required={required} />}
        {/* Info button */}
        {showInfo && (
          <InfoButton id={'password-info-button'} tabIndex={tabIndex} name={name} onChange={onChange} placeholder={placeholder} >

            <div className="px-4">
              <div className="border-b mb-2">Password requirements</div>
              <ul className="list-disc">
                <li>At least 8 characters</li>
                <li>At least one capital letter</li>
                <li>At least one number</li>
                <li>At least one symbol</li>
              </ul>
            </div>
          </InfoButton>
        )}
      </div>

      <div className={classNames("flex relative", label ? "mt-1" : "")}>
        {/* Input */}
        <input
          type={showPassword ? "text" : "password"}
          name={name}
          className="p-2 rounded-md focus:outline-none block w-full text-sm border border-gray-200 placeholder-gray-300"
          placeholder={placeholder}
          onChange={onChange}
          required={required}
          value={value}
          maxLength={maxLength}
          readOnly={!onChange}
          autoComplete="off"
          tabIndex={tabIndex}
          onKeyDown={onKeyDown}
        />
        {/* Show/hide password */}
        {showPassword ? (
          <EyeSlashIcon
            className={classNames("w-5 absolute top-1/2 right-3 cursor-pointer -translate-y-1/2",
                theme && theme?.primaryHexColor ? `text-${theme?.primaryHexColor}` : `text-primaryColor`
            )}
            onClick={() => setShowPassword(false)}
          />
        ) : (
          <EyeIcon
            className={classNames(theme && theme?.primaryHexColor
                ? `w-5 text-${theme?.primaryHexColor} absolute top-1/2 right-3 cursor-pointer -translate-y-1/2`
                : "w-5 text-primaryColor absolute top-1/2 right-3 cursor-pointer -translate-y-1/2"
            )}
            onClick={() => setShowPassword(true)}
          />
        )}
      </div>
    </div>
  );
}
