import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

function BarChart({ data, max }) {
  const maxValue = max || Math.max(...data.map((bar) => bar.value));

  const sorted = data.sort((a, b) => b.value - a.value);

  return (
    <Bar
      options={{
        indexAxis: "y",
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        scales: {
          x: {
            ticks: {
              stepSize: 1,
            },
          },
        },
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
      data={{
        datasets: [
          {
            data: sorted.map((x) => ({
              x: x.value,
              y: `${x.label} (${x.value})`,
            })),
            borderColor: "#70a17f",
            backgroundColor: "rgba(112, 161, 127, 0.2)",
          },
        ],
      }}
    />
  );
}

export default BarChart;
