import constants from "../utils/constants";
import {getSubdomain} from 'tldts';

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function JsonTryParse(str) {
  try {
    if (typeof str === "undefined") return undefined;
    return JSON.parse(str);
  } catch (e) {
    return undefined;
  }
}

export function isValidEmail(email) {
  const emailRegex = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,}$/;
  return emailRegex.test(email);
}

export function isValidPassword(password) {
  const regex =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+={}[\]:"|;',.<>/?])[A-Za-z\d!@#$%^&*()_\-+={}[\]:"|;',.<>/?]{8,}$/;
  return regex.test(password);
}

export function switchSortDirection(currentDirection) {
  switch (currentDirection) {
    case constants.SORT_DIRECTIONS.ASCENDING:
      return constants.SORT_DIRECTIONS.DESCENDING;
    case constants.SORT_DIRECTIONS.DESCENDING:
    default:
      return constants.SORT_DIRECTIONS.ASCENDING;
  }
}

export function countDefinedKeys(object) {
  let count = 0;

  for (const key in object) {
    if (
      object.hasOwnProperty(key) &&
      object[key] !== undefined &&
      object[key] !== ""
    ) {
      count++;
    }
  }
  return count;
}

export function convertToLabelValueList(list) {
  if (!list || !Array.isArray(list)) return [];

  return list.map((x) => ({ label: x.name, value: x.id }));
}

export function convertToLabelValue(obj) {
  if (!obj) return;
  return { label: obj.name, value: obj.id };
}

export function convertToIdNameList(list) {
  if (!list || !Array.isArray(list)) return [];
  return list.map((x) => ({ id: x.value, name: x.label }));
}

export function convertToIdName(obj) {
  if (!obj) return;
  return { id: obj.value, name: obj.label };
}

export function equivalentObjects(objA, objB) {
  if (!objA || !objB) return false;

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (const key of keysA) {
    if (objA[key] !== objB[key]) {
      return false;
    }
  }

  return true;
}

export function getDomain(url, subdomain) {
  let urlObj = new URL(url);
  let domainParts = urlObj.hostname.split('.');

  // make sure we are not removing the top level domain if there's no subdomain
  if (domainParts.length > 2) {
    domainParts.shift(); // remove the subdomain
  }

  // rebuild the url
  let newUrl = urlObj.protocol + "//" + domainParts.join('.');
  if(isDevMode()) {
    newUrl += `:${window.location.port}`;
  }
  return newUrl;
}

export function userHasRole(currentUser, roleIdParam){
  if(!currentUser || !currentUser.roles?.length) return false;
  if(Array.isArray(roleIdParam)){
    return currentUser.roles?.some(role => roleIdParam.includes(role?.typeOfRole));
  }
  return currentUser.roles?.some(role => role?.typeOfRole === roleIdParam);
}

export function getFormData(files, propertyName) {
  const formData = new FormData();
  if (files) {
    if (Array.isArray(files)) {
      for (let i = 0; i < files.length; i++) {
        formData.append(propertyName, files[i]);
      }
    } else formData.append(propertyName, files);
  }
  return formData;
}

export function isDevMode() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}

export function formatCurrency(dollars, isPennies) {
  if (isPennies) dollars = dollars / 100;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(dollars);
}

export function minutesToMs(minutes) {
  if(!Number(minutes)) {
    minutes = 1;
  }
  return  minutes * 60 * 1000;

}

export function buildUrlWithSubdomain(subdomain) {
    const urlObj = new URL(window.location.href);
    let url = `${urlObj.protocol}//${subdomain.trim()}.${urlObj.hostname}`;
    if(process.env.NODE_ENV === 'development') {
      url += `:${urlObj.port}`;
    }

    return url;
}

export function tryGetSubdomain() {
  const url = window.location.href;
  const subdomain = getSubdomain(url);
  if(subdomain === '' || subdomain === 'markt-app' || subdomain === undefined) return null;
  return subdomain;
}

export function padCardInfo(last4) {
  if (last4.length === 4) {
    return `**** **** **** ${last4}`;
  } else {
    return last4;
  }
}

export function getCardExpiration(card) {
  if(!card) return null;
  const {exp_year, exp_month} = card;
  return `${exp_month}${exp_year}`;
}

export function buildUrlWithoutSubdomain(subdomain) {
  const loc = getDomain(window.location, subdomain);
  return loc;
}

export function isDev() {
  let domainName = window.location.hostname;
  return domainName.includes(constants.DEV_DOMAIN);
}