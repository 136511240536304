import React, { useEffect, useMemo, useState } from "react";
import { useAlert, useApi, useTenant } from "../../hooks";
import { useLoaderStore, useUserStore } from "../../context";
import constants from "../../utils/constants";
import { classNames, formatCurrency, JsonTryParse } from "../../utils/helpers";
import { PaymentMethodModal } from "../../components/Modals";
import AmountDue from "../MargotAccountDetails/AmountDue";
import CurrentSubscriptionInfo from "../MargotAccountDetails/CurrentSubscriptionInfo";
import dateHelpers from "../../utils/dateHelpers";
import DefaultPaymentMethod from "../MargotAccountDetails/DefaultPaymentMethod";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import PaymentMethod from "../MargotAccountDetails/PaymentMethod";
import AddNewPaymentMethod from "../MargotAccountDetails/AddNewPaymentMethod";
import Products from "./Products";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { PaymentMethodSelect } from "../../components/Inputs";
import useSWR from "swr";
import { isNil } from "lodash/lang";
import moment from "moment";
import {useActiveTenant, useSystemTenantLists} from "../../context/useSystemTenantStore";
const { DURATION_TYPES } = constants;

function AccountBilling({ accountDetails}) {
  const alert = useAlert();
  const { setShowLoader } = useLoaderStore();
  const { currentUser } = useUserStore();
  const { fetch, post } = useApi();
  const [accountPaymentData, setAccountPaymentData] = useState(undefined);
  const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);
  const [existingPaymentMethods, setExistingPaymentMethods] = useState([]);
  const [products, setProducts] = useState([]);
  const [productDetails, setProductsDetails] = useState([]);
  const [paymentSummaryData, setPaymentSummaryData] = useState(undefined);
  const [selectedInterval, setSelectedInterval] = useState(
    constants.INTERVAL_TYPES[0].value,
  );
  const [defaultStripePaymentId, setDefaultStripePaymentId] = useState();
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(false);
  const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState(false);
  const [isLoadingPaymentMethodId, setIsLoadingPaymentMethodId] =
    useState(false);
  const [isLoadingPaymentSummary, setIsLoadingPaymentSummary] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(undefined);
  const [selectedDurationType, setSelectedDurationType] = useState("Months");
  const activeTenant = useActiveTenant();
  const tenantList = useSystemTenantLists();
  const systemTenantId = activeTenant?.id;
  const currentProductDetails =
    productDetails && productDetails.length
      ? productDetails.find(
          (p) =>
            p.productId === accountPaymentData?.currentSubscription?.productId,
        )
      : undefined;


  const [paymentSummaryQuery, setPaymentSummaryQuery] = useState({
    timeInterval: selectedInterval,
    endDate: dateHelpers.getStringFromMoment(moment()),
  });
  const {
    data: accountDetailsRes,
    isLoading: areAccountDetailsLoading,
    mutate: refreshData,
    error: accountDetailsResError,
  } = useSWR('account/AccountDetails', fetch, {
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    keepPreviousData: true,
  });
  const paymentSummaryUrl = activeTenant?.id
    ? `account/AccountPaymentSummary/${activeTenant?.id}`
    : null;
  const {
    data: paymentSummaryRes,
    isLoading: isPaymentSummaryLoading,
    error: paymentSummaryError,
  } = useSWR(
    [paymentSummaryUrl, paymentSummaryQuery],
    ([paymentSummaryUrl, paymentSummaryQuery]) =>
      fetch(paymentSummaryUrl, paymentSummaryQuery),
    {
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    if (!isNil(accountDetailsRes)) {
      processAccountDetails(accountDetailsRes);
    }
    if (!isNil(accountDetailsResError)) {
      alert(
        "Error while fetching payment summary",
        "Could not fetch payment summary info",
        "error",
      );
    }
  }, [accountDetailsRes, accountDetailsResError]);

  useEffect(() => {
    if (!isNil(paymentSummaryRes)) {
      processPaymentSummaryData(paymentSummaryRes);
    }
  }, [paymentSummaryRes]);

  function processAccountDetails(accountDetails) {
    let cardList = accountDetails.paymentMethods?.length
      ? accountDetails.paymentMethods.map((x) => JsonTryParse(x?.json))
      : [];
    setAccountPaymentData(accountDetails);
    setExistingPaymentMethods(cardList);
    setDefaultStripePaymentId(accountDetails.stripeDefaultPaymentId);
    setProducts(accountDetails.products);
    setProductsDetails(accountDetails.productDetails);
    if (
        accountDetails.currentSubscription &&
        accountDetails.currentSubscription.durationType !== undefined
    ) {
      let durationType = DURATION_TYPES.find(
        (d) => d.value === accountDetails.currentSubscription.durationType,
      )?.label;
      if (durationType) setSelectedDurationType(durationType);
      // switch (res.currentSubscription.durationType) {
      //   case DURATION_TYPES.BY_NAME.Months:
      //     setSelectedDurationType("Months");
      //     break;
      //   case DURATION_TYPES.BY_NAME.Years:
      //     setSelectedDurationType("Years");
      //     break;
      // }
    }
  }

  function processPaymentSummaryData(data) {
    setPaymentSummaryData(data);
  }

  // useEffect(() => {
  //   // getData();
  //   // getSystemTenantPaymentSummary();
  // }, []);

  useEffect(() => {
    if (!isPaymentSummaryLoading) {
      setPaymentSummaryQuery({
        ...paymentSummaryQuery,
        timeInterval: selectedInterval,
      });
    }
  }, [selectedInterval, activeTenant, isPaymentSummaryLoading]);

  const defaultPaymentMethod = useMemo(() => {
    if (
      existingPaymentMethods.length === 0 ||
      defaultStripePaymentId === undefined
    )
      return undefined;
    return existingPaymentMethods.find((x) => x.id === defaultStripePaymentId);
  }, [existingPaymentMethods, defaultStripePaymentId]);

  const getData = () => {
    setIsLoadingInitialData(true);
    setIsLoadingPaymentMethods(true);
    setIsLoadingPaymentMethodId(true);
    fetch(`account/AccountDetails/${activeTenant?.id}`)
      .then((res) => {})
      .catch(() => {
        alert(
          "Error while fetching account info",
          "Could not fetch account info",
          "error",
        );
      })
      .finally(() => {
        setIsLoadingInitialData(false);
        setIsLoadingPaymentMethodId(false);
        setIsLoadingPaymentMethods(false);
      });
  };

  // const getSystemTenantPaymentSummary = () => {
  //   setIsLoadingPaymentSummary(true);
  //   let payload = {
  //     endDate: new Date(),
  //     timeInterval: selectedInterval,
  //   };
  //   post(`account/AccountPaymentSummary/${activeTenant?.id}`, payload)
  //     .then((res) => {
  //
  //     })
  //     .catch(() => {
  //       alert(
  //         "Error while fetching payment summary",
  //         "Could not fetch payment summary info",
  //         "error",
  //       );
  //     })
  //     .finally(() => {
  //       setIsLoadingPaymentSummary(false);
  //     });
  // };

  const getPaymentMethods = () => {
    setIsLoadingPaymentMethods(true);
    fetch(`account/GetAccountPaymentMethods/${activeTenant?.id}`)
      .then((res) => {
        if (res && res.length) {
          let cardList = res.map((x) => JsonTryParse(x?.json));
          setExistingPaymentMethods(cardList);
        }
      })
      .finally(() => {
        setIsLoadingPaymentMethods(false);
      });
  };

  const getDefaultPaymentMethodId = () => {
    setIsLoadingPaymentMethodId(true);
    fetch(`account/GetAccountDefaultPaymentMethod/${activeTenant?.id}`)
      .then((res) => {
        setDefaultStripePaymentId(res?.id);
      })
      .finally(() => {
        setIsLoadingPaymentMethodId(false);
      });
  };

  const saveAccountPaymentMethod = (paymentMethodId, setAsDefault) => {
    setIsLoadingPaymentMethods(true);
    post(`account/AddPaymentMethodToAccount/${activeTenant?.id}`, {
      PaymentMethodId: paymentMethodId,
      SetAsDefault: existingPaymentMethods.length === 0 ? true : setAsDefault,
    })
      .then(() => {
        alert("Success", "Successfully created payment method", "success");
        // refreshData();
        getData();
        setPaymentMethodModalOpen(false);
      })
      .catch((err) => {
        alert(
          "Error",
          err.message || "An error occurred while attaching the payment method",
          "error",
        );
      })
      .finally(() => {
        setIsLoadingPaymentMethods(false);
      });
  };

  return (
    <div className="h-fit p-4 space-y-4 py-2 overflow-y-auto">
      {paymentMethodModalOpen && (
        <PaymentMethodModal
          modalOpen={paymentMethodModalOpen}
          existingPaymentMethodCount={existingPaymentMethods?.length || 0}
          refreshData={() => {
            getPaymentMethods();
            getDefaultPaymentMethodId();
          }}
          setModalOpen={setPaymentMethodModalOpen}
          savePaymentMethod={saveAccountPaymentMethod}
        />
      )}
      {/* Header */}
      {/*<div className="">*/}
      {/*    <div className="font-semibold text-xl mb-1">Account</div>*/}
      {/*    <div className="text-gray-500">*/}
      {/*        Manage your subscriptions and account information*/}
      {/*    </div>*/}
      {/*</div>*/}

      {/* Amount due */}
      {typeof accountPaymentData?.currentSubscription
        ?.renewalProductPriceInPennies === "number" &&
        accountPaymentData.currentSubscription.renewalProductPriceInPennies >
          0 && (
          <AmountDue
            amountInPennies={
              accountPaymentData?.currentSubscription
                ?.renewalProductPriceInPennies || 0
            }
            dueOn={
              accountPaymentData?.currentSubscription?.renewalEffectiveAt || ""
            }
          />
        )}

      {/* Current subscription and default payment method */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <CurrentSubscriptionInfo
          name={accountPaymentData?.currentSubscription?.name || ""}
          description={
            accountPaymentData?.currentSubscription?.description || ""
          }
          maxSystemTenants={currentProductDetails?.numberOfSystemTenants}
          systemTenantCount={tenantList?.length || 0}
          message={
            accountPaymentData?.currentSubscription?.productId ===
            accountPaymentData?.currentSubscription?.renewalProductId
              ? `Autorenews on ${dateHelpers.toDateString(
                  accountPaymentData?.currentSubscription?.effectiveThrough ||
                    "",
                )}`
              : `You will be switched to ${
                  accountPaymentData?.currentSubscription?.renewalProductName
                } on ${dateHelpers.toDateString(
                  accountPaymentData?.currentSubscription?.renewalEffectiveAt ||
                    "",
                )}`
          }
          paymentSummaryData={paymentSummaryData}
          systemTenant={activeTenant}
          selectedInterval={selectedInterval}
          setSelectedInterval={setSelectedInterval}
          isLoading={false}
          accountName={accountDetails?.name}
        />
        <div className="flex flex-col">
          <DefaultPaymentMethod
            defaultPaymentMethod={defaultPaymentMethod}
            existingPaymentMethodCount={existingPaymentMethods?.length || 0}
            isLoading={isLoadingPaymentMethodId || isLoadingPaymentMethodId}
            setPaymentMethodModalOpen={setPaymentMethodModalOpen}
          />
          {/* Payment methods */}
          {/*<div className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-x-4 lg:gap-y-4 xl:grid-cols-4">*/}
          <div className="flex flex-row justify-evenly gap-2 mt-1">
            {isLoadingPaymentMethods
              ? _.times(3, (i) => (
                  <Skeleton key={`skeleton-payment=${i}`} height="150px" />
                ))
              : existingPaymentMethods.map((x, i) => (
                  <PaymentMethod
                    key={`payment-method-${i}`}
                    paymentMethod={x}
                    isDefault={x.id === defaultStripePaymentId}
                    refreshData={() => {
                      getPaymentMethods();
                      getDefaultPaymentMethodId();
                    }}
                    systemTenant={activeTenant}
                  />
                ))}
            {existingPaymentMethods.length >= 0 &&
              existingPaymentMethods.length < constants.MAX_PAYMENT_TYPES && (
                <AddNewPaymentMethod
                  setPaymentMethodModalOpen={setPaymentMethodModalOpen}
                />
              )}
          </div>
          {existingPaymentMethods.length >= constants.MAX_PAYMENT_TYPES && (
            <div className="flex justify-end text-gray-500 italic">
              You have reached the maximum number of payment methods. Please
              remove a payment method to add a new one.
            </div>
          )}
        </div>
      </div>

      {/* Products */}
      <Products
         products={products}
         productDetails={productDetails}
         currentSubscription={accountDetailsRes?.currentSubscription}
         defaultPaymentMethod={defaultPaymentMethod}
         selectedDurationType={selectedDurationType}
         setSelectedDurationType={setSelectedDurationType}
         refreshData={refreshData}
      />

      {/* Payment history */}
      <div className="pt-2 pb-8">
        <div className="border-b border-gray-200 pb-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Payment History
          </h3>
        </div>
        <ul className="mt-4">
          {accountPaymentData?.payments.map((x, i) => {
            const isSuccess = x.succeededAt;
            const timeStamp = isSuccess ? x.succeededAt : x.failedAt;
            const message = isSuccess ? x.message : x.failureMessage;

            return (
              <li key={`payment-${x.ownedProductId}`}>
                <div className="relative pb-8">
                  {i !== accountPaymentData.payments.length - 1 ? (
                    <span
                      className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <div>
                      <span
                        className={classNames(
                          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-backgroundColor",
                        )}
                      >
                        {isSuccess ? (
                          <CheckCircleIcon className="w-8 h-8 text-success bg-backgroundColor" />
                        ) : (
                          <XCircleIcon className="w-8 h-8 text-error bg-backgroundColor" />
                        )}
                      </span>
                    </div>
                    <div className="pt-1.5">
                      <p>
                        {timeStamp && dateHelpers.toDateString(timeStamp)} &#40;
                        {/* {timeStamp && dateHelpers.localDateTime(timeStamp)} &#40; CANT USE LOCAL DATETIME BECAUSE MESSAGE CONTAINS UTC TIMES*/}
                        {formatCurrency(x.amount, true)} &#41;
                      </p>
                      <p className="text-sm text-gray-500">{message} </p>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default AccountBilling;
