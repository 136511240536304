import {create} from "zustand";
import {
    AccountRegistrationForm,
    AccountRegistrationPayment,
    SystemTenantRegistrationForm,
    UserRegistrationForm
} from "../components/Forms";
import useTenantStore from "./useSystemTenantStore";

export const StepIds = {
    userInfo: 1,
    accountInfo: 2,
    paymentInfo: 3,
    systemTenantInfo: 4,
};


export const StepStatus = {
    incomplete: "incomplete",
    current: "current",
    complete: "complete",
};

const stepList = [
    {
        id: StepIds.userInfo,
        name: "User",
        status: StepStatus.current,
        component: <UserRegistrationForm />
    },
    {
        id: StepIds.accountInfo,
        name: "Account",
        status: StepStatus.incomplete,
        component: <AccountRegistrationForm />

    },
    {
        id: StepIds.paymentInfo,
        name: "Payment",
        status: StepStatus.incomplete,
        component: <AccountRegistrationPayment />
    },
    {
        id: StepIds.systemTenantInfo,
        name: "Tenant",
        status: StepStatus.incomplete,
        component: <SystemTenantRegistrationForm />
    },
];

function replaceStepWhere(step, steps) {
    const indexToReplace = steps.findIndex((item) => item.id === step.id);
    if(indexToReplace !== -1) {
        stepList[indexToReplace] = step;
    }
    return stepList;
}

const useAdminRegistrationStepStore = create((set, get) =>
    ({
        steps: stepList,
        isComplete: false,
        currentStep: stepList[0],
        changeStep: (clickedStep) => {
            if(clickedStep.status === StepStatus.incomplete || clickedStep.id === get().currentStep.id) return;
            set(() => ({currentStep: clickedStep}));
        },
        completeStep: () => {
            const currentStep = {...get().currentStep};
            currentStep.status = StepStatus.complete;
            let stepList = [...get().steps];
            stepList = replaceStepWhere(currentStep, stepList);
            const isLastStep = currentStep.id === StepIds.systemTenantInfo;
            const nextStep = isLastStep ? currentStep
                : stepList[currentStep.id];
            if(nextStep.id != currentStep.id) {
                nextStep.status = StepStatus.current;
                stepList = replaceStepWhere(nextStep, stepList);
            }
            set(() => ({
                steps: stepList,
                currentStep: nextStep,
                isComplete: isLastStep,
            }));
        }
    })
);

export default useAdminRegistrationStepStore;

