import { Popover } from "@headlessui/react";
import {
  ArrowPathIcon,
  CheckIcon,
  IdentificationIcon,
  PlusCircleIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import {
  formatPhoneNumber,
  getCountryCallingCode,
} from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import {AddButton, PrimaryButton, ToggleButton} from "../components/Buttons";
import { CheckboxInput, SelectInput, TextInput } from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { ActiveFilter, PrimaryTable } from "../components/Tables";
import { useLoaderStore, useSystemTenantStore, useUserStore } from "../context";
import {useAlert, useApi, useTenant} from "../hooks";
import constants from "../utils/constants";
import dateHelpers from "../utils/dateHelpers";
import { switchSortDirection, userHasRole } from "../utils/helpers";
import {useActiveTenant} from "../context/useSystemTenantStore";
import useDevManager from "../context/useDevManager";

const EMPTY_FILTERS = {
  activeOnly: false,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  username: undefined,
};

export default function Users() {
  const navigate = useNavigate();
  const alert = useAlert();
  const { post, fetch } = useApi();
  const { setShowLoader } = useLoaderStore();
  const { currentUser, impersonate, impersonating } = useUserStore();
  const { IS_DEV } = useDevManager();
  const [activeFilters, setActiveFilters] = useState({
    ...EMPTY_FILTERS,
    activeOnly: true,
    accountId: currentUser?.roles?.find(x => x.accountId)?.accountId
  });
  const [stagedFilters, setStagedFilters] = useState(activeFilters);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState(
    constants.SORT_DIRECTIONS.ASCENDING
  );
  const [sortField, setSortField] = useState("id");
  const [displayCount, setDisplayCount] = useState(
    constants.DISPLAY_PER_PAGE ?? 25
  );
  const [data, setData] = useState({ list: [], totalCount: 0 });
  const [isLoading, setIsLoading] = useState(false);

  const activeTenant = useActiveTenant();
  const SYSTEM_TENANT_ID = activeTenant?.id;
  const IsSysAdmin = userHasRole(currentUser, constants.ROLE_IDS.SYS_ADMIN);
  const ACCOUNT_OPTIONS = currentUser.roles
    .filter(r => r.accountId)
    .map(x => ({ label: x.accountName, value: x.accountId}));

  useEffect(() => {
    if (IsSysAdmin) {
      getUsers();
    } else {
      getAccountUsers();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, displayCount, activeFilters, sortDirection, sortField]);

  useEffect(() => {
    setStagedFilters(activeFilters);
  }, [activeFilters]);

  const getUsers = () => {
    setIsLoading(true);
    fetch("user/list", {
      pageNumber: currentPage,
      pageSize: displayCount,
      sortDirection,
      sortField,
      isExact: false,
      ...activeFilters,
    })
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.error(err);
        alert("Server error", "Could not retrieve users", "error");
      })
      .finally(() => setIsLoading(false));
  };
  
  const getAccountUsers = () => {
    setIsLoading(true);
    if (!activeFilters?.accountId){
      alert("Invalid filters", "Please select an account to view users", "warning", false);
      return;
    } 
    // fetch(`user/AccountAdminList/${activeFilters?.accountId}/${SYSTEM_TENANT_ID}`)
    let payload = {
      pageNumber: currentPage,
      pageSize: displayCount,
      sortDirection,
      sortField,
      isExact: false,
      ...activeFilters,
    };
    fetch(`user/AccountAdminList`, payload)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.error(err);
        alert("Server error", "Could not retrieve users", "error");
      })
      .finally(() => setIsLoading(false));
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(switchSortDirection(sortDirection));
    } else {
      setSortField(field);
    }
  };

  const toggleActiveStatus = (user) => {
    setShowLoader(true);
    post(`user/toggleUserActive/${user.id}`)
      .then((res) => {
        alert(
          "Success",
          res.message || "User active status toggled",
          "success"
        );
        getUsers();
      })
      .catch((err) => {
        alert(
          "Error",
          err.data.message || "User active status could not be toggled",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const handleImpersonation = (user) => {
    if (impersonating) {
      alert(
        "Already impersonating",
        "Stop impersonating to impersonate a different user",
        "warning"
      );
      return;
    }
    setShowLoader(true);

    post(`user/impersonate/${user.id}`)
      .then((res) => {
        impersonate(res.user, res.token);
        alert("Success", `Impersonating ${user.username}`, "success");
        navigate("/");
      })
      .catch((err) => {
        alert(
          "Error",
          err.data.message || "Could not impersonate user",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  return (
      <div>
        {/* Header */}
        <div className="flex justify-between items-center mt-4 px-4">
          <PageHeader title="Users" ignoreSpacing/>
          <div className="mr-2">
            <AddButton onClicked={() => {
              if (!IsSysAdmin && activeFilters?.accountId) {
                navigate(`/users/0/${activeFilters.accountId}`);
              } else {
                navigate("/users/0");
              }
            }} text={'Add User'}/>
          </div>

        </div>

        {/* Filter section */}
        <div className="relative mb-4 rounded-xs shadow-sm bg-white p-4">
          <Popover as="div" className="relative">
            {({open}) => (
                <>
                  <Popover.Button className="flex items-center text-sm bg-gray-100 px-3 py-2 rounded-sm hover:bg-gray-200 relative focus:outline-none">
                    <PlusIcon className="w-4 mr-1"/>
                    FILTERS
                  </Popover.Button>
                  <Popover.Panel
                      as="div"
                      className="absolute grid gap-y-4 z-50 w-full sm:w-1/3 origin-bottom-right bg-white rounded-md p-4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none mt-1"
                  >
                    <div>
                      <CheckboxInput
                          name="active-only"
                          label="Show only active users"
                          checked={stagedFilters.activeOnly === true}
                          onChange={() =>
                              setStagedFilters({
                                ...stagedFilters,
                                activeOnly: stagedFilters.activeOnly ? false : true,
                              })
                          }
                      />
                    </div>
                    <div>
                      <TextInput
                          label="First Name"
                          placeholder="Enter first name to filter by"
                          value={stagedFilters.firstName}
                          onChange={(e) =>
                              setStagedFilters({
                                ...stagedFilters,
                                firstName: e.target.value || undefined,
                              })
                          }
                      />
                    </div>
                    <div>
                      <TextInput
                          label="Last Name"
                          placeholder="Enter last name to filter by"
                          value={stagedFilters.lastName}
                          onChange={(e) =>
                              setStagedFilters({
                                ...stagedFilters,
                                lastName: e.target.value || undefined,
                              })
                          }
                      />
                    </div>
                    <div>
                      <TextInput
                          label="Email"
                          placeholder="Enter email to filter by"
                          value={stagedFilters.email}
                          onChange={(e) =>
                              setStagedFilters({
                                ...stagedFilters,
                                email: e.target.value || undefined,
                              })
                          }
                      />
                    </div>
                    <div>
                      <TextInput
                          label="Username"
                          placeholder="Enter username to filter by"
                          value={stagedFilters.username}
                          onChange={(e) =>
                              setStagedFilters({
                                ...stagedFilters,
                                username: e.target.value || undefined,
                              })
                          }
                      />
                    </div>
                    <div>
                      <SelectInput
                          options={ACCOUNT_OPTIONS}
                          label="Account"
                          onClear={() =>
                              setStagedFilters({
                                ...stagedFilters,
                                accountId: undefined,
                              })}
                          value={ACCOUNT_OPTIONS.find(x => x.value === stagedFilters.accountId)}
                          onChange={(e) =>
                              setStagedFilters({
                                ...stagedFilters,
                                accountId: e.value,
                              })}
                          placeholder="select account"
                          isSearchable
                      />
                    </div>
                    <PrimaryButton
                        text="Apply filters"
                        onClick={() => setActiveFilters(stagedFilters)}
                    />
                  </Popover.Panel>
                </>
            )}
          </Popover>

          {/* Selected filters */}
          {Object.values(activeFilters).find(
              (val) => val !== undefined && val !== false
          ) ? (
              <div className="flex items-center space-x-2 pt-6 text-sm">
                <div>Selected:</div>
                <ActiveFilter
                    display="Show active only"
                    value={
                      activeFilters.activeOnly ? (
                          <CheckIcon className="w-4 text-green-600"/>
                      ) : undefined
                    }
                    onClear={() =>
                        setActiveFilters({
                          ...activeFilters,
                          activeOnly: false,
                        })
                    }
                />
                <ActiveFilter
                    display="First name"
                    value={activeFilters.firstName}
                    onClear={() =>
                        setActiveFilters({
                          ...activeFilters,
                          firstName: undefined,
                        })
                    }
                />
                <ActiveFilter
                    display="Last name"
                    value={activeFilters.lastName}
                    onClear={() =>
                        setActiveFilters({
                          ...activeFilters,
                          lastName: undefined,
                        })
                    }
                />
                <ActiveFilter
                    display="Email"
                    value={activeFilters.email}
                    onClear={() =>
                        setActiveFilters({
                          ...activeFilters,
                          email: undefined,
                        })
                    }
                />
                <ActiveFilter
                    display="Username"
                    value={activeFilters.username}
                    onClear={() =>
                        setActiveFilters({
                          ...activeFilters,
                          username: undefined,
                        })
                    }
                />
                <ActiveFilter
                    display="Account"
                    value={ACCOUNT_OPTIONS.find(x => x.value === activeFilters.accountId)?.label}
                    onClear={() =>
                        setActiveFilters({
                          ...activeFilters,
                          accountId: undefined,
                        })
                    }
                />
                <div
                    className="cursor-pointer bg-gray-100 px-3 py-2 text-xs hover:bg-gray-200"
                    onClick={() => {
                      setActiveFilters({...EMPTY_FILTERS});
                    }}
                >
                  Clear all
                </div>
              </div>
          ) : (
              <div className="pt-6 text-sm text-gray-400">No active filters</div>
          )}
        </div>

        {/* Table */}
        <div className="px-2">
          <PrimaryTable
              isLoading={isLoading}
              totalCount={data?.totalCount}
              displayCount={displayCount}
              currentPage={currentPage}
              setDisplayCount={setDisplayCount}
              setCurrentPage={setCurrentPage}
              header={
                <PrimaryTable.Header>
                  { IS_DEV &&
                  <PrimaryTable.HeaderColumn
                      display="Id"
                      onSort={() => handleSort("id")}
                      isSortingBy={sortField === "id"}
                      sortDirection={sortDirection}
                  />}
                  <PrimaryTable.HeaderColumn
                      display="First"
                      isSortingBy={sortField === "firstName"}
                      sortDirection={sortDirection}
                      onSort={() => handleSort("firstName")}
                  />
                  <PrimaryTable.HeaderColumn
                      display="Last"
                      isSortingBy={sortField === "lastName"}
                      sortDirection={sortDirection}
                      onSort={() => handleSort("lastName")}
                  />
                  <PrimaryTable.HeaderColumn
                      display="Email"
                      isSortingBy={sortField === "email"}
                      sortDirection={sortDirection}
                      onSort={() => handleSort("email")}
                  />
                  <PrimaryTable.HeaderColumn display="Phone #"/>
                  <PrimaryTable.HeaderColumn
                      display="Username"
                      isSortingBy={sortField === "username"}
                      sortDirection={sortDirection}
                      onSort={() => handleSort("username")}
                  />
                  <PrimaryTable.HeaderColumn display="Last Seen"/>
                  <PrimaryTable.HeaderColumn display=""/>
                </PrimaryTable.Header>
              }
          >
            {data &&
                data.list &&
                data.list.map((x, i) => (
                    <PrimaryTable.Row
                        key={`${x.id}-${i}`}
                        index={i}
                        onClick={(e) => {
                          if (x.deactivatedAt) return;
                          if (IsSysAdmin) navigate(`/users/${x.id}`);
                          if (!IsSysAdmin && activeFilters?.accountId) navigate(`/users/${x.id}/${activeFilters.accountId}`);
                        }}
                    >
                      {IS_DEV && <PrimaryTable.Cell>{x.id}</PrimaryTable.Cell>}
                      <PrimaryTable.Cell>{x.firstName}</PrimaryTable.Cell>
                      <PrimaryTable.Cell>{x.lastName}</PrimaryTable.Cell>
                      <PrimaryTable.Cell>{x.email}</PrimaryTable.Cell>
                      <PrimaryTable.Cell>
                        {formatPhoneNumber(
                            `+${getCountryCallingCode(x.countryCode)} ${
                                x.mobilePhoneNumber
                            }`
                        )}
                      </PrimaryTable.Cell>
                      <PrimaryTable.Cell>{x.username}</PrimaryTable.Cell>
                      <PrimaryTable.Cell>
                        {dateHelpers.getRelativeTime(x.lastLoggedOnAt)}
                      </PrimaryTable.Cell>
                      <PrimaryTable.Cell>
                        <div className="flex">
                          {/* Activate/deactivate */}
                          {currentUser.id !== x.id &&
                              (x.deactivatedAt ? (
                                  <ArrowPathIcon
                                      title="Activate"
                                      className="w-6 mx-1 text-white bg-green-500 rounded-md p-0.5 cursor-pointer"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        toggleActiveStatus(x);
                                      }}
                                  />
                              ) : (
                                  <XMarkIcon
                                      title="Deactivate"
                                      className="w-6 mx-1 text-white bg-delete rounded-md p-0.5 cursor-pointer"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        toggleActiveStatus(x);
                                      }}
                                  />
                              ))}

                          {/* Impersonation */}
                          {!impersonating &&
                              currentUser.id !== x.id &&
                              !x.deactivatedAt && (
                                  <IdentificationIcon
                                      title="Impersonate"
                                      className="w-6 mx-1 text-white bg-slate-900 rounded-md p-0.5 cursor-pointer"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleImpersonation(x);
                                      }}
                                  />
                              )}
                        </div>
                      </PrimaryTable.Cell>
                    </PrimaryTable.Row>
                ))}
          </PrimaryTable>
        </div>
      </div>
  );
}
