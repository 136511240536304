import { CheckCircleIcon } from "@heroicons/react/24/solid";
import "react-loading-skeleton/dist/skeleton.css";
import {
  americanExpressDark,
  dinersClubDark,
  discoverDark,
  jcbDark,
  masterCardDark,
  visaDark,
} from "../../assets/images/payment_methods/dark";
import {
  americanExpressLight,
  dinersClubLight,
  discoverLight,
  jcbLight,
  masterCardLight,
  visaLight,
} from "../../assets/images/payment_methods/light";
import { useAlert, useApi, useTenant } from "../../hooks";
import { classNames } from "../../utils/helpers";
import {
  formatStripeExpiration,
  isCardExpired,
} from "../../utils/stripeHelpers";
import {clsx} from "clsx";
import {useActiveTenant, useTenantTheme} from "../../context/useSystemTenantStore";

export default function PaymentMethod({
  paymentMethod,
  isDefault,
  forShowOnly,
  refreshData,
}) {
  const alert = useAlert();
  const { post, del } = useApi();
  const activeTenant = useActiveTenant();
  const theme = useTenantTheme();
  const selectedTheme = `bg-[${theme.primaryHexColor}] border-[${theme.primaryHexColor}]`;
  const selectedCircleClassNames = `bg-[${theme.primaryHexColor}]`;
  
  
  function getCardImage(brand, isDark) {
    switch (brand) {
      case "visa":
        return isDark ? visaDark : visaLight;
      case "amex":
        return isDark ? americanExpressDark : americanExpressLight;
      case "mastercard":
        return isDark ? masterCardDark : masterCardLight;
      case "discover":
        return isDark ? discoverDark : discoverLight;
      case "diners":
        return isDark ? dinersClubDark : dinersClubLight;
      case "jcb":
        return isDark ? jcbDark : jcbLight;
      default:
        return "";
    }
  }

  const changeToDefaultPaymentMethod = () => {
    let payload = {
      SetAsDefault: true,
      PaymentMethodId: paymentMethod.id,
    };
    post(`account/SetAccountDefaultPaymentMethod/${activeTenant.id}`, payload)
      .then((res) => {
        if (refreshData) refreshData();
      })
      .catch((err) => {
        alert(
          "Could not set as default payment method",
          err?.message || "Error occurred while saving payment method",
          "error",
        );
      });
  };

  const removePaymentMethod = () => {
    del(
      `account/DeleteAccountPaymentMethod/${paymentMethod.id}/${activeTenant.id}`,
    )
      .then(() => {
        if (refreshData) refreshData();
      })
      .catch((err) => {
        alert(
          "Could not remove payment method",
          err?.message || "Error occurred while removing payment method",
          "error",
        );
      });
  };

  const cardIsExpired = isCardExpired(paymentMethod);

  return (
    <div
      title={isDefault || forShowOnly ? "" : "Set as default payment method"}
      onClick={() => {
        if (!isDefault && !forShowOnly) {
          changeToDefaultPaymentMethod();
        }
      }}
      style={{borderColor: isDefault ? theme?.secondaryHexColor : "gray.500"}}
      className={classNames(
        "flex relative min-h-[10rem] w-full rounded-lg border",
        isDefault
          ? selectedTheme
          : forShowOnly
            ? "bg-gray-50"
            : "bg-white cursor-pointer",
      )}
    >
      {/* Checkbox */}
      {!forShowOnly && (
        <div
            style={{backgroundColor: isDefault ? theme?.secondaryHexColor : null}}
          className={clsx(!isDefault && "bg-gray-50",
            "absolute top-1 left-1 border rounded-full h-8 w-8 flex justify-center items-center",
          )}
        >
          {isDefault && <CheckCircleIcon className="w-6 h-6 text-white" />}
        </div>
      )}
      <div className="flex justify-center items-center w-2/5 p-1">
        <div className="">
          <img
            src={getCardImage(paymentMethod.card?.brand || "", true)}
            alt="card-logo"
            className=""
          />
        </div>
      </div>

      <div className="flex items-center w-3/5 py-0.5">
        <div className="">
          <div className="text-lg font-semibold">
            **** **** **** {paymentMethod.card?.last4}
          </div>
          <div
            className={classNames(
              "text-sm",
              cardIsExpired ? "text-red-500" : "",
            )}
          >
            {cardIsExpired ? "Expired" : "Expires"}{" "}
            {formatStripeExpiration(paymentMethod)}
          </div>
          {!forShowOnly && (
            <div
              title="Remove payment method"
              onClick={(e) => {
                e.stopPropagation();
                removePaymentMethod();
              }}
              className="text-sm text-red-500 cursor-pointer"
            >
              Remove
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
