
import {JsonTryParse, classNames, formatCurrency} from "../../utils/helpers";
import { ConfirmationModal } from "../Modals";
import {useUserStore} from "../../context";
import PrimaryButton from "../Buttons/CustomPrimaryButton";
import {useAlert, useApi, useTenant} from "../../hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {useActiveTenant, useTenantTheme} from "../../context/useSystemTenantStore";


export default function ProductCard({product, onPurchase, currentSubscription, onRefresh}) {
    const activeTenant = useActiveTenant();
    const theme = useTenantTheme();
    const { currentUser } = useUserStore();
    const { fetch, post } = useApi();
    const alert = useAlert();
    const navigate = useNavigate();
    // const [cancelModelOpen, setCancelModalOpen] = useState(false);
    const [switchModelOpen, setSwitchModalOpen] = useState(false);
    const [imgUrl, setImgUrl] = useState();
    const [productTag, setProductTag] = useState();
    
    useEffect(()=> {
        if(!product) return;
        setProductTag(product.tag);
        setImgUrl(product.tag.imageUrl)
    }, []);
    
    if(!product) return null;
    
    const IS_LICENSE = product.productType === "Primary License";
    const SCENERIO = {
        PURCHASE: 1,
        CURRENT: 2,
        UPGRADE: 3,
        SWITCH: 4,
        UNKNOWN: 5
    };

    function resolveScenario() {
        if (!currentSubscription || !IS_LICENSE) return SCENERIO.PURCHASE
        if (product.productId === currentSubscription.productId) return SCENERIO.CURRENT;
        if (product.price > currentSubscription.fullProductPrice) return SCENERIO.UPGRADE;
        if (product.price <= currentSubscription.fullProductPrice) return SCENERIO.SWITCH;
        return SCENERIO.UNKNOWN;
    }

    function resolveButtonButtonText() {
        switch(resolveScenario()){
            case SCENERIO.PURCHASE: return "Purchase";
            case SCENERIO.CURRENT: return "Current";
            case SCENERIO.UPGRADE: return "Upgrade";
            case SCENERIO.SWITCH: return "Switch";
            default: return "";
        }
    }

    function handleButtonClick() {
        if(!currentUser) {
            navigate("/login");
            return;
        }
        switch(resolveScenario()){
            case SCENERIO.PURCHASE: {
                if (product.price === 0) {
                    saveCustomerDetails();
                    return;
                }
                navigate(`/customerPurchase/${product.productId}/${IS_LICENSE}`);
            }
            case SCENERIO.CURRENT: return null;
            case SCENERIO.UPGRADE: return navigate(`/customerPurchase/${product.productId}/${IS_LICENSE}/${currentSubscription.ownedProductId}`);
            case SCENERIO.SWITCH: {
                setSwitchModalOpen(true)
                return;
            }
            default: return "";
        }
    }

    function saveCustomerDetails() {
        if(!currentUser) return;
        let payload = {
            UserId: currentUser.id,
            SystemTenantId: activeTenant?.id
        };
        post("user/SaveCustomerDetails", payload)
        .then(res => {
            purchaseFreeProduct();
        })
        .catch(err => {
            alert("Server error", "Unable to save customer details", "error");
        })
    }

    function purchaseFreeProduct() {
        if(!currentUser) return;
        let payload = {
            TenantId: currentUser.id,
            ProductId: product.productId,
            Qty: 1,
            Autorenew: IS_LICENSE ? true : false,
        };
       post(`product/purchaseProduct/${activeTenant?.id}`, payload)
       .then(res => {
        alert("Product purchased", "Successfully purchased product", "success");
        window.location.reload();
       })
       .catch(err => {
            alert("Server error", "Unable to execute purchase", "error");
        })
    };

    function changeSubscription() {
        fetch(`product/ChangeSubscription/${currentSubscription?.ownedProductId}/${product.productId}/${activeTenant?.id}`)
        .then(res => {
            alert("Subscription changed", res, "success");
            setSwitchModalOpen(false);
            onRefresh();
        })
        .catch(err => {
            console.error(err)
            alert("Server error", "Failed to switch subscription", "error");
        });
    }

    const getProductImgUrl = (productTagParam) => {
        if (!productTagParam?.imageFileKey) return;
        fetch(`product/GetProductSignedUrl/${productTagParam.imageFileKey}`)
        .then(res => {
          setImgUrl(res);
        })
        .catch(err => {
          alert("Server error", "Unable to fetch product image", "error")
        });
      };
    
    const BUTTON_TEXT = resolveButtonButtonText();
    return (
        <div
            className="border-2 border-black rounded-3xl p-5 xl:p-10 flex flex-col justify-end"
            style={{ borderColor: theme?.primaryHexColor}}
        >
            {imgUrl && (<div className="flex flex-grow justify-center mb-2">
            <img
                className="max-h-sm rounded-lg overflow-hidden aspect-square"
                src={imgUrl}
                alt="Product logo"
                style={{boxShadow: "0 1px 2px 0 #64748b"}}
            />
            </div>)}
            <div className="flex flex-row justify-between">
                <p className="text-gray-900 text-lg font-semibold">
                    {product.name}
                </p>
                <p className="text-gray-700">
                    {product.price ? formatCurrency(product.price, true) : "FREE"}
                </p>
            </div>
            <p className="mt-4 text-gray-500">
                {productTag?.description}
            </p>
            <div className="mt-4">
                <PrimaryButton 
                    overrideThemeColors={BUTTON_TEXT === "Current"} 
                    backgroundColor={BUTTON_TEXT === "Current" ? "success" : undefined} 
                    text={BUTTON_TEXT} 
                    onClick={handleButtonClick}
                />
            </div>
            <ConfirmationModal 
                textToMatch={currentUser?.username} 
                modalOpen={switchModelOpen}
                setModalOpen={setSwitchModalOpen}
                title={"Switch Subscription"}
                message={"Are you sure you want to switch to new subscription? (Enter username to confirm)"}
                confirmButtonText={"Confirm"}
                onConfirm={changeSubscription}
            />
        </div>
    );
}