import { create } from "zustand";
import constants  from "../utils/constants";

const useNotificationStore = create((set) => ({
  title: "",
  body: "",
  open: false,
  autoClose: true,
  type: constants.NOTIFICATION_TYPES.SUCCESS,
  setTitle: (title) => set(() => ({ title })),
  setBody: (body) => set(() => ({ body })),
  setOpen: (open) => set(() => ({ open })),
  setAutoClose: (autoClose) => set(() => ({ autoClose })),
  setType: (type) => set(() => ({ type })),

}));

export default useNotificationStore;
