import { create } from "zustand";

const useRegistrationStore = create((set, get) => ({
    registrationUser: undefined,
    registrationSysTenant: undefined,
    registrationAccount: undefined,
    clearAll: () => set(() => ({
        registrationUser: undefined,
        registrationSysTenant: undefined,
        registrationAccount: undefined,
    })),
    setRegistrationUser: (registrationUser) =>  set(() => ({ registrationUser })),
    setRegistrationSysTenant: (registrationSysTenant) => set(() => ({ registrationSysTenant })),
    setRegistrationAccount: (registrationAccount) => set(() => ({ registrationAccount }))
}));

export default useRegistrationStore;