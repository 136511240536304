import { Label } from ".";
import { classNames } from "../../utils/helpers";
import {clsx} from "clsx";

export default function NumberInput({
  label,
  subLabel,
  name,
  placeholder = "",
  required = false,
  value,
  min,
  max,
  onChange,
  className,
  onFocus,
  onBlur,
    id,
}) {
  return (
    <>
      {/* Label/required asterisk */}
      {label && <Label label={label} required={required} />}
      <div className={classNames("flex relative", label ? "mt-1" : "")}>
        {/* Input */}
        <input
            id={id}
          type="number"
          name={name}
          className={clsx(className,
            "p-2 rounded-md focus:outline-none block w-full sm:text-sm border border-gray-200 placeholder-gray-300"
          )}
          placeholder={placeholder}
          required={required}
          value={value}
          min={min}
          max={max}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          readOnly={!onChange}
          autoComplete="off"
        />
      </div>
      {/* Sublabel */}
      {subLabel && (
        <div className="mt-1 text-gray-400 text-xs text-right">{subLabel}</div>
      )}
    </>
  );
}
