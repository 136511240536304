import { PulseLoader } from "react-spinners";
import {useLoaderStore, useThemeConfig} from "../../context";
import constants from "../../utils/constants";
import { classNames } from "../../utils/helpers";
import useTenant from "../../hooks/useTenant";

export default function Loader() {
  const [showLoader] = useLoaderStore((state) => [state.showLoader]);
    const { theme } = useTenant();

  return (
    <div
        className={classNames(showLoader ? "" : "hidden", "text-xl bg-gray-50 opacity-60 z-50 absolute h-screen w-screen flex justify-center items-center")}>
      <PulseLoader
        color={theme.primaryHexColor ?? constants.APP_PRIMARY_COLOR}
        size={40}
        speedMultiplier={0.75}
      />
    </div>
  );
}
