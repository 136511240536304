import { useState } from "react";
import {useAdminRegistrationStepStore, useRegistrationStore} from "../../context";
import { useAlert } from "../../hooks";
import { TelephoneInput, TextInput } from "../Inputs";
import { isValidEmail } from "../../utils/helpers";
import { PrimaryButton } from "../Buttons";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { PaymentMethodModal } from "../Modals";
import {CardForm} from "./index";
import {InfoSlideOver} from "../Menus";

function AccountRegistrationForm(){
    const { registrationAccount, registrationUser, setRegistrationAccount } = useRegistrationStore();
    const alert = useAlert();
    const [name, setName] = useState(registrationAccount?.name || "");
    const [accountEmail, setAccountEmail] = useState(registrationAccount?.email || registrationUser?.email || "");
    const [accountMobilePhone, setAccountMobilePhone] = useState(registrationAccount?.mobilePhoneNumber || registrationUser?.mobilePhoneNumber || "");
    const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('US');


    const {completeStep, currentStep} = useAdminRegistrationStepStore();
    function isValid(){
        if (!name || !name.trim()) {
            alert("Invalid input", "Please enter a valid name", "warning");
            return false;
        }
        if (!accountEmail || !accountEmail.trim() || !isValidEmail(accountEmail)) {
            alert("Invalid input", "Please enter a valid email", "warning");
            return false;
        }
        if (!accountMobilePhone || !accountMobilePhone.trim()) {
            alert("Invalid input", "Please enter a valid phone number", "warning");
            return false;
        }
        if (!isPossiblePhoneNumber(accountMobilePhone.trim(), selectedCountry)) {
            alert("Invalid input", "Please enter a valid phone number", "warning");
            return false;
        }
        return true;
    }

    const handleSave = async () => {
        if (!isValid()) return;
        setRegistrationAccount({
            name: name,
            email: accountEmail,
            mobilePhone: accountMobilePhone,
        });
        completeStep();
    };



    return (
        <div className="xs:w-10/12 md:w-3/4 space-y-6" id={'account-registration-form'}>

            <div className="text-primaryColor text-lg font-semibold flex flex-row justify-start border-b">Account Information</div>
            <div>
                <TextInput
                    label="Name"
                    name="name"
                    value={name}
                    maxLength={200}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </div>
            <div>
                <TextInput
                    label="Account Email"
                    name="accountEmail"
                    value={accountEmail}
                    maxLength={150}
                    onChange={(e) => setAccountEmail(e.target.value)}
                    required
                />
            </div>
            <div>
                <TelephoneInput
                    label="Account Mobile Phone"
                    name="accountMobilePhone"
                    value={accountMobilePhone}
                    onChange={(e) => setAccountMobilePhone(e.target.value)}
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                />
            </div>
            <PrimaryButton text="Add Payment Information" onClick={handleSave} />
            
            
        </div>
    )
}
export default AccountRegistrationForm;