import { Outlet } from "react-router-dom";
import {useLayoutStore, useTenantLoader, useThemeConfig, useUserStore} from "../../context";
import { classNames } from "../../utils/helpers";
import { SideNavMenu, TopNavMenu } from "../Menus";
import {useGetTheme, useTenant} from "../../hooks";
import {clsx} from "clsx";
import {PulseLoader} from "react-spinners";
import constants from "../../utils/constants";
import React from "react";

export default function AuthLayout() {
  const [sidebarMenuOpen] = useLayoutStore((state) => [state.sidebarMenuOpen]);
  const {currentUser} = useUserStore();
    const { theme } = useTenant();
    const {tenantIsLoading} = useTenantLoader();
  return (
    <div className={clsx("flex flex-col")}>
      <TopNavMenu theme={theme}/>
        <div className={clsx("flex flex-row")}>
                        {currentUser && <SideNavMenu />}
                        <div className={clsx( "ml-20 absolute h-[calc(100vh_-_4rem)]  w-[calc(100vw_-_5rem)] min-h-0 top-16")}>
                            {tenantIsLoading
                                ? (<div
                                    className={classNames(tenantIsLoading ? "" : "hidden", "text-xl bg-gray-50 opacity-60 z-50 absolute h-screen w-screen flex justify-center items-center")}>
                                    <PulseLoader
                                        color={theme.primaryHexColor ?? constants.APP_PRIMARY_COLOR}
                                        size={40}
                                        speedMultiplier={0.75}
                                    />
                                </div>)
                                :  <Outlet />}

                        </div>


        </div>
     
    </div>
  );
}
