import {
  CheckCircleIcon,
  UserCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryButton } from "../components/Buttons";
import { Pill } from "../components/Displays";
import { SelectInput, TelephoneInput, TextInput } from "../components/Inputs";
import { useLoaderStore, useUserStore } from "../context";
import { useAlert, useApi } from "../hooks";
import dateHelpers from "../utils/dateHelpers";
import { isValidEmail, userHasRole } from "../utils/helpers";
import constants from "../utils/constants";
import { useActiveTenant, useTenantTheme } from "../context/useSystemTenantStore";

export default function Profile() {
  let { id } = useParams();
  const { currentUser } = useUserStore();
  const { setShowLoader } = useLoaderStore();
  const { fetch, post } = useApi();
  const theme = useTenantTheme();
  const activeTenant = useActiveTenant();
  const alert = useAlert();
  const navigate = useNavigate();

  const [user, setUser] = useState(undefined);
  const [selectedCountry, setSelectedCountry] = useState("US");
  const timezoneOptions = constants.US_TIMEZONES;

  useEffect(() => {
    if (currentUser.id !== Number(id)) {
      // profiles are only accessible to yourself (ie private)
      alert(
        "Error",
        "You do not have access to view this user's profile",
        "error"
      );
      navigate("/");
      return;
    }
    setShowLoader(true);
    let endpoint = userHasRole(currentUser, constants.ROLE_IDS.ADMIN)
      ? `user/getUserInfo/${id}/${activeTenant?.accountId}/${activeTenant?.id}`
      : `user/getUserInfo/${id}`;
    fetch(endpoint)
      .then((res) => {
        setUser(res);
      })
      .catch((err) => {
        alert("Server error", "Could not fetch user profile", "error");
      })
      .finally(() => setShowLoader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const getAdminUser = () => {
  //   let endpoint = activeTenant?.accountId
  //     ? `user/getUserInfo/${id}/${activeTenant?.accountId}/${activeTenant?.id}`
  //     : `user/getUserInfo/${id}`;
  //   fetch(endpoint)
  //     .then((res) => {
  //       setUser(res);
  //       setSelectedCountry(res.countryCode);
  //     })
  //     .catch(() => {
  //       alert("Server error", "Could not retrieve user", "error");
  //     })
  //     .finally(() => setShowLoader(false));
  // }

  const handleChange = (property, value) => {
    setUser({
      ...user,
      [property]: value,
    });
  };

  const handleSave = () => {
    if (!isValid(user, alert, selectedCountry)) return;

    setShowLoader(true);
    let endpoint = userHasRole(currentUser, constants.ROLE_IDS.ADMIN)
      ? `user/saveMargotUser/${activeTenant?.id}`
      : "user/updateProfile";
    post(endpoint, { ...user, countryCode: selectedCountry })
      .then((res) => {
        alert("Success", "Saved profile", "success");
      })
      .catch((err) => {
        alert(
          "Unable to save user",
          err.data.message || "Please try again",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = () => {
    if (
      !user.firstName ||
      !user.firstName.trim() ||
      user.firstName.trim().length <= 1
    ) {
      alert("Invalid input", "First name is not valid", "warning");
      return false;
    }

    if (
      !user.lastName ||
      !user.lastName.trim() ||
      user.lastName.trim().length <= 1
    ) {
      alert("Invalid input", "Last name is not valid", "warning");
      return false;
    }

    if (!user.email || !user.email.trim() || !isValidEmail(user.email)) {
      alert("Invalid input", "Email is not valid", "warning");
      return false;
    }

    if (
      !user.username ||
      !user.username.trim() ||
      user.username.trim().length <= 1
    ) {
      alert("Invalid input", "Username is not valid", "warning");
      return false;
    }

    if (!user.mobilePhoneNumber || !user.mobilePhoneNumber.trim()) {
      alert("Invalid input", "Phone number is not valid", "warning");
      return false;
    }

    if (
      !isPossiblePhoneNumber(user.mobilePhoneNumber.trim(), selectedCountry)
    ) {
      alert("Invalid input", "Phone number is not valid", "warning");
      return false;
    }

    return true;
  };

  return (
    <div>
      {/* Header */}
      <div className="bg-white shadow-lg h-[30vh] flex items-center justify-center space-x-4">
        <div>
          <UserCircleIcon className="w-32 text-primaryColor" style={{color: theme?.tertiaryHexColor}} />
        </div>
        <div>
          <div className="text-xl font-semibold mb-2">
            {user?.firstName || currentUser.firstName}{" "}
            {user?.lastName || currentUser?.lastName}
          </div>
          <div className="text-gray-500">
            {user?.email || currentUser.email} {"| "}
            {user?.username || currentUser.username}
          </div>
          <div className="text-gray-500">
            {user?.timezone || currentUser?.timezone}
          </div>
        </div>
      </div>

      <div className="p-4">
        {/* Roles */}
        <div className="mt-6">
          <div className="space-x-2 space-y-2">
            {currentUser.roles.map((x, i) => {
            let roleName = constants.ROLES.find(r => r.value === x.typeOfRole)?.label;
            return (
              <Pill key={i} title={roleName} />
            )})}
          </div>
        </div>

        {/* Info */}
        <div className="mt-6">
          <div className="">
            <div className="flex text-gray-500">
              Email verified{" "}
              {currentUser.emailVerified === true ? (
                <CheckCircleIcon className="w-6 ml-2 text-success" />
              ) : (
                <XCircleIcon className="w-6 ml-2 text-error" />
              )}
            </div>
            <div className="flex text-gray-500">
              Last logged on{" "}
              <div className="text-primaryColor ml-2">
                {dateHelpers.getRelativeTime(currentUser.lastLoggedOnAt)}
              </div>
            </div>
          </div>

          <div className="mt-4">
            {/* Form */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
              <div>
                <TextInput
                  label="First Name"
                  value={user?.firstName ?? ""}
                  onChange={(e) => handleChange("firstName", e.target.value)}
                  maxLength={30}
                  required
                />
              </div>
              <div>
                <TextInput
                  label="Last Name"
                  value={user?.lastName ?? ""}
                  onChange={(e) => handleChange("lastName", e.target.value)}
                  maxLength={30}
                  required
                />
              </div>
              <div>
                <TextInput
                  label="Email"
                  value={user?.email ?? ""}
                  onChange={(e) => handleChange("email", e.target.value)}
                  maxLength={50}
                  required
                />
              </div>
              <div>
                <TextInput
                  label="Username"
                  value={user?.username ?? ""}
                  onChange={(e) => handleChange("username", e.target.value)}
                  maxLength={30}
                  required
                />
              </div>
              <div>
                <TelephoneInput
                  name="phone"
                  label="Phone number"
                  value={user?.mobilePhoneNumber ?? ""}
                  onChange={(e) =>
                    handleChange("mobilePhoneNumber", e.target.value)
                  }
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                  required
                />
              </div>
              <div>
                <SelectInput
                  label={"Timezone"}
                  options={timezoneOptions}
                  value={timezoneOptions.find((x) => x.value === user?.timezone)}
                  onChange={(e) => {
                    handleChange("timezone", e.value);
                  }}
                  onClear={() => {
                    handleChange("timezone", null);
                  }}
                  isSearchable
                />
              </div>
            </div>

            {/* Submit button */}
            <div className="mt-12">
              <PrimaryButton text="Save" onClick={handleSave} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
