import { useState } from "react";
import { getCountries, isPossiblePhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../components/Buttons";
import { DateInput, PasswordInput, TelephoneInput, TextInput } from "../components/Inputs";
import {useLoaderStore, useRegistrationStore, useThemeConfig} from "../context";
import {useAlert, useApi, useTenant} from "../hooks";
import constants from "../utils/constants";
import { isValidEmail, isValidPassword } from "../utils/helpers";
import { formatPhoneNumber } from "react-phone-number-input";
import dateHelpers from "../utils/dateHelpers";
import moment from "moment";
import {useActiveTenant, useTenantTheme} from "../context/useSystemTenantStore";







export default function Register() {
  const alert = useAlert();
  const navigate = useNavigate();
  const { post } = useApi();
  const { setShowLoader } = useLoaderStore();
  const { registrationUser, regisreationSysTenant, registrationAccount } = useRegistrationStore();

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState("");
  const [dateOfBirth, setDateBirthDate] = useState(new Date());
  const [selectedCountry, setSelectedCountry] = useState("US");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const now = moment(new Date()).hour(0).minute(0);
  const minDate = moment(now).subtract(100, 'year');
  const maxDateString = now.format("YYYY-MM-DD",);
  const minDateString = minDate.format("YYYY-MM-DD");
  const activeTenant = useActiveTenant();
  const theme = useTenantTheme();

  const handleSubmit = async () => {
    if (!isValid()) return;

    setShowLoader(true);

    await post("public/register", {
      email,
      username,
      firstName,
      lastName,
      password,
      dateOfBirth,
      mobilePhoneNumber,
      countryCode: selectedCountry,
    })
      .then((res) => {
        alert(
          "Successfully Registered",
          "Check your inbox to confirm your email",
          "success",
          false
        );
        navigate("/");
      })
      .catch((err) => {
        alert("Unsuccessful", "Unable to register your account", "error");
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = () => {
    if (!email || !email.trim() || !isValidEmail(email)) {
      alert("Invalid input", "Please enter a valid email", "warning");
      return false;
    }

    if (!username || !username.trim() || username.trim().length <= 1) {
      alert("Invalid input", "Please enter a valid username", "warning");
      return false;
    }

    if (!firstName || !firstName.trim() || firstName.trim().length <= 1) {
      alert("Invalid input", "Please enter a valid first name", "warning");
      return false;
    }

    if (!lastName || !lastName.trim() || lastName.trim().length <= 1) {
      alert("Invalid input", "Please enter a valid last name", "warning");
      return false;
    }

    if (!mobilePhoneNumber || !mobilePhoneNumber.trim()) {
      alert("Invalid input", "Please enter a phone number", "warning");
      return false;
    }

    if (!isPossiblePhoneNumber(mobilePhoneNumber.trim(), selectedCountry)) {
      alert("Invalid input", "Please enter a valid phone number", "warning");
      return false;
    }

    if (
      !password ||
      !password.trim() ||
      !passwordConfirmation ||
      !passwordConfirmation.trim()
    ) {
      alert(
        "Invalid input",
        "Please enter a valid password and confirmation password",
        "warning"
      );
      return false;
    }

    if (!isValidPassword(password.trim())) {
      alert("Invalid input", "Password is not complex enough", "warning");
      return false;
    }

    if (password.trim() !== passwordConfirmation.trim()) {
      alert("Invalid input", "Passwords do not match", "warning");
      return false;
    }

    if (!dateOfBirth || dateHelpers.isDateValid(dateOfBirth)) {
      alert("Invalid input", "Please enter a date of birth", "warning");
      return false;
    }

    return true;
  };

  return (
      <div className={"flex  w-full h-full"}>
        <div className={"w-2/5 h-4/5 m-auto"}>
      <h1 className="text-3xl font-bold text-primaryColor" style={{color: theme?.primaryHexColor}}>
        Register with {activeTenant?.name || ''}
      </h1>
      <h5 className="mt-1 mb-6">
        Or{" "}
        <Link to="/" className="text-primaryColor hover:text-primaryColor/50" style={{color: theme?.primaryHexColor}}>
          go to sign in page
        </Link>
      </h5>

      <div className="w-full space-y-6">
        <div>
          <TextInput
            label="Email"
            name="email"
            value={email}
            maxLength={50}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <TextInput
            label="Username"
            name="username"
            value={username}
            maxLength={30}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <TextInput
              label="First Name"
              name="firstName"
              value={firstName}
              maxLength={30}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="w-1/2">
            <TextInput
              label="Last Name"
              name="lastName"
              value={lastName}
              maxLength={30}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
        </div>
        <div>
          <TelephoneInput
            name="phone"
            label="Phone number"
            value={mobilePhoneNumber}
            onChange={(e) => setMobilePhoneNumber(e.target.value)}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            required
          />
        </div>
        <div>
          <DateInput
            label="Birth date"
            subLabel="Select your birthday"
            name="birth-date"
            required
            value={dateOfBirth}
            onChange={(e) => setDateBirthDate(e.target.value)}
            min={minDateString}
            max={maxDateString}
          />
        </div>
        <div>
          <PasswordInput
            label="Password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSubmit();
            }}
            showInfo
            required
          />
        </div>
        <div>
          <PasswordInput
            label="Retype Password"
            name="passwordCofirmation"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            required
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSubmit();
            }}
          />
        </div>
        <PrimaryButton text="Sign Up" onClick={handleSubmit} />
      </div>
      </div>
      </div>
  );
}
