import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  LinkIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import { useAlert, useApi, useTenant } from "../../hooks";
import useSWR from "swr";
import {
  NumberInput,
  SelectInput,
  SingleImageUploader,
  TextAreaInput,
  TextInput,
} from "../Inputs";
import {cloneDeep, isNil, trim} from "lodash";
import { formatCurrency, getFormData, JsonTryParse } from "../../utils/helpers";
import constants from "../../utils/constants";
import Skeleton from "react-loading-skeleton";
import { clsx } from "clsx";
import { useCookies } from "react-cookie";
import cookieJar from "../../utils/cookieJar";
import { ConfirmationModal } from "../Modals";
import {useLoaderStore} from "../../context";
const FEATURE_SUPPLEMENT = "FeatureSupplement";
function ProductAdminSlideOver({ open, onClose, productId, theme }) {
  const { fetch, postAsync, postFormDataAsync } = useApi();
  const alert = useAlert();
  const [cookies] = useCookies([cookieJar.ITEMS.activeSystemTenantId]);
  const [isDirty, setIsDirty] = useState(false);

  const { data, isLoading, error, mutate, isValidating } = useSWR(
    !isDirty && productId > 0
      ? `product/productInfo/${productId}/${cookies.activeSystemTenantId}`
      : null,
    fetch,
  );


  const [product, setProduct] = useState({
    productId: productId,
    systemTenantId: cookies.activeSystemTenantId,
    tag: {}
  });
  const [pictureFiles, setPictureFiles] = useState([]);
  const [imgUrl, setImgUrl] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const { showLoader, setShowLoader}
 = useLoaderStore();
  useEffect(() => {
    setPictureFiles([]);
    setProduct({
      productId: 0,
      systemTenantId: cookies.activeSystemTenantId,
      tag: {}
    });
  }, []);

  useEffect(() => {
    if (!isNil(data) && !error) {
      let fetchedProduct = Object.assign({}, product, data);
      fetchedProduct.durationType =
        constants.DURATION_TYPES.find(
          (x) => x.label === fetchedProduct.durationType,
        )?.value || fetchedProduct.durationType;
      fetchedProduct.productType =
        constants.PRODUCT_TYPES.find(
          (x) => x.label === fetchedProduct.productType,
        )?.value || fetchedProduct.productType;
      setProduct(fetchedProduct);
      // fetchedProduct.tag = JsonTryParse(fetchedProduct.tag) || constants.DEFAULT_EMPTY_TAG;
      // fetchedProduct.systemTenantId = cookies.activeSystemTenantId //NOT SURE IF WE NEED THIS FOR EXISTING PRODUCTS
    }
    if (error) {
      alert(
        "Error",
        "There was an issue retrieving this product. Please try again later.",
        "Error",
      );
      handleClose();
    }
  }, [data, error]);

  function handleChange(property, value) {
    if (!isDirty) {
      setIsDirty(true);
    }
    setProduct({
      ...product,
      [property]: value,
    });
  }

  useEffect(() => {
    setShowLoader(isSaving);
  }, [isSaving]);

  function handleTagChange(property, value) {
    let copy = { ...product };
    copy.tag[property] = value;
    setProduct(copy);
  }



  const getProductImgUrl = (productParam) => {
    if (!productParam?.tag?.imageFilekey) return;
    fetch(`product/GetProductSignedUrl/${productParam.tag.imageFilekey}`)
      .then((res) => {
        setImgUrl(res);
      })
      .catch((err) => {
        alert("Server error", "Unable to fetch product image", "error");
      });
  };

  function buildProductPayload(productItem) {
    let productTag = {...productItem.tag};

    let payload = Object.assign({}, productItem, {
      tag: JSON.stringify(productTag),
    });
    return payload;
  }

  async function handleSave() {
    if (!validateForm()) return;
    if (!cookies?.activeSystemTenantId) return;
    if(isSaving) return;
    setIsSaving(true);
    const hasImage = pictureFiles.length > 0;
    try {
        const savedProduct = await saveProduct(product);
        if(hasImage) {
            const imgKey = await saveProductImage(savedProduct);
            let updatedProduct = convertProductType(savedProduct);
            updatedProduct.tag.imageFileKey = imgKey;
            await saveProduct(updatedProduct);
            // mutate(updatedProduct);

        }
        mutate();
        alert("Product Saved", `${product.name} has been saved successfully.`, "success");
        setTimeout(() => handleClose(true), 1000);
    } catch (e) {
        console.error(e?.data?.message);
    } finally {
      setIsSaving(false);
    }
  }

  function convertProductType(incomingProduct) {
      let productToEdit = cloneDeep(incomingProduct);
      productToEdit.productType = constants.PRODUCT_TYPES.find(
          (x) => x.label === productToEdit?.productType,
      ).value;
      return productToEdit;
  }

  const saveProduct = async (productToSave) => {
    const payload = buildProductPayload(productToSave);
    try {
        const res = await postAsync(`product/saveProduct/${cookies.activeSystemTenantId}`, payload);
        setIsDirty(false);
        return res;
    } catch (e) {
        console.error(e);
        alert("Unable to save product", e?.data?.message || "Please try again", "error");
        throw e;
    }
  };

  async function saveProductImage(savedProduct) {
    let payload = getFormData(pictureFiles[0], "Photo");
    payload.append("Product", product.productId);
    try {
        return await postFormDataAsync("product/SaveProductImage", payload);
    } catch (e) {
        console.error(e);
        alert("Server error", "Unable to save product image", "error");
        throw e;
    }
  }

  // const handleSave =  () => {
  //     const hasImages = pictureFiles.length > 0;
  //     saveProduct(hasImages);
  //     if(hasImages) {
  //
  //     }
  // }

  function validateForm() {
    if (!product.name || !product.name.trim()) {
      alert("Form invalid", "Name must have a value", "warning");
      return false;
    }
    if (
      product.price === null ||
      product.price === undefined ||
      typeof product.price !== "number"
    ) {
      alert("Form invalid", "Price must have a number value", "warning");
      return false;
    }
    if (product.price < 50) {
      alert("Form invalid", "Price must have a value greater than $0.50", "warning");
      return false;
    }
    if (!product.productType) {
      alert("Form invalid", "Product Type Id must have a value", "warning");
      return false;
    }
    if (!product.durationCount) {
      alert("Form invalid", "Duration count must have a value", "warning");
      return false;
    }
    if (!product.durationType) {
      alert("Form invalid", "Duration type must have a value", "warning");
      return false;
    }
    return true;
  }

  useEffect(() => {
  }, [pictureFiles]);

  function handleClose(skipDialog) {
    setProduct({
      productId: 0,
    });
    setPictureFiles([]);
    onClose(skipDialog || !isDirty);
  }

  useEffect(() => {
  }, [product]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div
              className={clsx(
                "pointer-events-none fixed inset-y-0 right-0 flex max-w-full",
                "pl-10 sm:pl-16  h-[calc(100vh-4rem)] top-16",
              )}
            >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-800 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-800 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className={clsx(
                    "pointer-events-auto w-screen max-w-md",
                    "shadow-md shadow-l-0.5 shadow-black shadow-spread-0.5",
                  )}
                >
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div id={"product-header"}>
                      <div
                        style={{
                          background: `linear-gradient(to bottom right, ${theme?.primaryHexColor} 75%, ${theme?.secondaryHexColor} 100%)`,
                        }}
                        className="px-4 py-6 sm:px-6"
                      >
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                            {productId > 0 ? "Edit Product" : "New Product"}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-delete text-white border-color-slate-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={onClose}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        {/*<div className="mt-1">*/}
                        {/*    <p className="text-sm text-indigo-300">*/}
                        {/*        Get started by filling in the information below to*/}
                        {/*        create your new project.*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                    <div className="h-0 flex-1  overflow-y-auto">
                      <div
                        id={"product-content"}
                        className="flex flex-1 flex-col justify-between"
                      >
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div
                            className={clsx(
                              "space-y-6 pb-5 pt-2 flex flex-col",
                            )}
                          >
                            {isSaving && (
                              <div
                                className={clsx("h-100 w-100 bg-black")}
                              ></div>
                            )}
                            <SingleImageUploader
                              files={pictureFiles}
                              setFiles={setPictureFiles}
                              // imageClassName={"h-full w-full object-contain rounded-lg"}
                              imageUrlKey={product?.tag?.imageUrl}
                            />
                            <div>
                              {isLoading ? (
                                <Skeleton />
                              ) : (
                                <TextInput
                                  label="Name"
                                  value={product?.name ?? ""}
                                  disabled={isSaving}
                                  onChange={(e) =>
                                    handleChange("name", e.target.value)
                                  }
                                  required
                                  maxLength={250}
                                />
                              )}
                            </div>
                            <div>
                              {isLoading ? (
                                <Skeleton />
                              ) : (
                                <NumberInput
                                  label="Price"
                                  disabled={isSaving}
                                  value={product?.price ?? ""}
                                  onChange={(e) =>
                                    handleChange(
                                      "price",
                                      e.target.valueAsNumber,
                                    )
                                  }
                                  required
                                  min={0}
                                />
                              )}
                            </div>
                            {product?.price && (<div className="flex flex-row justify-end text-gray-500">{formatCurrency(product.price, true)}</div>)}
                            <div>
                              {isLoading ? (
                                <Skeleton />
                              ) : (
                                <SelectInput
                                  label={"Product Type"}
                                  options={constants.PRODUCT_TYPES}
                                  disabled={isSaving}
                                  value={constants.PRODUCT_TYPES.find(
                                    (x) => x.value === product?.productType,
                                  )}
                                  onChange={(e) =>
                                    handleChange("productType", e.value)
                                  }
                                  onClear={() =>
                                    handleChange("productType", null)
                                  }
                                  isSearchable
                                  required
                                />
                              )}
                            </div>
                            <div>
                              {isLoading ? (
                                <Skeleton />
                              ) : (
                                <SelectInput
                                  label={"Duration Interval"}
                                  options={constants.DURATION_TYPES}
                                  disabled={isSaving}
                                  value={constants.DURATION_TYPES.find(
                                    (x) => x.value === product?.durationType,
                                  )}
                                  onChange={(e) =>
                                    handleChange("durationType", e.value)
                                  }
                                  onClear={() =>
                                    handleChange("durationType", null)
                                  }
                                  isSearchable
                                  required
                                />
                              )}
                            </div>
                            <div>
                              {isLoading ? (
                                <Skeleton />
                              ) : (
                                <NumberInput
                                  label="Duration Count"
                                  disabled={isSaving}
                                  value={product?.durationCount ?? ""}
                                  onChange={(e) =>
                                    handleChange(
                                      "durationCount",
                                      e.target.valueAsNumber,
                                    )
                                  }
                                  required
                                  min={0}
                                />
                              )}
                            </div>
                            {product?.productType === FEATURE_SUPPLEMENT && (
                              <div>
                                {isLoading ? (
                                  <Skeleton />
                                ) : (
                                  <NumberInput
                                    label="Supplemental Feature Qty"
                                    disabled={isSaving}
                                    value={product?.supplementalFeatureQty ?? ""}
                                    onChange={(e) =>
                                      handleChange(
                                        "supplementalFeatureQty",
                                        e.target.valueAsNumber,
                                      )
                                    }
                                    required
                                  />
                                )}
                              </div>
                            )}
                            <div>
                              {isLoading ? (
                                <Skeleton />
                              ) : (
                                <TextAreaInput
                                  label="Description"
                                  disabled={isSaving}
                                  value={product?.tag?.description ?? ""}
                                  onChange={(e) =>
                                    handleTagChange(
                                      "description",
                                      e.target.value,
                                    )
                                  }
                                  maxLength={1000}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id={"product-footer"}
                      className={clsx(
                        "flex flex-shrink-0 justify-end px-4 py-3 z-10 bg-white shadow-md",
                          "shadow-t-0.5 shadow-black shadow-spread-0.5"
                      )}
                    >
                      <button
                        type="button"
                        className={clsx(
                          "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900",
                          "shadow-sm ring-1 ring-inset ring-gray-300 hover:brightness-110",
                        )}
                        onClick={onClose}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={async () => await handleSave()}
                        disabled={isSaving}
                        className={clsx(
                          "ml-4 inline-flex justify-center rounded-md px-3 py-2 text-sm",
                          "font-semibold text-white shadow-sm hover:brightness-125 focus-visible:outline",
                          "focus-visible:outline-2 focus-visible:outline-offset-2",
                        )}
                        style={{ backgroundColor: theme?.secondaryHexColor }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ProductAdminSlideOver;
