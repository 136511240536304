import React, { useState } from 'react'
import {useTenant} from "../../hooks";

const ToggleButton = ({isChecked, setIsChecked, onText, offText}) => {
  const { theme } = useTenant();

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  return (
    <>
      <label className='cursor-pointer select-none items-center'>
        <input
          type='checkbox'
          checked={isChecked}
          onChange={handleCheckboxChange}
          className='sr-only'
        />
        <div className='flex items-center justify-center rounded-md bg-white my-2 shadow-sm shadow-gray-300'>
          <div
            className={`flex-1 text-center rounded m-2 mr-0 ${
              !isChecked ? 'bg-primaryColor text-white mr-2' : 'text-body-color'
            }`}
            style={{backgroundColor: !isChecked ? theme?.primaryHexColor : undefined}}
          >
            {offText}
          </div>
          <div
            className={`flex-1 text-center rounded m-2 ml-0 ${
              isChecked ? 'bg-primaryColor text-white ml-2' : 'text-body-color'
            }`}
            style={{backgroundColor: isChecked ? theme?.primaryHexColor : undefined}}
          >
            {onText}
          </div>
        </div>
      </label>
    </>
  )
}

export default ToggleButton