import { Label } from ".";
import { classNames } from "../../utils/helpers";

export default function TextAreaInput({
  label,
  subLabel,
  name,
  placeholder = "",
  required = false,
  value,
  maxLength,
  onChange,
}) {
  return (
    <>
      {/* Label/required asterisk */}
      {label && <Label label={label} required={required} />}
      <div className={classNames("flex relative", label ? "mt-1" : "")}>
        {/* Input */}
        <textarea
          type="textarea"
          name={name}
          className="p-2 rounded-md focus:outline-none block w-full text-sm border border-gray-200 placeholder-gray-300"
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          onChange={onChange}
          readOnly={!onChange}
          autoComplete="off"
        />
      </div>
      {/* Sublabel */}
      {subLabel && (
        <div className="mt-1 text-gray-400 text-xs text-right">{subLabel}</div>
      )}
    </>
  );
}
