import React, { Fragment, useEffect, useState } from "react";
import { useApi, useTenant } from "../../hooks";
import { Listbox, Transition } from "@headlessui/react";
import Skeleton from "react-loading-skeleton";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import useSWR from "swr";
import { create } from "zustand";
import { isNil } from "lodash/lang";
import {
  americanExpressDark,
  dinersClubDark,
  discoverDark,
  jcbDark,
  masterCardDark,
  visaDark,
} from "../../assets/images/payment_methods/dark";
import {
  americanExpressLight,
  dinersClubLight,
  discoverLight,
  jcbLight,
  masterCardLight,
  visaLight,
} from "../../assets/images/payment_methods/light";
import {JsonTryParse} from "../../utils/helpers";
import {useActiveTenant} from "../../context/useSystemTenantStore";

const paymentMethodStore = create((set, get) => ({
  paymentMethods: [],
  defaultPaymentMethod: null,
  setPaymentMethods: (paymentMethods) => {
    set(() => ({ paymentMethods }));
    const defaultPayment = paymentMethods.find(
      (paymentMethod) => paymentMethod.isDefault,
    );
    if (!isNil(defaultPayment)) {
      get().setDefaultPaymentMethod(defaultPayment);
    }
  },
  setDefaultPaymentMethod: (defaultPaymentMethod) =>
    set(() => ({ defaultPaymentMethod })),
}));

function getCardImage(brand, isDark) {
    switch (brand) {
        case "visa":
            return isDark ? visaDark : visaLight;
        case "amex":
            return isDark ? americanExpressDark : americanExpressLight;
        case "mastercard":
            return isDark ? masterCardDark : masterCardLight;
        case "discover":
            return isDark ? discoverDark : discoverLight;
        case "diners":
            return isDark ? dinersClubDark : dinersClubLight;
        case "jcb":
            return isDark ? jcbDark : jcbLight;
        default:
            return "";
    }
}



const PaymentMethodSelectItem = ({ paymentMethod }) => {
  return (
    <>
      <span className="flex items-center mix-blend-normal">
        <img
          src={getCardImage(paymentMethod?.card?.brand || "", true)}
          alt="card-logo"
          className="h-8"
        />
        <div className="text-lg font-semibold">
          **** **** **** {paymentMethod?.card?.last4}
        </div>
      </span>
      <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
        <ChevronUpDownIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </span>
    </>
  );
};

function PaymentMethodSelect(props) {
    const activeTenant = useActiveTenant();
  const { fetch } = useApi();

  //TODO: Probably pass the url into this as a prop
  const URL = activeTenant?.id
    ? `account/GetAccountPaymentMethods/${activeTenant.id}`
    : null;
  const { data, isLoading, error } = useSWR(URL, fetch);
  const {
    paymentMethods,
    defaultPaymentMethod,
    setPaymentMethods,
    setDefaultPaymentMethod,
  } = paymentMethodStore();

  useEffect(() => {
    if (!isNil(data)) {
        let cardList = data.map(x => {
            const parsedCard = JsonTryParse(x?.json);
            delete x.json;
            return {...x, ...parsedCard};
        });
      setPaymentMethods(cardList);
    }
  }, [data]);



  return (
    <Listbox
      value={defaultPaymentMethod}
      onChange={setDefaultPaymentMethod}
      // disabled={disabled}
    >
      {({ open }) => (
        <>
          <div className="relative mt-2 w-full">
            <Listbox.Button className="mix-blend-normal w-full cursor-default rounded-md bg-transparent py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
              {isLoading ? (
                <Skeleton />
              ) : (
               <PaymentMethodSelectItem paymentMethod={defaultPaymentMethod} />
              )}
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                static
              >
                {paymentMethods
                  ? paymentMethods.map((paymentMethod) => (
                      <Listbox.Option
                        key={paymentMethod.id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "bg-indigo-600 text-white"
                              : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-3 pr-9",
                          )
                        }
                        value={paymentMethod}
                      >
                        {({ selected, active }) => (
                            <PaymentMethodSelectItem paymentMethod={paymentMethod} />
                        )}
                      </Listbox.Option>
                    ))
                  : null}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export default PaymentMethodSelect;
