import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

const useDebounce = ({ toDebounce }) => {
  const [debounced, setDebounced] = useState(toDebounce);

  const updateDebounced = useCallback(
    debounce((newToDebounce) => {
      setDebounced(newToDebounce);
    }, 600),
    []
  );

  useEffect(() => {
    updateDebounced(toDebounce);
  }, [toDebounce, updateDebounced]);

  return {
    debounced,
    updateDebounced
  };
};

export default useDebounce;
