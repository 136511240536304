import {useNavigate} from "react-router-dom";
import {useAlert, useApi} from "../../hooks";
import {useLoaderStore} from "../../context";
import {useEffect, useState} from "react";
import constants from "../../utils/constants";
import {switchSortDirection} from "../../utils/helpers";
import {PageHeader} from "../../components/Layouts";
import {ArrowPathIcon, CheckIcon, PlusCircleIcon, PlusIcon, XMarkIcon} from "@heroicons/react/24/solid";
import {ActiveFilter, PrimaryTable} from "../../components/Tables";
import {Popover} from "@headlessui/react";
import {CheckboxInput, TextInput} from "../../components/Inputs";
import {PrimaryButton} from "../../components/Buttons";
import {useActiveTenant} from "../../context/useSystemTenantStore";
import useSWR from "swr";
import useDevManager from "../../context/useDevManager";

const EMPTY_FILTERS = {
    activeOnly: false,
};
export default function SystemTenantCustomers() {
    const navigate = useNavigate();
    const alert = useAlert();
    const { post, fetch } = useApi();
    const { setShowLoader } = useLoaderStore();
    const activeSystemTenant = useActiveTenant();
    const { IS_DEV } = useDevManager();
    const [activeFilters, setActiveFilters] = useState({
        ...EMPTY_FILTERS,
        activeOnly: true,
    });
    const [stagedFilters, setStagedFilters] = useState(activeFilters);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortDirection, setSortDirection] = useState(
        constants.SORT_DIRECTIONS.ASCENDING
    );
    const [sortField, setSortField] = useState("id");
    const [displayCount, setDisplayCount] = useState(
        constants.DISPLAY_PER_PAGE ?? 25
    );
    const [payloadFilters, setPayloadFilters] = useState({
        ...activeFilters,
        currentPage,
        sortDirection,
        sortField
    });
    const getCustomerURL = 'systemTenant/customers';
    const {data, isLoading, mutate, error} = useSWR([getCustomerURL, payloadFilters], ([getCustomerURL, payloadFilters]) => fetch(getCustomerURL, payloadFilters));
    // const [data, setData] = useState({ list: [], totalCount: 0 });
    // const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setPayloadFilters({
            currentPage,
            sortDirection,
            ...activeFilters,
            pageSize: displayCount,
            systemTenantId: activeSystemTenant.id,
            sortField,
        })
    }, [currentPage, displayCount, activeFilters, sortDirection, sortField, activeSystemTenant]);

    useEffect(() => {
        setStagedFilters(activeFilters);
    }, [activeFilters]);

    const getData = () => {
        // setIsLoading(true);
        fetch(`systemTenant/GetSystemTenantCustomers/${activeSystemTenant?.id}`, {
            pageNumber: currentPage,
            pageSize: displayCount,
            sortDirection,
            sortField,
            isExact: false,
            ...activeFilters,
        })
            .then((res) => {
                console.error("res");
                // setData(res);
            })
            .catch((err) => {
                alert("Server error", "Could not retrieve accounts", "error");
            });
            // .finally(() => setIsLoading(false));
    };

    const handleSort = (field) => {
        if (sortField === field) {
            setSortDirection(switchSortDirection(sortDirection));
        } else {
            setSortField(field);
        }
    };

    const toggleActiveStatus = (account) => {
        setShowLoader(true);
        post(`account/toggleAccountActive/${account.id}`)
            .then((res) => {
                alert("Success",
                    res.message || "Account active status toggled",
                    "success");
                getData();
            })
            .catch((err) => {
                alert("Error",
                    err.data.message || "Account active status could not be toggled",
                    "error");
                console.error(err);
            })
            .finally(() => {
                setShowLoader(false);
            });
    };
    // Insert toggleActiveStatus here

    return (
        <div>
            {/* Header */}
            <div className="flex justify-between items-center mt-4 px-4">
                <PageHeader title="Customers" ignoreSpacing />
                {/* <PlusCircleIcon
                    className="w-8 text-primaryColor hover:text-secondaryColor cursor-pointer"
                    onClick={() => navigate("/accounts/0")}
                /> */}
            </div>

            {/* Filter section */}
            <CustomerFilterSection
                stagedFilters={stagedFilters}
                setStagedFilters={setStagedFilters}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
            />

            {/* Table */}
            <div className="px-2">
            <PrimaryTable
                isLoading={isLoading}
                totalCount={data?.totalCount}
                displayCount={displayCount}
                currentPage={currentPage}
                setDisplayCount={setDisplayCount}
                setCurrentPage={setCurrentPage}
                header={
                    <PrimaryTable.Header>
                        {IS_DEV &&
                        <PrimaryTable.HeaderColumn
                            display="Id"
                            onSort={() => handleSort("id")}
                            isSortingBy={sortField === "id"}
                            sortDirection={sortDirection}
                        />}
                        <PrimaryTable.HeaderColumn
                            display="First Name"
                            onSort={() => handleSort("firstName")}
                            isSortingBy={sortField === "firstName"}
                            sortDirection={sortDirection}
                        />
                        <PrimaryTable.HeaderColumn
                            display="Last Name"
                            onSort={() => handleSort("lastName")}
                            isSortingBy={sortField === "lastName"}
                            sortDirection={sortDirection}
                        />
                        <PrimaryTable.HeaderColumn display="Email" />
                        <PrimaryTable.HeaderColumn display="Phone" />
                        <PrimaryTable.HeaderColumn display="" small/>
                    </PrimaryTable.Header>
                }
            >
                {data &&
                    data.list &&
                    data.list.map((x, i) => (
                        <PrimaryTable.Row
                            key={`${x.id}-${i}`}
                            index={i}
                            onClick={(e) => {
                                if (!x.deactivatedAt) {
                                    navigate(`/customers/${x.id}`);
                                }
                            }}
                        >
                            {IS_DEV && <PrimaryTable.Cell>{x.id}</PrimaryTable.Cell>}
                            <PrimaryTable.Cell>
                                {x.firstName}
                            </PrimaryTable.Cell>
                            <PrimaryTable.Cell>
                                {x.lastName}
                            </PrimaryTable.Cell>
                            <PrimaryTable.Cell>
                                {x.email}
                            </PrimaryTable.Cell>
                            <PrimaryTable.Cell>
                                {x.mobilePhone}
                            </PrimaryTable.Cell>
                            {/* Insert cells here */}
                            <PrimaryTable.Cell>
                                <div className="flex justify-center">
                                    {x.deactivatedAt ? (
                                        <ArrowPathIcon
                                            title="Activate"
                                            className="w-6 mx-1 text-white bg-green-500 rounded-md p-0.5 cursor-pointer"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleActiveStatus(x);
                                            }}
                                        />
                                    ) : (
                                        <XMarkIcon
                                            title="Deactivate"
                                            className="w-6 mx-1 text-white bg-delete rounded-md p-0.5 cursor-pointer"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleActiveStatus(x);
                                            }}
                                        />
                                    )}
                                    {/* Insert flip active status here */}
                                </div>
                            </PrimaryTable.Cell>
                        </PrimaryTable.Row>
                    ))}
            </PrimaryTable>
            </div>
        </div>
    );
}

const CustomerFilterSection = ({
                                  stagedFilters,
                                  setStagedFilters,
                                  activeFilters,
                                  setActiveFilters,
                              }) => {
    return (
        <div className="relative mb-4 rounded-xs shadow-sm bg-white p-4">
            <Popover as="div" className="relative">
                {({open}) => (
                    <>
                        <Popover.Button
                            className="flex items-center text-sm bg-gray-100 px-3 py-2 rounded-sm hover:bg-gray-200 relative focus:outline-none">
                            <PlusIcon className="w-4 mr-1"/>
                            FILTERS
                        </Popover.Button>
                        <Popover.Panel
                            as="div"
                            className="absolute grid gap-y-4 z-50 w-full sm:w-1/3 origin-bottom-right bg-white rounded-md p-4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none mt-1"
                        >
                            <div>
                                <CheckboxInput
                                    name="active-only"
                                    label="Show only active users"
                                    checked={stagedFilters.activeOnly === true}
                                    onChange={() =>
                                        setStagedFilters({
                                            ...stagedFilters,
                                            activeOnly: stagedFilters.activeOnly ? false : true,
                                        })
                                    }
                                />
                            </div>
                            <div>
                                <TextInput
                                    label="Name"
                                    placeholder="Enter Name" to filter by
                                    value={stagedFilters.name}
                                    onChange={(e) =>
                                        setStagedFilters({
                                            ...stagedFilters,
                                            name: e.target.value || undefined,
                                        })
                                    }
                                />
                            </div>
                            {/* Add filters here */}
                            <PrimaryButton
                                text="Apply filters"
                                onClick={() => setActiveFilters(stagedFilters)}
                            />
                        </Popover.Panel>
                    </>
                )}
            </Popover>

            {/* Selected filters */}
            {Object.values(activeFilters).find(
                (val) => val !== undefined && val !== false
            ) ? (
                <div className="flex items-center space-x-2 pt-6 text-sm">
                    <div>Selected:</div>
                    <ActiveFilter
                        display="Show active only"
                        value={
                            activeFilters.activeOnly ? (
                                <CheckIcon className="w-4 text-green-600"/>
                            ) : undefined
                        }
                        onClear={() =>
                            setActiveFilters({
                                ...activeFilters,
                                activeOnly: false,
                            })
                        }
                    />

                    <ActiveFilter
                        display="Name"
                        value={activeFilters.name}
                        onClear={() =>
                            setActiveFilters({
                                ...activeFilters,
                                name: undefined,
                            })
                        }
                    />
                    {/* Add active filters here */}

                    <div
                        className="cursor-pointer bg-gray-100 px-3 py-2 text-xs hover:bg-gray-200"
                        onClick={() => {
                            setActiveFilters({...EMPTY_FILTERS});
                        }}
                    >
                        Clear all
                    </div>
                </div>
            ) : (
                <div className="pt-6 text-sm text-gray-400">No active filters</div>
            )}
        </div>
    );
}