import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { Dispatch, SetStateAction } from "react";

export default function AddNewPaymentMethod({
  setPaymentMethodModalOpen,
}) {
  return (
    <div
      onClick={() => setPaymentMethodModalOpen(true)}
      className="flex flex-col items-center justify-center p-1 bg-gray-100 cursor-pointer w-full rounded-lg border min-h-[10rem] hover:bg-gray-50"
    >
      <PlusCircleIcon className="w-16 h-16 text-gray-300" />
      <div className="text-gray-500">Add a new payment method</div>
    </div>
  );
}