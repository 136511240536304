import {create} from "zustand";


const useTenantLoader = create((set, get) => ({
    tenantIsLoading: false,
    setTenantIsLoading: (isLoading) => {
        if(get().isLoading) return;
        set(() => ({isLoading}))
    },
    }
));

export default useTenantLoader;