import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { CheckIcon, Square2StackIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useMemo, useState } from "react";
import { ModalWrapper } from ".";
import { PrimaryButton } from "../Buttons";
import { TextInput } from "../Inputs";

// Modal that requires user to type input to confirm selection
// Use this as an example of how to create other modals (using ModalWrapper)
export default function ConfirmationModal({
  modalOpen,
  title,
  message,
  textToMatch,
  confirmButtonText,
  Icon = Square2StackIcon,
  iconColor = "gray",
  iconColorShade = "-500",
  setModalOpen,
  onConfirm,
}) {
  const [userInput, setUserInput] = useState("");

  const userInputMatches = useMemo(
    () => textToMatch === userInput,
    [textToMatch, userInput]
  );

  return (
    <ModalWrapper open={modalOpen} setOpen={setModalOpen}>
      <div className="w-[85vw] lg:w-[35vw]">
        <div className="pb-4 text-center flex items-center flex-col">
          <Icon className={`w-5 text-${iconColor}${iconColorShade || ""}`} />
          {title}
        </div>
        {message && (
          <div className="pb-8 mx-6 text-center text-sm text-gray-500">
            {message}
          </div>
        )}

        <div className="mb-8">
          <TextInput
            placeholder={textToMatch}
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            iconColor={userInputMatches ? "success" : "notification"}
            Icon={userInputMatches ? CheckIcon : ExclamationTriangleIcon}
          />
        </div>

        <div className="mt-3">
          <PrimaryButton
            text={confirmButtonText}
            onClick={onConfirm}
            disabled={!userInputMatches}
          />
        </div>
      </div>
    </ModalWrapper>
  );
}
