import { useEffect, useState } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useNavigate, useParams } from "react-router-dom";
import { GoBack, PrimaryButton } from "../components/Buttons";
import { CollapsibleSection, Pill } from "../components/Displays";
import {
  Label,
  MultiSelectInput,
  TelephoneInput,
  TextInput,
} from "../components/Inputs";
import { PageHeader } from "../components/Layouts";
import { useLoaderStore, useUserStore } from "../context";
import {useAlert, useApi, useTenant} from "../hooks";
import constants from "../utils/constants";
import { convertToIdNameList, isValidEmail, userHasRole } from "../utils/helpers";
import {useActiveTenant, useTenantTheme} from "../context/useSystemTenantStore";

export default function User() {
  let { id, accountId } = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const isCreatingNew = id === "0";

  const { fetch, post } = useApi();
  const { setShowLoader } = useLoaderStore();
  const [selectedCountry, setSelectedCountry] = useState("US");
  const { currentUser } = useUserStore();
  const activeTenant = useActiveTenant();
  const theme = useTenantTheme();
  const SYSTEM_TENANT_ID = activeTenant?.id;
  const IsSysAdmin = userHasRole(currentUser, constants.ROLE_IDS.SYS_ADMIN);
  const [user, setUser] = useState(undefined);
  useEffect(() => {
    if (isCreatingNew) {
      let newUser = { id: 0, roles: [] };
      if (accountId) {
        newUser.roles.push({id: 0, typeOfRole: constants.ROLE_IDS.ADMIN, accountId: accountId});
      } else {
        newUser.roles.push({id: 0, typeOfRole: constants.ROLE_IDS.SYS_ADMIN});
      }

      setUser(newUser);
      return;
    }
    setShowLoader(true);
    getUser();
  }, []);

  const getUser = () => {
    let endpoint = accountId
      ? `user/getUserInfo/${id}/${accountId}/${SYSTEM_TENANT_ID}`
      : `user/getUserInfo/${id}`;
    fetch(endpoint)
      .then((res) => {
        setUser(res);
        setSelectedCountry(res.countryCode);
      })
      .catch(() => {
        alert("Server error", "Could not retrieve user", "error");
      })
      .finally(() => setShowLoader(false));
  }

  const handleChange = (property, value) => {
    setUser({
      ...user,
      [property]: value,
    });
  };

  const handleSave = () => {
    if (!isValid(user, alert, selectedCountry)) return;

    setShowLoader(true);
    let endpoint = accountId
      ? `user/saveMargotUser/${SYSTEM_TENANT_ID}`
      : "user/saveUser";
    post(endpoint, { ...user, countryCode: selectedCountry })
      .then(() => {
        alert("Success", "Saved user", "success");
        navigate("/users");
      })
      .catch((err) => {
        alert(
          "Unable to save user",
          err.data.message || "Please try again",
          "error"
        );
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = () => {
    if (
      !user.firstName ||
      !user.firstName.trim() ||
      user.firstName.trim().length <= 1
    ) {
      alert("Invalid input", "First name is not valid", "warning");
      return false;
    }

    if (
      !user.lastName ||
      !user.lastName.trim() ||
      user.lastName.trim().length <= 1
    ) {
      alert("Invalid input", "Last name is not valid", "warning");
      return false;
    }

    if (!user.email || !user.email.trim() || !isValidEmail(user.email)) {
      alert("Invalid input", "Email is not valid", "warning");
      return false;
    }

    if (
      !user.username ||
      !user.username.trim() ||
      user.username.trim().length <= 1
    ) {
      alert("Invalid input", "Username is not valid", "warning");
      return false;
    }

    if (!user.mobilePhoneNumber || !user.mobilePhoneNumber.trim()) {
      alert("Invalid input", "Phone number is not valid", "warning");
      return false;
    }

    if (
      !isPossiblePhoneNumber(user.mobilePhoneNumber.trim(), selectedCountry)
    ) {
      alert("Invalid input", "Phone number is not valid", "warning");
      return false;
    }

    if (!user.roles || user.roles.length === 0) {
      alert("Invalid input", "User must have at least one role", "warning");
      return false;
    }

    return true;
  };

  const handleForgotPassword = () => {
    if (!isValid() || !user.email) return;

    setShowLoader(true);
    let endpoint = accountId
      ? "public/adminforgotPassword"
      : "public/forgotPassword";
    post(endpoint, user.email)
    .then((res) => {
      alert("Success!", "Reset password email sent", "success", false);
    })
    .catch((err) => {
      alert("Unsuccessful", "We could not send a reset password email", "error");
      console.error(err);
    })
    .finally(() => {
      setShowLoader(false);
    });
    
  };

  return (
    <div>
      {/* Header */}
      <PageHeader
        title={
          isCreatingNew || !user ? "Create new user" : `Manage ${user.username}`
        }
      />
      <div className="ml-4">
        <GoBack display="Manage all users" navigateTo="/users" />
      </div>
      <div className="bg-gray-100 shadow-md rounded-md shadow-primaryColor/25">
        {/* Form */}
        <CollapsibleSection title="Info">
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
            <div>
              <TextInput
                label="First Name"
                value={user?.firstName ?? ""}
                onChange={(e) => handleChange("firstName", e.target.value)}
                maxLength={30}
                required
              />
            </div>
            <div>
              <TextInput
                label="Last Name"
                value={user?.lastName ?? ""}
                onChange={(e) => handleChange("lastName", e.target.value)}
                maxLength={30}
                required
              />
            </div>
            <div>
              <TextInput
                label="Email"
                value={user?.email ?? ""}
                onChange={(e) => handleChange("email", e.target.value)}
                maxLength={50}
                required
              />
            </div>
            <div>
              <TextInput
                label="Username"
                value={user?.username ?? ""}
                onChange={(e) => handleChange("username", e.target.value)}
                maxLength={30}
                required
              />
            </div>
            <div>
              <TelephoneInput
                label="Phone Number"
                value={user?.mobilePhoneNumber ?? ""}
                onChange={(e) =>
                  handleChange("mobilePhoneNumber", e.target.value)
                }
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                required
              />
            </div>
            <div>
              <div>
                <Label label={"Roles"} />
                <div className="relative flex flex-row flex-wrap">
                {user?.roles.map((x, i) => {
                  let roleName = constants.ROLES.find(r => r.value === x.typeOfRole || r.value === x.roleId)?.label;
                  return (
                    <span className="mt-1"><Pill key={i} title={roleName} /></span>
                  )})}
                </div>
              </div>
            </div>
          </div>
        </CollapsibleSection>
        
        {/* Submit button */}
        <div className="mt-12 pb-4 px-4">
          <PrimaryButton
            text={isCreatingNew ? "Create" : "Save"}
            onClick={handleSave}
          />
        </div>
      </div>
      <div className="m-4">
        <button 
          className="p-4 text-white font-medium underline bg-transparent rounded-full hover:italic" 
          style={{
            // backgroundColor: theme?.secondaryHexColor,
            color: theme?.secondaryHexColor,
            display: isCreatingNew ? "none" : undefined
          }}
          onClick={handleForgotPassword}
        >
            Send Forgot Password Email
        </button>
      </div>
    </div>
  );
}
