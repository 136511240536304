export default function Label({ label, required }) {
  const id = label ? label.split(" ").join("-").toLowerCase() : "";

  return (
    <label htmlFor={id} className="block text-sm font-normal text-gray-700">
      {label}
      {required && <span className="inline-block text-red-700">*</span>}
    </label>
  );
}
