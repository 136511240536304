import { useTheme } from "../../context";
import {useTenant} from "../../hooks";

export default function Pill({title}) {
  const { theme } = useTenant();
  return (
    <div className="inline-block px-6 py-3 bg-primaryColor text-white text-md shadow-lg rounded-full" style={{backgroundColor: theme?.primaryHexColor}}>
      {title}
    </div>
  );
}