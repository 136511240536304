import React, {useEffect, useMemo, useState} from 'react';
import {useCookies} from "react-cookie";
import {redirect, Route, useLocation, useNavigate} from "react-router-dom";
import useSWR from "swr";
import {buildUrlWithoutSubdomain, tryGetSubdomain} from "../../utils/helpers";
import {cache} from "swr/_internal";
import {useApi} from "../../hooks";
import {useTenantLoader} from "../../context";
import {PulseLoader} from "react-spinners";
import {clsx} from "clsx";

function SubdomainGuard({children}) {
  const [cookies] = useCookies(["subdomain"]);
  const location = useLocation();
  const navigate = useNavigate();
  const subdomain = tryGetSubdomain();
  const { fetch } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const shouldValidate = subdomain && subdomain !== cookies?.subdomain;
  const url = subdomain && !cookies.subdomain ? `public/ping/${subdomain}` : null;
  const {
    data,
    isLoading: pingLoading,
    error,
    isValidating,
    mutate,
  } = useSWR(url, fetch, {errorRetryCount: 0});

  const { setTenantIsLoading } = useTenantLoader();
  //Check if on subdomain route
  //Check if has subdomain cookie which means valid route.
  //

  // if (error && error.data.message) {
  //   if (error.data.message === "Tenant not found.") {
  //     const url = buildUrlWithoutSubdomain(subdomain);
  //     window.location.replace(url + "/tenant/not-found");
  //   }
  // }

  // async function pingServerForRedirect() {
  //     if(!subdomain) return;
  //     if(subdomain !== cookies?.subdomain) return;
  //     await fetch(`public/ping/${subdomain}`)
  //         .then((res) => {
  //
  //         }).catch((err) => {
  //             if(err.status === 404) {
  //                 return redirect('/not-found');
  //             }
  //         })
  // }

  // if(error) {
  //     console.error('err: ', error);
  // }

  // useEffect(() => {
  //     // pingServerForRedirect();
  // }, [location]);
  
  if (isLoading || isValidating) {
      return (<div
              className={clsx(isLoading ? "" : "hidden", "text-xl bg-gray-50 opacity-60 z-50 absolute h-screen w-screen flex justify-center items-center")}>
              <PulseLoader
                  size={40}
                  speedMultiplier={0.75}
              />
          </div>)
  }
  if(error?.data?.message === "Tenant not found.") {
      const url = buildUrlWithoutSubdomain(subdomain);
      window.location.replace(url + "/tenant/not-found");
      return <></>;
  }
  else {
      return children;
  }
}

export default SubdomainGuard;