import React, {useEffect, useState} from "react";
import "./App.css";
import { Loader, Notification } from "./components/Alerts";
import {useLoaderStore, useUserStore} from "./context";
import storage from "./utils/storage";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import cookieJar from "./utils/cookieJar";
import ViewController from "./components/Layouts/ViewController";
import {useTenant} from './hooks/index';
import {isNil} from "lodash/lang";
import useSWR from "swr";
import {useApi, useSubdomain} from "./hooks";
import {useActiveTenant, useSystemTenantLists, useTenantActions} from "./context/useSystemTenantStore";
import {useCookies} from "react-cookie";
const stripePromise = loadStripe(
    //import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || ""
    "pk_test_51OcCpVKS7DupIgmojiZnZYbKh719wfuRUbK6EEW9fVZTyDrjpyJg8PiYV5HuGX6RaOiVMcSXFpkb6NUeIf5Hlzzk001wFzksAr"
);





function App() {
  const {fetch} = useApi();
  const { currentUser, setCurrentUser, setImpersonating } = useUserStore();
  const {setShowLoader} = useLoaderStore();
  const { isLoading, loadTenantList } = useTenant();
  const activeTenant = useActiveTenant();
  const tenantList = useSystemTenantLists();
  const { setSystemTenants } = useTenantActions();
  const [cookies, setCookie, removeCookie] = useCookies(['token']);



  useEffect(() => {
    const systemTenantsCookie = cookieJar.getItem(storage.ITEMS.systemTenants);
    const user = cookieJar.getItem(storage.ITEMS.currentUser);
    const isImpersonating = cookieJar.getItem(storage.ITEMS.isImpersonating) || false;
    setCurrentUser(user);
    setImpersonating(isImpersonating);
  }, [setCurrentUser, setImpersonating]);


  useEffect(() => {
    if(!isNil(cookies?.token) && tenantList.length === 0) {
        loadTenantList();
    }
  }, [cookies]);


    useEffect(() => {
        setShowLoader(isLoading);
    }, [isLoading]);





  return (
    <div className="h-full mx-auto bg-backgroundColor overflow-x-hidden overflow-y-auto">
      <Notification />
      <Loader />
      <Elements stripe={stripePromise}>
      <ViewController/>
      {/*{currentUser ? (*/}
      {/*  <Routes>*/}
      {/*    <Route element={<AuthLayout />}>*/}
      {/*      <Route path="/" element={<Dashboard />} />*/}
      {/*      <Route path="/users" element={<Users />} />*/}
      {/*      <Route path="/users/:id" element={<User />} />*/}
      {/*      <Route path="/profile/:id" element={<Profile />} />*/}
      {/*      <Route path="/demo" element={<Demo />} />*/}
      {/*      <Route path="/accounts" element={<AccountSettings />} />*/}
      {/*      <Route path="/accounts/:id" element={<Account />} />*/}
      {/*      <Route path="/systemTenants" element={<SystemTenants />} />*/}
      {/*      <Route path="/systemTenants/:id" element={<SystemTenant />} />*/}
      {/*      <Route path="/products" element={<SystemTenantProducts />} />*/}
      {/*      <Route path="/products/:id" element={<Product />} />*/}
      {/*      /!* Add more routes here *!/*/}
      {/*      <Route path="*" element={<NotFound404 />} />*/}
      {/*    </Route>*/}
      {/*  </Routes>*/}
      {/*) : (*/}
      {/*  <Routes>*/}
      {/*    <Route element={<PublicLayout />}>*/}
      {/*      <Route path="/" element={<Login />} />*/}
      {/*      <Route path="/forgotPassword" element={<ForgotPassword />} />*/}
      {/*      <Route path="/resetPassword/:token" element={<ResetPassword />} />*/}
      {/*      <Route path="/verifyEmail/:token" element={<EmailVerification />} />*/}
      {/*      <Route path="/register" element={<Register />} />*/}
      {/*      <Route path="/adminRegistration" element={<AdminRegistration />} />*/}
      {/*      <Route path="*" element={<NotFound404 />} />*/}
      {/*    </Route>*/}
      {/*  </Routes>*/}
      {/*)}*/}
      </Elements>
    </div>
  );
}

export default App;
