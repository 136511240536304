import React from 'react';

const CardInput = ({ label, children }) => {
    return (
        <div>
            <div className="mb-2">{label}</div>
            <div className="rounded-md bg-gray-100 p-2 border border-gray-200 shadow-sm outline-1 outline-fabricPink hover:bg-gray-200 focus:outline">
                {children}
            </div>
        </div>
    );
};

export default CardInput;