export const DUMMY_PEOPLE = [
  { label: "Ali Tahbaz", value: 1 },
  { label: "Dane Cook", value: 2 },
  { label: "James Reese", value: 3 },
  { label: "Chris Jackson", value: 4 },
  { label: "Sacha Gordon", value: 5 },
];

export const TEMP_USERS = [
  {
    id: 1,
    firstName: "Halie",
    lastName: "Chmura",
    username: "haliechm",
    email: "halie@sightsource.net",
    lastLoggedOn: "07/01/23",
    isActive: true,
  },
  {
    id: 2,
    firstName: "Cassidy",
    lastName: "Chmura",
    username: "cass",
    email: "cassidyt@gmail.com",
    lastLoggedOn: "06/30/23",
    isActive: false,
  },
  {
    id: 3,
    firstName: "Murphy",
    lastName: "Chmura",
    username: "murph1234",
    email: "murphy@gmail.com",
    lastLoggedOn: "",
    isActive: true,
  },
  {
    id: 4,
    firstName: "Jane",
    lastName: "Doe",
    username: "jane_doe",
    email: "jane@yahoo.com",
    lastLoggedOn: "04/15/2021",
    isActive: true,
  },
];
