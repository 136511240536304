import {useRef, useState} from "react";
import { getCountries, isPossiblePhoneNumber } from "react-phone-number-input";
import { PrimaryButton } from "../Buttons";
import { PasswordInput, SelectInput, TelephoneInput, TextInput } from "../Inputs";
import {useAdminRegistrationStepStore, useRegistrationStore} from "../../context";
import { useAlert, useApi } from "../../hooks";
import { isValidEmail, isValidPassword } from "../../utils/helpers";
import {getTimezonesForCountry} from "countries-and-timezones";
import {Tooltip} from "react-tooltip";
import {InfoSlideOver} from "../Menus";
import constants from "../../utils/constants";

export default function UserRegistrationForm(){
    const { setRegistrationUser, registrationUser } = useRegistrationStore();
    const alert = useAlert();
    const [email, setEmail] = useState(registrationUser?.email ?? "");
    const [username, setUsername] = useState(registrationUser?.username ?? "");
    const [firstName, setFirstName] = useState(registrationUser?.firstName ?? "");
    const [lastName, setLastName] = useState(registrationUser?.lastName ?? "");
    const [mobilePhoneNumber, setMobilePhoneNumber] = useState(registrationUser?.mobilePhoneNumber ?? "");
    const [timezone, setTimezone] = useState(registrationUser?.timezone ?? null);
    const [selectedCountry, setSelectedCountry] = useState("US");
    const [password, setPassword] = useState(registrationUser?.password ?? "");
    const [passwordConfirmation, setPasswordConfirmation] = useState(registrationUser?.password ?? "");
    const {completeStep, currentStep} = useAdminRegistrationStepStore();
    // // const timezones = ct.getAllTimezones();
    // const timezones = getTimezonesForCountry('US');
    // const timezoneOptions = timezones.map((tz) => ({
    //     label: `${tz.name} (${tz.utcOffsetStr})`,
    //     value: tz.name
    // }));

    const timezoneOptions = constants.US_TIMEZONES;

    const isValid = () => {
        if (!email || !email.trim() || !isValidEmail(email)) {
          alert("Invalid input", "Please enter a valid email", "warning");
          return false;
        }
    
        if (!username || !username.trim() || username.trim().length <= 1) {
          alert("Invalid input", "Please enter a valid username", "warning");
          return false;
        }
    
        if (!firstName || !firstName.trim() || firstName.trim().length <= 1) {
          alert("Invalid input", "Please enter a valid first name", "warning");
          return false;
        }
    
        if (!lastName || !lastName.trim() || lastName.trim().length <= 1) {
          alert("Invalid input", "Please enter a valid last name", "warning");
          return false;
        }
    
        if (!mobilePhoneNumber || !mobilePhoneNumber.trim()) {
          alert("Invalid input", "Please enter a phone number", "warning");
          return false;
        }
    
        if (!isPossiblePhoneNumber(mobilePhoneNumber.trim(), selectedCountry)) {
          alert("Invalid input", "Please enter a valid phone number", "warning");
          return false;
        }
    
        if (
          !password ||
          !password.trim() ||
          !passwordConfirmation ||
          !passwordConfirmation.trim()
        ) {
          alert(
            "Invalid input",
            "Please enter a valid password and confirmation password",
            "warning"
          );
          return false;
        }
    
        if (!isValidPassword(password.trim())) {
          alert("Invalid input", "Password is not complex enough", "warning");
          return false;
        }
    
        if (password.trim() !== passwordConfirmation.trim()) {
          alert("Invalid input", "Passwords do not match", "warning");
          return false;
        }
    
        return true;
    };

    const handleSave = async () => {
        if (!isValid()) return;
        setRegistrationUser({
            email: email,
            username: username,
            firstName: firstName,
            lastName: lastName,
            mobilePhoneNumber: mobilePhoneNumber,
            timezone: timezone,
            password: password,
            countryCode: selectedCountry
        })
        completeStep();
    };

    // emailTextInput?.addEventListener("focus", (event) => {
    //     console.log('focused event');
    //
    // });
    const inputFocusRef = useRef(null);

    return (
      <div className="w-10/12 md:w-9/12 space-y-4">
        <div className="text-primaryColor text-lg font-semibold flex flex-row justify-start border-b">
          User Information
        </div>
          <TextInput
            id="email-text-input"
            label="Email"
            name="email"
            value={email}
            ref={inputFocusRef}
            tabIndex={"0"}
            maxLength={50}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

        <div>
          <TextInput
            label="Username"
            name="username"
            value={username}
            maxLength={30}
            tabIndex={"0"}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <TextInput
              label="First Name"
              name="firstName"
              value={firstName}
              maxLength={30}
              tabIndex={"0"}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="w-1/2">
            <TextInput
              label="Last Name"
              name="lastName"
              value={lastName}
              maxLength={30}
              tabIndex={"0"}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
        </div>
        <div>
          <TelephoneInput
            name="phone"
            label="Phone number"
            value={mobilePhoneNumber}
            onChange={(e) => setMobilePhoneNumber(e.target.value)}
            selectedCountry={selectedCountry}
            tabIndex={"0"}
            setSelectedCountry={setSelectedCountry}
            required
          />
        </div>
        <div>
          <SelectInput
            label={"Timezone"}
            options={timezoneOptions}
            value={timezoneOptions.find((x) => x.value === timezone)}
            onChange={(e) => {
              setTimezone(e.value);
            }}
            onClear={() => {
              setTimezone(null);
            }}
            isSearchable
          />
        </div>
        <div>
          <PasswordInput
            label="Password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSave();
            }}
            showInfo
            required
            tabIndex={"0"}
          />
        </div>
        <div>
          <PasswordInput
            label="Retype Password"
            name="passwordCofirmation"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            required
            tabIndex={"0"}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSave();
            }}
          />
        </div>
        <PrimaryButton
          text="NEXT: Register Account Information"
          onClick={handleSave}
        />
      </div>
    );
}