import { Menu } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {Tooltip} from "react-tooltip";

export default function InfoButton({ children, id }) {
  return (
      <>
      <InformationCircleIcon id={id} className="w-4 text-gray-700 cursor-pointer ml-1" />
      <Tooltip anchorSelect={`#${id}`} place="top" openOnClick variant={"light"}
               className={"border border-neutral-300"}
               opacity={1}
               classNameArrow={"border-b"}>
          {children}
      </Tooltip>
      </>
    // <Menu as="div" className="relative" tabIndex={tabIndex}>
    //   <Menu.Button>
    //     <InformationCircleIcon className="w-4 text-gray-700 cursor-pointer ml-1" />
    //   </Menu.Button>
    //   <Menu.Items className="absolute p-2 left-0 z-10 w-48 origin-top bg-white divide-y divide-gray-100 rounded-md shadow-lg outline-none">
    //     <Menu.Item className="text-sm text-gray-700 py-1 px-4">
    //       {children}
    //     </Menu.Item>
    //   </Menu.Items>
    // </Menu>
  );
}
