import { CheckCircleIcon } from "@heroicons/react/24/solid";
import "react-loading-skeleton/dist/skeleton.css";
import {
  americanExpressDark,
  dinersClubDark,
  discoverDark,
  jcbDark,
  masterCardDark,
  visaDark,
} from "../../assets/images/payment_methods/dark";
import {
  americanExpressLight,
  dinersClubLight,
  discoverLight,
  jcbLight,
  masterCardLight,
  visaLight,
} from "../../assets/images/payment_methods/light";
import {useAlert, useApi, useTenant} from "../../hooks";
import {  useUserStore } from "../../context";
import {
  classNames,
} from "../../utils/helpers";
import { formatStripeExpiration, isCardExpired } from "../../utils/stripeHelpers";
import {useTenantTheme} from "../../context/useSystemTenantStore";

export default function PaymentMethod({
  paymentMethod,
  isDefault,
  forShowOnly,
  refreshData,
  changeToDefaultPaymentMethod,
  removePaymentMethod
}) {
  const alert = useAlert();
  const { post, del } = useApi();
  const { activeAccount } = useUserStore();
  const theme = useTenantTheme();
  const PRIMARY_COLOR = theme?.primaryHexColor;

  function getCardImage(brand, isDark) {
    switch (brand) {
      case "visa":
        return isDark ? visaDark : visaLight;
      case "amex":
        return isDark ? americanExpressDark : americanExpressLight;
      case "mastercard":
        return isDark ? masterCardDark : masterCardLight;
      case "discover":
        return isDark ? discoverDark : discoverLight;
      case "diners":
        return isDark ? dinersClubDark : dinersClubLight;
      case "jcb":
        return isDark ? jcbDark : jcbLight;
      default:
        return "";
    }
  }



  

  const cardIsExpired = isCardExpired(paymentMethod);

  return (
    <div
      style={isDefault ? {borderColor: PRIMARY_COLOR} : null}
      title={isDefault || forShowOnly ? "" : "Set as default payment method"}
      onClick={() => {
        if (!isDefault && !forShowOnly) {
          changeToDefaultPaymentMethod(paymentMethod.id);
        }
      }}
      className={classNames(
        "flex relative min-h-[10rem] w-full rounded-lg border",
        isDefault
          ? "bg-primaryColor-100 border-primaryColor"
          : forShowOnly
          ? "bg-gray-50"
          : "bg-white cursor-pointer"
      )}
    >
      {/* Checkbox */}
      {!forShowOnly && (
        <div
        style={isDefault ? {backgroundColor: PRIMARY_COLOR} : null}
          className={classNames(
            "absolute top-1 left-1 border rounded-full h-8 w-8 flex justify-center items-center",
            isDefault ? "bg-primaryColor" : "bg-gray-50"
          )}
        >
          {isDefault && <CheckCircleIcon className="w-6 h-6 text-white" />}
        </div>
      )}
      <div className="flex justify-center items-center w-1/2 p-1">
        <div className="">
          <img
            src={getCardImage(paymentMethod.card?.brand || "", true)}
            alt="card-logo"
            className=""
          />
        </div>
      </div>

      <div className="flex items-center w-1/2 py-0.5">
        <div className="">
          <div className="text-lg font-semibold">
            **** **** **** {paymentMethod.card?.last4}
          </div>
          <div
            className={classNames(
              "text-sm",
              cardIsExpired ? "text-red-500" : ""
            )}
          >
            {cardIsExpired ? "Expired" : "Expires"}{" "}
            {formatStripeExpiration(paymentMethod)}
          </div>
          {!forShowOnly && (
            <div
              title="Remove payment method"
              onClick={(e) => {
                e.stopPropagation();
                removePaymentMethod(paymentMethod.id);
              }}
              className="text-sm text-red-500 cursor-pointer"
            >
              Remove
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
