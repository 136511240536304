import React from "react";
import {clsx} from "clsx";
import {useTenant} from "../../hooks";

export default function CustomPrimaryButton({
  text,
  disabled,
  backgroundColor = "primaryColor",
  textColor = "white",
  ButtonSVG,
  onClick,
  overrideThemeColors = false
}) {
  const {  theme } = useTenant();

  const themePrimaryStyle = {backgroundColor: disabled || overrideThemeColors ? undefined : theme?.primaryHexColor};
  const themeSecondaryStyle = {color: theme?.secondaryHexColor};

  function resolveBackgroundColor(){
    if(theme) return `bg-[${theme?.primaryHexColor}]`;
    return `bg-color${backgroundColor}`
  }

  return (
    <button
      disabled={disabled}
      className={clsx("marker:inline-flex items-center min-w-24 h-[38px] mix-blend-color",
          "px-5 border border-transparent shadow-sm rounded-sm shadow-gray-300",
          "justify-center text-md font-medium focus:outline-none w-full",
          disabled 
          ? "bg-gray-300 text-gray-700 hover:bg-gray-300"
          : `bg-${backgroundColor} text-${textColor} hover:opacity-70`
      )}
      style={themePrimaryStyle}
      onClick={disabled ? () => {} : onClick}
    >
      {ButtonSVG ? (
        <span className="flex flex-row items-center space-x-1">
          <span className="-ml-0.5 m-1 h-4 w-4">
            <ButtonSVG />
          </span>

          <span className="whitespace-nowrap ">{text}</span>
        </span>
      ) : (
        <span className="whitespace-nowrap">{text}</span>
      )}
    </button>
  );
}
