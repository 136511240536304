import useSystemTenantStore, {
    useActiveTenant,
    useSystemTenantLists,
    useTenantActions, useTenantTheme,
} from "../context/useSystemTenantStore";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import { useApi, useSubdomain } from "./";
import useSWR from "swr";
import { isNil } from "lodash/lang";
import {useLoaderStore, useTenantLoader, useUserStore} from "../context";
import {isEmpty, isEqual} from "lodash";
import {minutesToMs, tryGetSubdomain, userHasRole} from "../utils/helpers";
import {useCookies} from "react-cookie";
import constants from "../utils/constants";
import cookieJar from "../utils/cookieJar";

const CHECK_SYSTEM_TENANT_REFRESH = 50000;

const PREVIEW_THEME = {
  primaryHexColor: null,
  secondaryHexColor: null,
  tertiaryHexColor: null,
};

const DEFAULT_THEME = {
    primaryHexColor: "#959595",
    secondaryHexColor: "#c8c8c8",
    tertiaryHexColor: "#f1e2e2",
    logo: constants.APP_LOGO
}

const tenantListUrl = "systemTenant/GetSystemTenantList";


export default function useTenant() {
  const { fetchAsync } = useApi();
  const {setShowLoader} = useLoaderStore();
  const onNotFoundRoute = window.location.pathname.includes("not-found");
  const { currentUser } = useUserStore();
  const subdomain = tryGetSubdomain();
  const { hasActiveTenant, setSystemTenants, setActiveSystemTenant } =
    useTenantActions();
  const activeSystemTenant = useActiveTenant();
  const systemTenants = useSystemTenantLists();
  const [cookie] = useCookies([cookieJar.ITEMS.theme, cookieJar.ITEMS.activeSystemTenantId]);
  const [isLoading, setIsLoading] = useState(false);
  const [tenantTheme, setTenantTheme] = useState(DEFAULT_THEME);
    const canGetTenantList = userHasRole(currentUser, constants.ROLE_IDS.ADMIN) && !subdomain;
  // const { data, error, mutate, isLoading: tenantLoading } = useSWR(canGetTenantList ? tenantListUrl : null,
  //     fetch, {
  //   revalidateOnFocus: false,
  //   revalidateOnMount: false,
  //   refreshInterval: systemTenants.length > 0 ? 0 : 500
  //
  // });
    const {tenantIsLoading, setTenantIsLoading} = useTenantLoader();



  //   useEffect(() => {
  //       if(tenantLoading && !activeSystemTenant) {
  //           setTenantIsLoading(true);
  //       }
  //   }, [tenantLoading]);
  //
  // useEffect(() => {
  //   if (!isNil(data) && !error) {
  //     setSystemTenants(data);
  //   }
  // }, [data, error]);

    // async function reFetchData() {
    //     if(canGetTenantList) {
    //        await mutate();
    //     }
    // }

  

  useEffect(() => {
    if (cookie.tenantTheme) {
        if(!isEqual(cookie.tenantTheme, activeSystemTenant)) {
            setActiveSystemTenant(cookie.tenantTheme);
        }
    }
  }, [cookie.tenantTheme]);


    async function getTenants() {
        const res = await fetchAsync("systemTenant/GetSystemTenantList");
        if(res && res.length) {
            setSystemTenants(res);
        }
    }



    useEffect(() => {
       if(!isThemeEqual(activeSystemTenant)) {
           setTenantTheme(convertTenantToTheme(activeSystemTenant));
           setTenantIsLoading(false);
       }
    }, [activeSystemTenant]);


    function convertTenantToTheme(tenant) {
        if(!tenant) return DEFAULT_THEME;
        return {
            primaryHexColor: tenant.primaryHexColor,
            secondaryHexColor: tenant.secondaryHexColor,
            tertiaryHexColor: tenant.tertiaryHexColor,
            logo: tenant.logo,
            header: tenant.header,
            subHeader: tenant.subHeader
        };
    }


    function isThemeEqual(incomingTenant) {
        const themeObj = convertTenantToTheme(incomingTenant);
        return isEqual(tenantTheme, themeObj);
    }

    // function revalidateData() {
    //     if(!isLoading) {
    //         mutate();
    //     }
    // }

  function updateTheme({
    primaryHexColor,
    secondaryHexColor,
    tertiaryHexColor,
    header,
    subHeader
  }) {
  // console.log('attempting to update system tenant theme');
    setActiveSystemTenant({
      ...activeSystemTenant,
      primaryHexColor: primaryHexColor || activeSystemTenant.primaryHexColor,
      secondaryHexColor:
        secondaryHexColor || activeSystemTenant.secondaryHexColor,
      tertiaryHexColor: tertiaryHexColor || activeSystemTenant.tertiaryHexColor,
      header: header || activeSystemTenant.header,
      subHeader: subHeader || activeSystemTenant.subHeader
    });
  }


    useEffect(() => {
        setShowLoader(isLoading);
    }, [isLoading]);


  return {
    activeTenant: activeSystemTenant,
    subdomain,
    tenantList: systemTenants,
    theme: tenantTheme,
    updateTheme,
    isLoading,
    // error,
    loadTenantList: getTenants,
    activeSystemTenantId: cookie?.activeSystemTenantId,
  };
}
