import constants from "../utils/constants";
import Cookies from "universal-cookie";

let inMemoryStorage = {};


const cookies = new Cookies(null, { path: '/' });

const isSupported = () => {
  try {
    localStorage.setItem("test", "test");
    localStorage.removeItem("test");
    return true;
  } catch (e) {
    return false;
  }
};

const clear = () => {
  if (isSupported()) {
    document.cookie.replace(
        /(?<=^|;).+?(?=\=|;|$)/g,
        name => window.location.hostname
            .split(/\.(?=[^\.]+\.)/)
            .reduceRight((acc, val, i, arr) => i ? arr[i]='.'+val+acc : (arr[i]='', arr), '')
            .map(domain => document.cookie=`${name}=;max-age=0;path=/;domain=${domain}`)
    );
  } else {
    inMemoryStorage = {};
  }
};

const getItem = (key) => {
  if (isSupported()) {
    const item = cookies.get(key);
    return item;
  }
  else if (inMemoryStorage.hasOwnProperty(key)) return inMemoryStorage[key];
  else return null;
};

const removeItem = (key) => {
  if (isSupported()) {
    cookies.remove(key, { path: '/' });
  }
  else delete inMemoryStorage[key];
};

const setItem = (key, value) => {
  if (isSupported())  {
    cookies.set(key, value);
  }
  else inMemoryStorage[key] = value;
}

const ITEMS = {
  currentUser: "currentUser",
  jwt: `${constants.APP_NAME}-jwt`,
  storageCurrentUser: "storageCurrentUser",
  storageJWT: `storage-${constants.APP_NAME}-jwt`,
  username: `${constants.APP_NAME}-username`,
  theme: 'tenantTheme',
  activeSystemTenantId: 'activeSystemTenantId',
}

const cookieJar = {
  clear,
  getItem,
  removeItem,
  setItem,
  ITEMS,
}

export default cookieJar;