import React, { useState } from "react";
import { useAlert, useApi, useTenant } from "../../hooks";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { ModalWrapper } from "../Modals";
import { CardInput, CheckboxInput } from "../Inputs";
import { PrimaryButton } from "../Buttons";
import Cards from "react-credit-cards-2";
import { getCardExpiration, padCardInfo } from "../../utils/helpers";
import {useRegistrationStore} from "../../context";
import {useTenantTheme} from "../../context/useSystemTenantStore";

function CardForm({ getPaymentMethodCallback, savePaymentMethod, paymentInfo }) {
  const alert = useAlert();
  const { post } = useApi();
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTenantTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(true);
  const [paymentMethodType, setPaymentMethodType] = useState("card");
  const [paymentMethod, setPaymentMethod] = useState(paymentInfo ?? null);
  const PRIMARY_COLOR = theme?.primaryHexColor
    ? theme.primaryHexColor
    : `primaryColor`;
  const handleCardSubmit = async () => {
    if(paymentMethod) {
      savePaymentMethod(paymentMethod);
      return;
    }
    setIsLoading(true);
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);

    if (!cardNumberElement || !cardCvcElement || !cardExpiryElement) return;

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });

    if (result.error) {
      alert(
        "Error",
        result.error.message ||
          "An error occurred while attaching the payment method",
        "ERROR",
      );
      setIsLoading(false);
      return;
    }
    
    if (getPaymentMethodCallback) {
      getPaymentMethodCallback({ ...result.paymentMethod });
      setIsLoading(false);
      return;
    }
    setPaymentMethod(result.paymentMethod);
    savePaymentMethod(result.paymentMethod, defaultPaymentMethod);
    setIsLoading(false);
  };


  return (
    <div>
      {paymentMethod  && paymentMethod.card ? (
      <div className="mt-2">
        <Cards
          number={padCardInfo(paymentMethod?.card.last4 ?? "4242")}
          preview
          cvc={paymentMethod?.card.cvc ?? "000"}
          expiry={getCardExpiration(paymentMethod?.card ?? {exp_year: 2028, exp_month: 12})}
          name={""}
          issuer={paymentMethod?.card.brand ?? "visa"}
        />
      </div>) : null}

      <div className="mt-4">
        <div className="grid gap-y-4">
          <CardInput label="Card number">
            <CardNumberElement options={{ showIcon: true }} />
          </CardInput>
          <div className="grid grid-cols-4 gap-x-4">
            <div className="col-span-4 md:col-span-2">
              <CardInput label="Expiration date">
                <CardExpiryElement />
              </CardInput>
            </div>
            <div className="col-span-4 md:col-span-2 mt-2 md:mt-auto">
              <CardInput label="Security code">
                <CardCvcElement />
              </CardInput>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <PrimaryButton
          text="Save Payment Method"
          onClick={handleCardSubmit}
          disabled={isLoading}
        />
      </div>
    </div>
  );
}

export default CardForm;
