import { default as _axios } from "axios";
import {redirect, useNavigate} from "react-router-dom";
import { useUserStore } from "../context";
import storage from "../utils/storage";
import {useCookies} from "react-cookie";
import {getDomain, tryGetSubdomain} from "../utils/helpers";
import {useUpdateSubStore} from "../context/useSubdomainStore";

const API_BASE_URL = "/api";

const commonHeaders = {
  Accept: "application/json",
  'Access-Control-Allow-Origin': window.location,
};

const headers = { ...commonHeaders, "Content-Type": "application/json" };

const multipart_headers = {
  ...commonHeaders,
  "Content-Type": "multipart/form-data",
};

const queryString = (params) => {
  const query = Object.keys(params)
    .map(
      (k) =>
        `${encodeURIComponent(k)}=${encodeURIComponent(
          params[k] !== undefined ? params[k] : ""
        )}`
    )
    .join("&");

  return `${query.length ? "?" : ""}${query}`;
};

function useApi() {
  const navigate = useNavigate();
  const updateSubStore = useUpdateSubStore();
  const { setCurrentUser } = useUserStore();
  const [cookies, setCookie, removeCookie] = useCookies();


  const axios = _axios.create({
    baseURL: API_BASE_URL,
    timeout: 600_000,

  });

  axios.interceptors.request.use((request) => {
    console.log("token in cookies", cookies.token)
    console.log("all cookies", cookies)
    request.headers.Authorization = cookies.token
      ? `Bearer ${cookies.token}`
      : "";
    return request;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        setCurrentUser(null);
        storage.removeItem(storage.ITEMS.currentUser);
        storage.removeItem(storage.ITEMS.jwt);
        storage.removeItem(storage.ITEMS.storageCurrentUser);
        storage.removeItem(storage.ITEMS.storageJWT);
        navigate("/");
      }
      return new Promise((_, reject) => {
        // console.log(error.response);
        reject(error.response);
      });
    }
  );

  const getUserToken = () => storage.getItem(storage.ITEMS.jwt);

  const fetch = (url, params = {}) => {
    const onNotFoundRoute = window.location.pathname.includes("not-found");
    if(onNotFoundRoute) return Promise.resolve();
    return axios
      .get(`${url}${queryString(params)}`, {
        headers: headers,
      })
      .then((res) => res.data);
  };

  const fetchAsync = async (url, params = {}) => {
    const onNotFoundRoute = window.location.pathname.includes("not-found");
    if(onNotFoundRoute) return Promise.resolve();
    const res = await axios
        .get(`${url}${queryString(params)}`, {
          headers: headers,
        })
    return res.data;
  };

  const post = (url, data) => {
    const onNotFoundRoute = window.location.pathname.includes("not-found");
    if(onNotFoundRoute) return Promise.resolve();
    return axios({
      method: "post",
      url: `${url}`,
      data,
      timeout: 600_000,
      headers: headers,
    }).then((res) => res.data);
  };

  const postAsync = async (url, data) => {
    const onNotFoundRoute = window.location.pathname.includes("not-found");
    if(onNotFoundRoute) return Promise.resolve();
    const res = await axios({
      method: "post",
      url: `${url}`,
      data,
      timeout: 600_000,
      headers: headers,
    });
    return res.data;
  };

  const put = (url, data) => {
    const onNotFoundRoute = window.location.pathname.includes("not-found");
    if(onNotFoundRoute) return Promise.resolve();
    return axios.put(`${url}`, data, { headers: headers }).then((res) => res.data);
  };

  const patch = (url, data) => {
    const onNotFoundRoute = window.location.pathname.includes("not-found");
    if(onNotFoundRoute) return Promise.resolve();
    return axios.patch(`${url}`, data, { headers: headers }).then((res) => res.data);
  }
  const del = (url) => {
    return axios.delete(`${url}`, { headers: headers }).then((res) => res.data);
  }

  const postFormData = (url, formData) => {
    const onNotFoundRoute = window.location.pathname.includes("not-found");
    if(onNotFoundRoute) return Promise.resolve();
    return axios
      .post(`${url}`, formData, {
        headers: multipart_headers,
      })
      .then((res) => res.data);
  }

  const postFormDataAsync = async (url, formData) => {
    const onNotFoundRoute = window.location.pathname.includes("not-found");
    if(onNotFoundRoute) return Promise.resolve();
    const res = await axios
        .post(`${url}`, formData, {
          headers: multipart_headers,
        });
    return res.data;
  }

  return {
    getUserToken,
    post,
    postAsync,
    fetch,
    fetchAsync,
    put,
    patch,
    del,
    postFormData,
    postFormDataAsync,
  };
}

export default useApi;
