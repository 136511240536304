import { CloudArrowUpIcon, XMarkIcon } from "@heroicons/react/24/solid";
import _ from "lodash";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {useAlert, useTenant} from "../../hooks";
import {SingleImageUploader} from "./index";
import {useTenantTheme} from "../../context/useSystemTenantStore";

export default function FileUpload({
  files,
  setFiles,
  acceptedImageFiles = [".jpeg", ".jpg", ".png", ".gif", ".bmp"],
  acceptedAudioFiles = [".mp3", ".wav", ".ogg", ".flac"],
  acceptedVideoFiles = [".mp4", ".avi", ".mov", ".mkv"],
  allowMultipleFiles = true,
  disabled = false,
  maxFileCount,
}) {
  const alert = useAlert();
  const theme = useTenantTheme();
  const PRIMARY_COLOR = theme?.primaryHexColor;
  const SECONDARY_COLOR = theme?.secondaryHexColor;

  const onDrop = useCallback(
    (acceptedFiles) => {
      let duplicateCount = _.chain([...files, ...acceptedFiles])
        .groupBy((f) => f.name)
        .map((list) => list)
        .filter((l) => l.length > 1)
        .value()?.length;

      if (duplicateCount >= 1) {
        alert(
          "Duplicate file name",
          "A file with the same name already exists",
          "warning"
        );
      } else {
        let uploadList = _.chain([...files, ...acceptedFiles])
          .uniqBy((f) => f.name)
          .map((f) => {
            // allows for a mini preview of the file upload
            f = Object.assign(f, {
              preview: URL.createObjectURL(f),
            });
            return f;
          })
          .value();
        if (maxFileCount && uploadList.length > maxFileCount) {
          alert(
            "Too many files",
            `Only ${maxFileCount} files are allowed to be uploaded`,
            "warning"
          );
          return;
        }
        setFiles(uploadList);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [alert, files, maxFileCount]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": acceptedImageFiles,
      "audio/*": acceptedAudioFiles,
      "video/*": acceptedVideoFiles,
      "application/pdf": [".pdf"],
      "text/*": [".txt"],
    },
    multiple: allowMultipleFiles,
    disabled,
  });

  return (
    <div className="p-8 bg-white rounded-md shadow-md cursor-pointer">
      <div
        {...getRootProps({
          className:
            "flex justify-center items-center rounded-md min-h-[10rem] bg-gray-50 border border-gray-300 border-dashed shadow-sm",
        })}
      >
        <div className="flex flex-col items-center">
          <input {...getInputProps()} />
          <p className="text-primaryColor text-lg font-bold" style={{color: PRIMARY_COLOR}}>
            Select file{allowMultipleFiles && "(s)"}
          </p>
          <p className="text-primaryColor text-lg font-bold" style={{color: PRIMARY_COLOR}}>
            or drop file{allowMultipleFiles && "(s)"} here
          </p>
          <CloudArrowUpIcon className="mt-4 w-16 text-primaryColor" style={{color: PRIMARY_COLOR}}/>
        </div>
      </div>

      <FilePreview files={files} setFiles={setFiles} themePrimaryColor={PRIMARY_COLOR} themeSecondaryColor={SECONDARY_COLOR}/>
    </div>
  );
}

const FilePreview = ({ files, setFiles, themePrimaryColor, themeSecondaryColor }) => {
  const handleFileDelete = (file) => {
    const filtered = files.filter((f) => f.path !== file.path);
    setFiles(filtered);
  };

  return (
    <div>
      <h6 className="text-lg text-primaryColor my-4 border-b border-gray-200" style={{color: themePrimaryColor, borderBottomColor: themeSecondaryColor}}>
        Preview
      </h6>
      {files && files.length > 0 && (
        <>
          <div>
            <ul className="flex space-x-8">
              {files.map((file, i) => (
                <li key={file.path} className="flex flex-col">
                  {file.preview && (
                    <div
                        style={{backgroundColor: themePrimaryColor}}
                      className="w-[100px] h-[100px] p-1 shadow-lg rounded-md inline-flex box-border relative"
                      key={file.name}
                    >
                      <div className="flex min-w-0 overflow-hidden justify-center">

                        <img
                          src={file.preview}
                          alt={`file-preview-${i}`}
                          className="w-auto h-full block"
                          onLoad={() => {
                            URL.revokeObjectURL(file.preview);
                          }}
                        />
                      </div>
                      <XMarkIcon
                        className="w-6 text-white bg-delete hover:bg-delete-100 rounded-full absolute -bottom-1 -right-1"
                        onClick={() => handleFileDelete(file)}
                      />
                    </div>
                  )}
                  <div className="text-sm text-gray-400">{file.name}</div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};
