import { useState } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../Buttons";
import {
  PasswordInput,
  SelectInput,
  TelephoneInput,
  TextInput,
  NumberInput,
} from "../Inputs";
import {useLoaderStore, useRegistrationStore} from "../../context";
import { useAlert, useApi } from "../../hooks";
import constants from "../../utils/constants";
import { isValidEmail, isValidPassword } from "../../utils/helpers";
import { formatPhoneNumber } from "react-phone-number-input";
import {Tooltip, Tooltip as ReactTooltip} from 'react-tooltip'
import HSTooltip from "@preline/tooltip";
import {InfoTooltip} from "../Displays";
import {InfoSlideOver} from "../Menus";

const FOUR_DAYS = 5760;
const SEVEN_DAYS = 10080;

export default function SystemTenantRegistrationForm({ handleSubmitCallback }) {
  const { setRegistrationSysTenant, registrationUser, registrationAccount, clearAll } =
    useRegistrationStore();
  const navigate = useNavigate();
  const alert = useAlert();
  const {post} = useApi();
  const {setShowLoader} = useLoaderStore();
  const [name, setName] = useState(
    registrationAccount?.name ? `${registrationAccount?.name}` : "",
  );
  const [adminEmail, setAdminEmail] = useState(
    registrationAccount?.email || "",
  );
  const [adminMobilePhone, setAdminMobilePhone] = useState(
    registrationAccount?.mobilePhone || "",
  );
  const [delinquentGracePeriodMinutes, setDelinquentGracePeriodMinutes] =
    useState(FOUR_DAYS);
  const [warnRenewalMinutesThreshold, setWarnRenewalMinutesThreshold] =
    useState(SEVEN_DAYS);
  const [
    warnCancellationMinutesThreshold,
    setWarnCancellationMinutesThreshold,
  ] = useState(SEVEN_DAYS);
  const [processRenewalMinutesThreshold, setProcessRenewalMinutesThreshold] =
    useState(FOUR_DAYS);
  const [getTenantUsersUri, setGetTenantUsersUri] = useState("");
  const [clientCommunicationSignature, setClientCommunicationSignature] =
    useState("");

  const [infoBarOpen, setInfoBarOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('US');


  function isValid() {
    if (!name || !name.trim()) {
      alert("Invalid input", "Please enter a valid name", "warning");
      return false;
    }
    if (!adminEmail || !adminEmail.trim() || !isValidEmail(adminEmail)) {
      alert("Invalid input", "Please enter a valid email", "warning");
      return false;
    }
    if (!adminMobilePhone || !adminMobilePhone.trim()) {
      alert("Invalid input", "Please enter a valid phone number", "warning");
      return false;
    }
    if (!isPossiblePhoneNumber(adminMobilePhone.trim(), selectedCountry)) {
      alert("Invalid input", "Please enter a valid phone number", "warning");
      return false;
    }
    if (
      processRenewalMinutesThreshold < 0 ||
      processRenewalMinutesThreshold > 43200
    ) {
      alert(
        "Invalid input",
        "Renewal hours must be between 0 and 43200 (1 month)",
        "warning",
      );
      return false;
    }
    return true;
  }

  function handleSubmit() {
    if (!isValid()) return;
    let sysTenantInfo = {
      name: name,
      adminEmail: adminEmail,
      adminMobilePhone: adminMobilePhone,
      delinquentGracePeriodMinutes: delinquentGracePeriodMinutes,
      warnRenewalMinutesThreshold: warnRenewalMinutesThreshold,
      warnCancellationMinutesThreshold: warnCancellationMinutesThreshold,
      processRenewalMinutesThreshold: processRenewalMinutesThreshold,
      getTenantUsersUri: "MargotOkapiPlus Member",
      tenantUserCommunicationSentUri: null,
      clientCommunicationSignature: clientCommunicationSignature,
    };
    setRegistrationSysTenant(sysTenantInfo);
    // handleSubmitCallback(sysTenantInfo);
    createAccount(sysTenantInfo);
  }

  const warnRenewalTooltip = new HSTooltip(document.querySelector('#warn-renewal-tooltip'));


  async function createAccount(sysTenant) {
    if (!registrationUser || !sysTenant) return;

    setShowLoader(true);
    let strippedAccount = {...registrationAccount};
    strippedAccount.paymentMethodId = registrationAccount.paymentMethod.id;
    delete strippedAccount.paymentMethod;
    await post("public/AdminRegister", {
      user: registrationUser,
      account: strippedAccount,
      systemTenant: sysTenant,
    })
        .then((res) => {
          alert(
              "Successfully Registered",
              "Check your inbox to confirm your email",
              "success",
              false,
          );
          clearAll();
          navigate("/");
        })
        .catch((err) => {
          alert("Unsuccessful", "Unable to register your account", "error");
          console.error(err);
        })
        .finally(() => {
          setShowLoader(false);
        });
  }

  return (
    <div className="xs:w-10/12 md:w-3/4 space-y-6">
      <InfoSlideOver title={'test'} open={infoBarOpen}
                     onClose={() => setInfoBarOpen(false)} >
        <div>Hello</div>

      </InfoSlideOver>
      <div className="text-primaryColor text-lg font-semibold flex flex-row justify-start border-b">
        Tenant Information
      </div>
      <div className="overflow-y-auto flex flex-col">
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-4 gap-y-4">
          <div>
            <TextInput
              label="Name"
              name="name"
              value={name}
              maxLength={200}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div>
            <div className="peer/email">
              <TextInput
                label="Admin Email"
                id={"email-tool-tip"}
                name="adminEmail"
                value={adminEmail}
                maxLength={150}
                onChange={(e) => setAdminEmail(e.target.value)}
                required
                // onFocus={() => {
                //   setInfoBarOpen(true);
                // }}
                // onBlur={(e) => {
                //   setInfoBarOpen(false);
                // }}
              />
              <InfoTooltip id={"#email-tool-tip"}>
                {constants.Explanation.emailNotification}
              </InfoTooltip>
            </div>
            {/*<div className="hidden peer-focus-within/email:block text-red-700 text-sm">*/}
            {/*  <p>*/}
            {/*  */}
            {/*    /!*{`This is an email address used only when a notification or other processing failure occurs for this account. Email will be used preferentially if it is provided.`}*!/*/}
            {/*  </p>*/}
            {/*</div>*/}
          </div>
        </div>

        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-4 gap-y-4 mt-4">
          <div className="peer/phone">
            <TelephoneInput
              label="Admin Mobile Phone"
              name="adminMobilePhone"
              id={"mobile-phone-tooltip"}
              inputId={"mobile-phone-tooltip"}
              value={adminMobilePhone}
              onChange={(e) => setAdminMobilePhone(e.target.value)}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              required
            />
            <InfoTooltip id={"#mobile-phone-tooltip"}>
              {constants.Explanation.mobileNotification}
            </InfoTooltip>
          </div>
          <div>
            <TextInput
              label="Email Signature"
              name="clientCommunicationSignature"
              value={clientCommunicationSignature}
              maxLength={300}
              onChange={(e) => setClientCommunicationSignature(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="inline-block w-full">
        <NumberInput
          label="Delinquent Subscription Grace Period (Minutes)"
          name="delinquentGracePeriodMinutes"
          id={"grace-period-tooltip"}
          value={delinquentGracePeriodMinutes}
          onChange={(e) => {
            setDelinquentGracePeriodMinutes(e.target.valueAsNumber);
          }}
          min={0}
        />
        <InfoTooltip id={"#grace-period-tooltip"}>
          {constants.Explanation.grace}
        </InfoTooltip>
      </div>
      {/*<div className="peer/warnRenewal">*/}
      <div id="warn-renewal-tooltip" className="inline-block w-full">
        <NumberInput
          label="Renewal Warning Threshold (Minutes)"
          name="warningRenewalHours"
          id={"renewal-warning-tooltip"}
          onFocus={({ target }) => {
            // window.HSTooltip.show(target);
          }}
          // onFocusGained={(e) => console.log('focus gained: ', e)}
          value={warnRenewalMinutesThreshold}
          onChange={(e) => {
            setWarnRenewalMinutesThreshold(e.target.valueAsNumber);
          }}
          min={0}
        />
        <InfoTooltip id={"#renewal-warning-tooltip"} place={"top"} width={60}>
          {constants.Explanation.warnRenewal}
        </InfoTooltip>
        {/*<span*/}
        {/*  className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-white"*/}
        {/*  role="tooltip"*/}
        {/*>*/}
        {/*  <p>{constants.Explanation.warnRenewal}</p>*/}
        {/*</span>*/}
      </div>

      {/*<div className="hidden peer-focus-within/warnRenewal:block text-red-700">*/}
      {/*  <ReactTooltip anchorSelect="hidden peer-focus-within/warnRenewal" placement="right">*/}
      {/*    <p>*/}
      {/*      {constants.Explanation.warnRenewal}*/}
      {/*    </p>*/}
      {/*  </ReactTooltip>*/}

      {/*          {`This defines the window of time prior to primary subscription expiration during which a pending renewal notification will be sent to someone intending to renew.*/}
      {/*Say Jason's subscription expires on Friday morning at 8AM and he has indicated that he'd like to renew.*/}
      {/*We may send a renewal notification to him according to his defined preferences beginning at Monday at 8AM if this setting is 7200 (aka 5 days).*/}
      {/*Note that this warning of renewal notification will only ever be sent once, at most, per subscription renewal.*/}
      {/*It is also necessary to choose a setting here that is more than the Process Renewal Minutes Threshold value because we no longer attempt to*/}
      {/*warn of pending renewal once the renewal processing window threshold has passed.*/}
      {/*For instance, if the Process Renewal Minutes Threshold is 3 days and the Warning of Renewal Minutes Threshold is 4 days only the 1 day of additional*/}
      {/*anticipation time will be considered usable for sending the warning notification. (Default set to 7 days)`}*/}
      {/*      </div>*/}
      <div className="peer/warnCancel">
        <NumberInput
          label="Cancellation Warning Threshold (Minutes)"
          name="warningCancellationHours"
          id={"cancel-warning-tooltip"}
          value={warnCancellationMinutesThreshold}
          onChange={(e) => {
            setWarnCancellationMinutesThreshold(e.target.valueAsNumber);
          }}
          min={0}
        />
      </div>
      <InfoTooltip id={"#cancel-warning-tooltip"} place={"top"}>
        {constants.Explanation.warnCancel}
      </InfoTooltip>
      {/*<div className="hidden peer-focus-within/warnCancel:block text-red-700">*/}
      {/*  <p>*/}
      {/*    {constants.Explanation.warnCancel}*/}
      {/*    /!*          {`This defines the window of time prior to primary subscription expiration during which a pending expiration/cancellation notification will be sent to someone NOT intending to renew.*!/*/}
      {/*    /!*Say Jason's subscription expires on Friday morning at 8AM and he has indicated that he will not be renewing to any subscription product.*!/*/}
      {/*    /!*We may send a cancellation notification to him according to his defined preferences beginning at Monday at 8AM if this setting is 7200 (aka 5 days). (Default set to 7 days)`}*!/*/}
      {/*  </p>*/}
      {/*</div>*/}
      <div className="peer/renew">
        <NumberInput
          label="Process Renewal Threshold (Minutes)"
          name="processRenewalMinutesThreshold"
          id={"process-renew-tooltip"}
          value={processRenewalMinutesThreshold}
          onChange={(e) => {
            setProcessRenewalMinutesThreshold(e.target.valueAsNumber);
          }}
          min={0}
          required
        />
      </div>
      <InfoTooltip id={"#process-renew-tooltip"} place={"top"} width={50}>
        {constants.Explanation.renew}
      </InfoTooltip>
      {/*<div className="hidden peer-focus-within/renew:block text-red-700">*/}
      {/*  <p>*/}
      {/*    {constants.Explanation.renew}*/}
      {/*    /!*          {`This defines the window of time prior to primary subscription expiration during which automatic renewal payments will be attempted.*!/*/}
      {/*    /!*Say Jason's subscription expires on Friday morning at 8AM and he has indicated that he'd like to renew.*!/*/}
      {/*    /!*We will begin attempting to process his payment on file per his intent this number of minutes prior to that expiration.*!/*/}
      {/*    /!*If this setting is 1440 (aka 1 day) then the attempt to purchase the renewal subscription will occur a bit after 8AM on Thursday.*!/*/}
      {/*    /!*It is recommended that at least 2 or 3 days of time be set here so that any payment issues can be corrected prior to license expiration. (Default set to 4 days)`}*!/*/}
      {/*  </p>*/}
      {/*</div>*/}

      <PrimaryButton text="SUBMIT" onClick={handleSubmit} />
    </div>
  );
}
