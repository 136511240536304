import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTenant } from "../../hooks";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip
);

function LineChart({data, dataPointLabel}) {
  const { theme } = useTenant();
  return (
    <Line
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
      data={{
        datasets: [
          {
            fill: true,
            borderColor: "#3b82f6",
            backgroundColor: "rgba(59, 130, 246, 0.1)",
            label: dataPointLabel,
            data: data || []
            // data: [
            //   { x: "Feb 7", y: 78.02 },
            //   { x: "Feb 8", y: 81.74 },
            //   { x: "Feb 9", y: 73.99 },
            //   { x: "Feb 10", y: 80.6 },
            //   { x: "Feb 11", y: 79.48 },
            //   { x: "Feb 12", y: 74.71 },
            //   { x: "Feb 13", y: 77.1 },
            // ],
          },
        ],
      }}
    />
  );
}

export default LineChart;
