import {
  UserGroupIcon,
  QuestionMarkCircleIcon,
  SwatchIcon,
  CreditCardIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router";
import { InfoCard } from "../components/Displays";
import { useSystemTenantStore, useThemeConfig, useUserStore } from "../context";
import useSWR from "swr";
import { useAlert, useApi, useTenant } from "../hooks";
import {
  useActiveTenant,
  useTenantActions, useTenantTheme,
} from "../context/useSystemTenantStore";
import { useEffect, useState } from "react";
import { isNil } from "lodash/lang";
import { minutesToMs } from "../utils/helpers";
import {clsx} from "clsx";
import {DashboardStats, RecentInvoices} from "./SystemTenantDashboard";

export default function Dashboard() {
  const { currentUser } = useUserStore();
  const activeTenant = useActiveTenant();
  const theme = useTenantTheme();
  const { fetch } = useApi();
  const navigate = useNavigate();
  const alert = useAlert();

  const [dashboardUrl, setDashboardUrl] = useState(null);


  useEffect(()=>{
  },[activeTenant])

  function getActiveProducts() {
    fetch(`systemTenant/OwnedProductList/${activeTenant?.id}`, {
      pageNumber: 1,
      pageSize: 25,
      sortDirection: "descending",
      sortField: "EffectiveAt",
      isExact: false,
      activeOnly: true,
      systemTenantId: activeTenant?.id,
    }).then((res) => {
    });
  }

  const backgroundGradient = `bg-gradient-to-br from-[${theme?.primaryHexColor}] to-[${theme?.secondaryHexColor}]`;
  // function getDashboardInfo(){
  //   fetch("systemTenant/SystemTenantDashboardInfo")
  //   .then(res => {
  //     console.log("dahboard response", res)
  //   })
  //   .catch(err => {
  //     alert("Server error", "Failed to get dashboard info", "error");
  //   });
  // }

  return (
    <div className="">
      {/* Header */}
      <div
        style={{ background: `linear-gradient(to bottom right, ${theme?.primaryHexColor} 50%, ${theme?.secondaryHexColor} 100%)` }}
        className={clsx(
          "h-[16vh] p-4 text-lg",
        )}
      >
        <h3 className="text-white font-bold">
          Welcome, {currentUser.firstName}
        </h3>

        {/* Put relevant info to client in this section */}
      </div>
      <div className="px-4">
        <DashboardStats/>
      </div>
      <div className={"mt-20"}>
        <RecentInvoices/>
      </div>
      
      {/* Cards */}
    </div>
  );
}
