import React, { useCallback, useRef, useState } from "react";
import { CloudArrowUpIcon, XMarkIcon } from "@heroicons/react/24/solid";
import Dropzone, { useDropzone } from "react-dropzone";
import { useAlert, useTenant } from "../../hooks";
import { chain } from "lodash";
import { clsx } from "clsx";
import {useTenantTheme} from "../../context/useSystemTenantStore";
import {AddPhotoIcon} from "../Icons";

function SingleImageUploader({
  files,
  setFiles,
  acceptedImageFiles = [".jpeg", ".jpg", ".png", ".gif", ".bmp"],
  acceptedAudioFiles = [".mp3", ".wav", ".ogg", ".flac"],
  acceptedVideoFiles = [".mp4", ".avi", ".mov", ".mkv"],
  allowMultipleFiles = false,
  disabled = false,
  maxFileCount,
  imageUrlKey,
  imageClassName,
}) {
  const alert = useAlert();
    const theme = useTenantTheme();
  const PRIMARY_COLOR = theme?.primaryHexColor;
  const SECONDARY_COLOR = theme?.secondaryHexColor;

  const onDrop = useCallback(
    (acceptedFiles) => {
      let duplicateCount = chain([...files, ...acceptedFiles])
        .groupBy((f) => f.name)
        .map((list) => list)
        .filter((l) => l.length > 1)
        .value()?.length;

      if (duplicateCount >= 1) {
        alert(
          "Duplicate file name",
          "A file with the same name already exists",
          "warning",
        );
      } else {
        let uploadList = chain([...files, ...acceptedFiles])
          .uniqBy((f) => f.name)
          .map((f) => {
            // allows for a mini preview of the file upload
            f = Object.assign(f, {
              preview: URL.createObjectURL(f),
            });
            return f;
          })
          .value();
        if (maxFileCount && uploadList.length > maxFileCount) {
          alert(
            "Too many files",
            `Only ${maxFileCount} files are allowed to be uploaded`,
            "warning",
          );
          return;
        }
        setFiles(uploadList);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [alert, files, maxFileCount],
  );

  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    // accept: {
    //     "image/*": acceptedImageFiles,
    // },
    multiple: false,
    useFsAccessApi: false,
  });

  function onReplace() {
    open();
  }

  return (

          <div id={'single-image-uploader'}  {...getRootProps({className: "relative group/image w-2/8 flex items-center justify-center m-4 rounded"})}>
              {imageUrlKey || files?.length
                  ? (
                      <img
              src={files[0]?.preview || imageUrlKey}
              alt=""
              className={clsx("w-fit h-full object-contain rounded-lg")}
            />
                  ) : (
                <AddPhotoIcon  style={{width: '50%', height: '50%'}}/>
              )}
            <input {...getInputProps()} />
            <div
              className={clsx(
                "absolute w-full h-full group-hover/image:z-20 top-0 none",
                "group-hover/image:bg-[#00000066] group-hover/image:inline-block px-20",
                "group-hover/image:flex group-hover/image:justify-center group-hover/image:items-center",
              )}
            >
              <button
                type="button"
                onClick={onReplace}
                className={clsx(
                  "relative none w-0 text-transparent group-hover/image:w-full group-hover/image:text-white ",
                  "group-hover/image:border group-hover/image:opacity-100 hover:bg-black group-hover/image:z-40",
                  " group-hover/image:rounded",
                )}
              >
                  {imageUrlKey ? "Replace" : "Upload"}
              </button>
            </div>
          </div>

        );

    //     {imageUrlKey ? (
    //         <input src={imageUrlKey} {...getInputProps()}/>
    //             // <img src={imageUrlKey} alt="" className={imageClassName}/>
    //     ) : (
    //   <div
    //     {...getRootProps({
    //       className:
    //         "flex justify-center items-center rounded-md min-h-[10rem] bg-gray-50 border border-gray-300 border-dashed shadow-sm",
    //     })}
    //   >
    //     <div className="flex">
    //
    //         <input {...getInputProps()} />
    //
    //       {/*<p*/}
    //       {/*  className="text-primaryColor text-lg font-bold"*/}
    //       {/*  style={{ color: PRIMARY_COLOR }}*/}
    //       {/*>*/}
    //       {/*  Select file{allowMultipleFiles && "(s)"}*/}
    //       {/*</p>*/}
    //       {/*<p*/}
    //       {/*  className="text-primaryColor text-lg font-bold"*/}
    //       {/*  style={{ color: PRIMARY_COLOR }}*/}
    //       {/*>*/}
    //       {/*  or drop file{allowMultipleFiles && "(s)"} here*/}
    //       {/*</p>*/}
    //       {/*<CloudArrowUpIcon*/}
    //       {/*  className="mt-4 w-16 text-primaryColor"*/}
    //       {/*  style={{ color: PRIMARY_COLOR }}*/}
    //       {/*/>*/}
    //     </div>
    //   </div>)}
    //
    //   {/*<FilePreview*/}
    //   {/*  files={files}*/}
    //   {/*  setFiles={setFiles}*/}
    //   {/*  themePrimaryColor={PRIMARY_COLOR}*/}
    //   {/*  themeSecondaryColor={SECONDARY_COLOR}*/}
    //   {/*/>*/}
    // </div>
}

const FilePreview = ({
  files,
  setFiles,
  themePrimaryColor,
  themeSecondaryColor,
}) => {
  const handleFileDelete = (file) => {
    const filtered = files.filter((f) => f.path !== file.path);
    setFiles(filtered);
  };

  return (
    <div>
      <h6
        className="text-lg text-primaryColor my-4 border-b border-gray-200"
        style={{
          color: themePrimaryColor,
          borderBottomColor: themeSecondaryColor,
        }}
      >
        Preview
      </h6>
      {files && files.length > 0 && (
        <>
          <div>
            <ul className="flex space-x-8">
              {files.map((file, i) => (
                <li key={file.path} className="flex flex-col">
                  {file.preview && (
                    <div
                      style={{ backgroundColor: themePrimaryColor }}
                      className="w-[100px] h-[100px] p-1 shadow-lg rounded-md inline-flex box-border relative"
                      key={file.name}
                    >
                      <div className="flex min-w-0 overflow-hidden justify-center">
                        <img
                          src={file.preview}
                          alt={`file-preview-${i}`}
                          className="w-auto h-full block"
                          onLoad={() => {
                            URL.revokeObjectURL(file.preview);
                          }}
                        />
                      </div>
                      <XMarkIcon
                        className="w-6 text-white bg-delete hover:bg-delete-100 rounded-full absolute -bottom-1 -right-1"
                        onClick={() => handleFileDelete(file)}
                      />
                    </div>
                  )}
                  <div className="text-sm text-gray-400">{file.name}</div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default SingleImageUploader;
