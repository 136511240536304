import React, {useMemo, useState} from 'react';
import {CheckIcon, Square2StackIcon} from "@heroicons/react/24/solid";
import {ModalWrapper} from "./index";
import {TextInput} from "../Inputs";
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline";
import {PrimaryButton} from "../Buttons";
import {clsx} from "clsx";

function PromptModal({
                         modalOpen,
                         title,
                         message,
                         confirmButtonText,
                         Icon = Square2StackIcon,
                         iconColor = "gray",
                         iconColorShade = "-500",
                         setModalOpen,
                         onConfirm,
                     }) {



    return (
      <ModalWrapper open={modalOpen} setOpen={setModalOpen}>
        <div className="w-[85vw] lg:w-[35vw]">
          <div className="pb-4 text-center flex items-center flex-col">
            {title}
          </div>
          {message && (
            <div className="pb-8 mx-6 text-center text-sm text-gray-500">
              {message}
            </div>
          )}

          <div className="mt-3 flex flex-row gap-x-4">
            <button
              className={clsx(
                "marker:inline-flex items-center min-w-24 h-[38px]",
                "px-5 border border-transparent shadow-sm rounded-sm shadow-gray-300",
                "justify-center text-sm font-medium focus:outline-none w-full bg-gray-100 border-neutral-400",
                "hover:bg-gray-200",
              )}
              onClick={setModalOpen}
            >
              <span className="whitespace-nowrap text-neutral-900">Cancel</span>
            </button>
            <button
              className={clsx(
                "marker:inline-flex items-center min-w-24 h-[38px]",
                "px-5 border border-transparent shadow-sm rounded-sm shadow-gray-300",
                "justify-center text-sm font-medium focus:outline-none w-full bg-error",
                  "hover:bg-red-500",
              )}
              onClick={() => {
                  onConfirm();
                  setModalOpen();
              }}
            >
              <span className="whitespace-nowrap text-onPrimary">Confirm</span>
            </button>
          </div>
        </div>
      </ModalWrapper>
    );
}

export default PromptModal;