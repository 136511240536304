import { create } from "zustand";
import { JsonTryParse } from "../utils/helpers";
import storage from "../utils/storage";
import useCookies from "../utils/cookieJar";
import {useApi} from "../hooks";
import axios from "axios";
import cookieJar from "../utils/cookieJar";

const useUserStore = create((set, get) => ({
  currentUser: null,
  impersonating: false,
  setCurrentUser: (currentUser) => set(() => ({ currentUser })),
  setImpersonating: (impersonating) => set(() => ({ impersonating })),
  impersonate: (user, token) => {
    if(get().impersonating) return;

    // const storageCurrentUser = storage.getItem(storage.ITEMS.currentUser);
    // const storageCurrentUserJWT = storage.getItem(storage.ITEMS.jwt);
    // storage.setItem(storage.ITEMS.storageCurrentUser, storageCurrentUser);
    // storage.setItem(storage.ITEMS.storageJWT, storageCurrentUserJWT);
    // storage.setItem(storage.ITEMS.currentUser, JSON.stringify(user));
    set(() => ({ impersonating: true, currentUser: user }));
  },
  stopImpersonating: (user) => {
    const storageCurrentUser = storage.getItem(storage.ITEMS.storageCurrentUser);
    // const storageCurrentUserJWT = storage.getItem(storage.ITEMS.storageJWT);
    // storage.setItem(storage.ITEMS.currentUser, storageCurrentUser);
    // storage.setItem(storage.ITEMS.jwt, storageCurrentUserJWT);
    // storage.removeItem(storage.ITEMS.storageCurrentUser);
    // storage.removeItem(storage.ITEMS.storageJWT);
    set(() => ({
      impersonating: false,
      currentUser: user || JsonTryParse(storageCurrentUser),
    }));
  },
  login: (user, token, rememberMe) => {
    set(() => ({ currentUser: user }));
    // cookieJar.setItem(storage.ITEMS.currentUser, JSON.stringify(user));
    // useCookies.setItem("token", token);
    // storage.setItem(storage.ITEMS.currentUser, JSON.stringify(user));
    // storage.setItem(storage.ITEMS.jwt, token);
    if (rememberMe)
      storage.setItem(storage.ITEMS.username, user.username);
    else storage.removeItem(storage.ITEMS.username);
  },
  logout: async () => {

    const response = await axios.delete('/api/public/logout');

    set(() => ({ currentUser: null }));
    // cookieJar.removeItem(storage.ITEMS.currentUser);
    // useCookies.removeItem(useCookies.ITEMS.currentUser);
    storage.removeItem(storage.ITEMS.currentUser);
    storage.removeItem(storage.ITEMS.jwt);
    storage.removeItem(storage.ITEMS.storageCurrentUser);
    storage.removeItem(storage.ITEMS.storageJWT);
    storage.removeItem(storage.ITEMS.tenantStore);
    cookieJar.removeItem(cookieJar.ITEMS.activeSystemTenantId);
    set(() => ({ impersonating: false }));
  },
}));


export const useGetUser = () => useUserStore((state) => state.currentUser);
export const useSetUser = () => useUserStore((state) => state.setCurrentUser);


export default useUserStore;
