import { create } from "zustand";

const useLoaderStore = create((set, get) => ({
  showLoader: false,
  setShowLoader: (showLoader) => {
    if(get().showLoader && showLoader) return;
    set(() => ({ showLoader }));
  },
}));



export default useLoaderStore;
