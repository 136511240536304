import { SwatchesPicker } from "react-color";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Link, useParams } from "react-router-dom";
import { useAlert, useApi, useTenant } from "../../hooks";
import { useLoaderStore, useUserStore } from "../../context";
import { useEffect, useState } from "react";
import {
  ColorPicker,
  FileUpload,
  Label,
  TextInput,
} from "../../components/Inputs";
import useDebounce from "../../hooks/useDebounce";
import { buildUrlWithSubdomain, getFormData } from "../../utils/helpers";
import { PrimaryButton } from "../../components/Buttons";
import useSWR from "swr";
import { isNil } from "lodash/lang";
import { ConfirmationModal } from "../../components/Modals";
import { Grid } from "react-bootstrap-icons";
import {CodeBlock} from "../../components/Displays";
import {useActiveTenant, useTenantTheme} from "../../context/useSystemTenantStore";
const LayoutSectionCol = ({ children }) => {
  return (
    <div className="flex flex-col bg-gray-100 rounded-lg my-4 p-4 gap-4">
      {children}
    </div>
  );
};
const LayoutSectionRow = ({ children }) => {
  return (
    <div className="flex flex-row bg-gray-100 rounded-lg my-4 p-4 gap-4 align-content-center">
      {children}
    </div>
  );
};
const ItemLabel = ({ label }) => {
  return <h3 className="font-semibold">{label}</h3>;
};
function SystemTenantCustomization(props) {
    const activeTenant = useActiveTenant();
    const theme = useTenantTheme();
  const { updateTheme } = useTenant();
  const SYSTEM_TENANT_ID = activeTenant?.id;
  const { fetch, post, postFormData } = useApi();
  const alert = useAlert();
  const { setShowLoader } = useLoaderStore();
  const [customizationSettings, setCustomizationSettings] = useState({
    id: 0,
    systemTenantName: activeTenant?.name,
    primaryHexColor: theme?.primaryHexColor,
    secondaryHexColor: theme?.secondaryHexColor,
    tertiaryHexColor: theme?.tertiaryHexColor,
    header: theme?.header || "",
    subHeader: theme?.subHeader,
  });
  const [proposedSlug, setProposedSlug] = useState();
  const [isValidSlug, setIsValidSlug] = useState(false);
  const { debounced, updateDebounced } = useDebounce("");
  const [pictureFiles, setPictureFiles] = useState([]);
  const [confirmSlug, setConfirmSlug] = useState(false);
  const { currentUser } = useUserStore();

  const { data: defaultCustomizationSettings, isLoading } = useSWR(
    SYSTEM_TENANT_ID
      ? `systemTenant/GetSystemTenantCustomizationInfo/${SYSTEM_TENANT_ID}`
      : null,
    fetch,
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (activeTenant) {
      setShowLoader(true);
      getCustomizationDetails();
    }
  }, [activeTenant]);

  useEffect(() => {
    updateDebounced(proposedSlug);
  }, [proposedSlug]);

  useEffect(() => {
    validateSlug();
  }, [debounced]);

  useEffect(() => {
    if (!isNil(defaultCustomizationSettings)) {
      setCustomizationSettings(defaultCustomizationSettings);
    }
  }, [defaultCustomizationSettings]);

  function getCustomizationDetails() {
    fetch(`systemTenant/GetSystemTenantCustomizationInfo/${SYSTEM_TENANT_ID}`)
      .then((res) => {
        if (res) {
          // setCustomizationSettings(res);
          return;
        }
        alert(
          "Theme settings not found",
          "No theme settings found. Lets create one!",
          "warning",
        );
      })
      .catch((err) => {
        alert("Error", "Unable to fetch customization settings", "error");
      })
      .finally(() => {
        setShowLoader(false);
      });
  }
  function handleChange(field, value) {
    setCustomizationSettings({
      ...customizationSettings,
      [field]: value,
    });
  }
  function validateSlug() {
    if (!debounced) return;
    fetch(`systemTenant/ValidateSlug/${debounced}`)
      .then((res) => {
        if (!res.isValid) {
          alert("Invalid subdomain", res.message, "warning");
        }
        setIsValidSlug(res.isValid);
      })
      .catch((err) => {
        alert(
          "Server error",
          "Unable to validate subdomain at this time",
          "error",
        );
      });
  }
  function saveCustomizationDetails(payload = null) {
    setShowLoader(true);
    post(
      `systemTenant/SaveSystemTenantCustomization/${SYSTEM_TENANT_ID}`,
      payload || customizationSettings,
    )
      .then((res) => {
        // setCustomizationSettings(res);
        saveSystemTenantLogo(res.id);
        updateTheme({
            primaryHexColor: customizationSettings.primaryHexColor,
            secondaryHexColor: customizationSettings.secondaryHexColor,
            tertiaryHexColor: customizationSettings.tertiaryHexColor,
            header: customizationSettings.header,
            subHeader: customizationSettings.subHeader
        });
        alert(
          "Saved theme settings",
          "Saved system tenant theme settings",
          "success",
        );
      })
      .catch((err) => {
        alert("Server error", "Unable to save theme settings", "error");
      })
      .finally(() => {
        setShowLoader(false);
      });
  }
  function saveSystemTenantLogo(passedId) {
    if (!pictureFiles.length) return;
    setShowLoader(true);
    let payload = getFormData(pictureFiles[0], "Photo");
    payload.append("Id", passedId || customizationSettings.id);
    postFormData("systemTenant/SaveSystemTenantLogo", payload)
      .then((res) => {
        // setCustomizationSettings(res);
        setPictureFiles([]);
        alert("Saved logo", "Saved system tenant logo", "success");
      })
      .catch((err) => {
        alert("Server error", "Unable to save system tenant logo", "error");
      })
      .finally(() => {
        setShowLoader(false);
      });
  }
  function handleSave() {
    if (proposedSlug && !customizationSettings.urlSlug) {
      let payload = Object.assign(
        {},
        { ...customizationSettings, urlSlug: proposedSlug },
      );
      saveCustomizationDetails(payload);
      return;
    }
    saveCustomizationDetails();
    // saveSystemTenantLogo();
  }
  return (
    <div className="overscroll-y-auto px-2">
      <LayoutSectionRow>
          {customizationSettings?.photoUrl &&
          (<img
            className="w-8"
            src={customizationSettings?.photoUrl}
            alt="Logo"
          />)}
        <ItemLabel
          label={`${customizationSettings?.id === 0 ? "Create" : "Update"} ${activeTenant?.name} theme settings`}
        />
      </LayoutSectionRow>
      <LayoutSectionCol>
        {customizationSettings?.urlSlug ? (
          <>
            <ItemLabel label={"Url "} />
            <CodeBlock
              text={buildUrlWithSubdomain(customizationSettings?.urlSlug)}
              copyText={"Copy Url"}
            />
          </>
        ) : (
          <div>
            <ItemLabel label={"Url Subdomain"} />
            <TextInput
              // label={"Url Subdomain"}
              name={"urlSlug"}
              value={proposedSlug || ""}
              onChange={(e) => {
                setProposedSlug(e.target.value);
              }}
              Icon={proposedSlug && !isValidSlug ? XMarkIcon : undefined}
              iconColor="error"
              maxLength={20}
              required
            />
          </div>
        )}
      </LayoutSectionCol>

      <LayoutSectionCol>
        <div className="grid grid-cols-10 gap-4">
          <div className="">
            <ItemLabel label={"Homepage Header"} />
          </div>
          <div className="col-span-4">
            <TextInput
              value={customizationSettings.header}
              onChange={(e) => handleChange("header", e.target.value)}
              maxLength={200}
            />
          </div>
          <div className="row-start-2">
            <ItemLabel label={"Homepage SubHeader"} />
          </div>
          <div className="row-start-2 col-span-4">
            <TextInput
              value={customizationSettings.subHeader}
              onChange={(e) => handleChange("subHeader", e.target.value)}
              maxLength={200}
            />
          </div>
        </div>
      </LayoutSectionCol>

      <div className="grid grid-cols-5 gap-2">
        <LayoutSectionCol>
          <div>
            <ItemLabel className="" label={"Color Settings"} />
          </div>
          <div className="flex flex-row justify-between">
            <ItemLabel className="" label={"Primary"} />
            <ColorPicker
              color={customizationSettings?.primaryHexColor}
              onChange={(color, event) => {
                handleChange("primaryHexColor", color.hex);
              }}
            />
          </div>
          <div className="flex flex-row  justify-between">
            <ItemLabel className="" label={"Secondary"} />
            <ColorPicker
              color={customizationSettings?.secondaryHexColor}
              onChange={(color, event) => {
                handleChange("secondaryHexColor", color.hex);
              }}
            />
          </div>
          <div className="flex flex-row justify-between">
            <ItemLabel className="" label={"Tertiary"} />
            <ColorPicker
              color={customizationSettings?.tertiaryHexColor}
              onChange={(color, event) => {
                handleChange("tertiaryHexColor", color.hex);
              }}
            />
          </div>
        </LayoutSectionCol>
        <div className="col-span-4">
          <LayoutSectionCol>
            <ItemLabel label={"Logo"} />
            <FileUpload
              files={pictureFiles}
              setFiles={setPictureFiles}
              allowMultipleFiles={false}
              maxFileCount={1}
            />
          </LayoutSectionCol>
        </div>
      </div>
      {/*<LayoutSectionRow>*/}
      {/*  <div className="flex-1 flex flex-col">*/}
      {/*    <ItemLabel className="" label={"Primary Color"} />*/}
      {/*    <div className="shadow-md rounded-lg overflow-hidden">*/}
      {/*      <SwatchesPicker*/}
      {/*        width={"auto"}*/}
      {/*        color={customizationSettings?.primaryHexColor}*/}
      {/*        onChangeComplete={(color, event) => {*/}
      {/*          handleChange("primaryHexColor", color.hex);*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="flex-1 flex flex-col">*/}
      {/*    <ItemLabel label={"Secondary Color"} />*/}
      {/*    <div className="shadow-md rounded-lg overflow-hidden">*/}
      {/*      /!*<ColorPicker color={customizationSettings?.secondaryHexColor} onChange={(color, event) => {*!/*/}
      {/*      /!*    handleChange("secondaryHexColor", color.hex);*!/*/}
      {/*      /!*}}/>*!/*/}
      {/*      <SwatchesPicker*/}
      {/*        width={"auto"}*/}
      {/*        color={customizationSettings?.secondaryHexColor}*/}
      {/*        onChangeComplete={(color, event) => {*/}
      {/*          handleChange("secondaryHexColor", color.hex);*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="flex-1 flex flex-col">*/}
      {/*    <ItemLabel label={"Tertiary Color"} />*/}
      {/*    <div className="shadow-md rounded-lg overflow-hidden">*/}
      {/*      <SwatchesPicker*/}
      {/*        width={"auto"}*/}
      {/*        color={customizationSettings?.tertiaryHexColor}*/}
      {/*        onChangeComplete={(color, event) => {*/}
      {/*          handleChange("tertiaryHexColor", color.hex);*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</LayoutSectionRow>*/}

      <PrimaryButton
        text={"SAVE"}
        onClick={() => {
          if (proposedSlug) {
            setConfirmSlug(true);
            return;
          }
          handleSave();
        }}
      />
      <ConfirmationModal
        modalOpen={confirmSlug}
        setModalOpen={setConfirmSlug}
        title={"Confirm subdomain"}
        message={`Are you sure you want to set your subdomain to "${proposedSlug}"? This can only be set once.`}
        textToMatch={currentUser.username}
        confirmButtonText={"Confirm"}
        onConfirm={handleSave}
      />
    </div>
  );
}
export default SystemTenantCustomization;
