import {create} from "zustand";


const useSubdomainStore = create(
    (set, get) => ({
      subdomain: null,
        updateSubdomain: (subdomain) => {
            set({subdomain});
        }
    })
);

export const useSubStore = () => useSubdomainStore((state) => state.subdomain);
export const useUpdateSubStore = () => useSubdomainStore((state) => state.updateSubdomain);



const subdomainStore = {
    useSubStore,
    useUpdateSubStore
};

