import { Menu } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import React, {useEffect, useMemo} from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { MENU_ALT_2, MENU_ALT_3 } from "../../assets/images";
import {
  useLayoutStore,
  useUserStore
} from "../../context";
import {useAlert, useApi, useTenant} from "../../hooks";
import constants from "../../utils/constants";
import { classNames } from "../../utils/helpers";
import {CustomPrimaryButton, PrimaryButton} from "../Buttons";
import {SystemTenantNameDisplay} from "../Displays";
import {clsx} from "clsx";
import {useActiveTenant, useTenantTheme} from "../../context/useSystemTenantStore";

export default function TenantTopNavMenu() {
  const { sidebarMenuOpen, setSidebarMenuOpen } = useLayoutStore();
    const activeTenant = useActiveTenant();
    const theme = useTenantTheme();
  const { impersonating, stopImpersonating, currentUser, logout } = useUserStore();
  const navigate = useNavigate();
  const alert = useAlert();
  const { del, fetch } = useApi();

  const handleStopImpersonation = () => {
    del("user/Unimpersonate")
    .then(res => {
      stopImpersonating(res.user);
      alert("Success", "No longer impersonating", "success");
      //Check for and remove slug if in the baseUrl
      let baseUrlArray = window.location.host.split('.');
      if (baseUrlArray[0] === activeTenant?.slug) {
        baseUrlArray = baseUrlArray.slice(1);
      }
      let baseUrl = baseUrlArray.join('.');
      let tenantHome =  `${window.location.protocol}//${baseUrl}`;
      window.location.href = tenantHome;
    })
    .catch(err => {
      alert("Server error", "Error while trying to stop impersonating", "error");
    })
  };

  const profileDropdownItems = useMemo(
    () => currentUser ? [
      {
        href: `/profile/${currentUser.id}`,
        name: "Profile",
      },
      {
        onClick: () => {
          del("public/Logout")
            .then(res => {
              logout();
              if (window.location.pathname === "/") {
                window.location.reload();
              }
            })
            .catch(err => {
              alert("Error", "Unable to perform logout", "error");
            });
        },
        href: "/",
        name: "Sign Out",
      },
    ] : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  if(currentUser) {
    return (
        <div style={{backgroundColor: theme?.primaryHexColor}}
             className={`z-50 mx-auto px-2 sm:px-6 lg:pl-4 lg:pr-8 flex h-16 items-center justify-between fixed w-screen top-0 left-0`}>
          <div className="flex flex-1">
            {/* Sidebar opener/closer */}
            {/* <div className="mr-6 flex items-center space-x-4 cursor-pointer">
              {sidebarMenuOpen ? (
                  <img
                      className="h-8 w-auto"
                      src={MENU_ALT_3}
                      alt="logo"
                      onClick={() => setSidebarMenuOpen(false)}
                  />
              ) : (
                  <img
                      className="h-8 w-auto"
                      src={MENU_ALT_2}
                      alt="logo"
                      onClick={() => setSidebarMenuOpen(true)}
                  />
              )}
            </div> */}

          <SystemTenantNameDisplay/>
            {impersonating && (
                <div
                    className="flex flex-shrink-0 items-center text-sm font-bold underline lg:ml-5 text-white cursor-pointer">
                  <div onClick={handleStopImpersonation}>
                    Stop impersonating {currentUser.username}
                  </div>
                </div>
            )}
          </div>

          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {/* Profile dropdown */}
            <Menu as="div" className="relative ml-3">
              <Menu.Button
                  className="flex rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primaryColor">
                <span className="sr-only">Open user menu</span>
                  <UserCircleIcon  className="w-10" color={theme?.tertiaryHexColor}/>
              </Menu.Button>
              <Menu.Items style={{backgroundColor: theme?.secondaryHexColor}}
                          className="absolute right-0 z-50 w-48 origin-top-right rounded-md py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {profileDropdownItems.map((subItem, i) => (
                    <Menu.Item key={subItem.href + i}>
                      {({active}) => (
                          <Link
                              to={subItem.href}
                              onClick={subItem.onClick}
                              className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                              )}
                          >
                            {subItem.name}
                          </Link>
                      )}
                    </Menu.Item>
                ))}
              </Menu.Items>
            </Menu>
          </div>
        </div>
    )
  } else {
    return (
        <div style={{backgroundColor: theme?.primaryHexColor}}
             className={`z-50 mx-auto px-2 sm:px-6 lg:pl-4 lg:pr-8 flex h-16 items-center justify-between fixed w-screen top-0 left-0`}>
          <div className="flex flex-1">
              <SystemTenantNameDisplay/>
          </div>

            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <button
                    className={clsx("marker:inline-flex items-center min-w-24 h-[38px]",
                        "px-5 border border-transparent shadow-sm rounded-sm shadow-gray-300",
                        "justify-center text-md font-medium focus:outline-none w-full text-white hover:opacity-80",
            
                    )}
                    style={{backgroundColor: theme?.tertiaryHexColor, color: "white"}}
                    onClick={() => navigate("/login")}
                >
                    <span className="whitespace-nowrap text-white">Login</span>
                </button>
            </div>
        </div>
    );
  }

}
