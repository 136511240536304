import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../components/Buttons";
import {isNil} from "lodash/lang";
import {getDomain} from "../utils/helpers";
import {useTenant} from "../hooks";
import {useTenantTheme} from "../context/useSystemTenantStore";

export default function NotFound404() {
  const {  subdomain } = useTenant();
  const theme = useTenantTheme();
  const navigate = useNavigate();


  function handleNavigateToHome() {
    if(isNil(subdomain)) {
      navigate("/");
    } else {
      const loc = getDomain(window.location, subdomain);
      window.location.replace(loc);
    }
  }

  return (
    <>
      <main className="grid h-screen justify-center items-center px-6 lg:px-8 pb-40">
        <div className="text-center">
          <p style={{color: theme?.primaryHexColor}} className="text-base font-semibold text-primaryColor">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Page not found
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <PrimaryButton text="Go back home" onClick={() => handleNavigateToHome()} />
          </div>
        </div>
      </main>
    </>
  );
}
