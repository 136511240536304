import React, {useEffect, useState} from 'react';
import {useAlert, useApi, useTenant} from "../../hooks";
import {PageHeader} from "../../components/Layouts";
import {GoBack, PrimaryButton} from "../../components/Buttons";
import {CollapsibleSection} from "../../components/Cards";
import {NumberInput, TelephoneInput, TextInput} from "../../components/Inputs";
import useSWR from "swr";
import {value} from "lodash/seq";
import {useLoaderStore} from "../../context";
import {isNil} from "lodash/lang";
import {useActiveTenant} from "../../context/useSystemTenantStore";
import { isValidEmail } from "../../utils/helpers";
import { isPossiblePhoneNumber } from "react-phone-number-input";

function AccountDetails({accountDetails, updateAccountDetails, isLoading}) {
    const activeTenant = useActiveTenant();
    const {fetch, post} = useApi();
    const alert = useAlert();
    const {setShowLoader} = useLoaderStore();
    const [account, setAccount] = useState(accountDetails);
    const [selectedCountry, setSelectedCountry] = useState('US');

    const handleChange = (property, value) => {
        setAccount({
            ...account,
            [property]: value,
        });
    };

    useEffect(() => {
        if(!isNil(accountDetails)) {
            setAccount(accountDetails);
        }
    }, [accountDetails]);



    const handleSave = () => {
        if (!isValid() || !activeTenant.id) return;
        setShowLoader(true);
        post(`account/saveAccount/${activeTenant.id}`, account)
            .then((res) => {
                alert("Success", "Saved account", "success");
                // navigate("/accounts");
                updateAccountDetails(account);
            })
            .catch((err) => {
                alert(
                    "Unable to save account",
                    err.data.message || "Please try again",
                    "error"
                );
                console.error(err);
            })
            .finally(() => {
                setShowLoader(false);
            });
    };

    const isValid = () => {
        if (!account.name || !account.name.trim()) {
            alert("Form invalid", "Name must have a value", "warning");
            return false;
        }
        if (!account.email || !account.email.trim() || !isValidEmail(account.email)) {
            alert("Invalid input", "Please enter a valid email", "warning");
            return false;
        }
        if (!account.mobilePhone || !account.mobilePhone.trim()) {
            alert("Invalid input", "Please enter a valid phone number", "warning");
            return false;
        }
        if (!isPossiblePhoneNumber(account.mobilePhone.trim(), selectedCountry)) {
            alert("Invalid input", "Please enter a valid phone number", "warning");
            return false;
        }

        return true;
    };


    return (
        <div className=" w-full h-full">
            {/* Header */}
            {/*<PageHeader*/}
            {/*    title={*/}
            {/*        isCreatingNew || !account ? "Create new account" : `Manage account ${account.id}`*/}
            {/*    }*/}
            {/*/>*/}

            <div className="shadow-md rounded-md shadow-primaryColor/25 h-full">
                {/* Form */}
                {/*<CollapsibleSection title="Info">*/}
                    <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4">
                        <div>
                            <TextInput
                                label="Name"
                                disabled={isLoading}
                                value={account?.name ?? ""}
                                onChange={(e) => handleChange("name", e.target.value)}
                                required
                                maxLength={199}
                            />
                        </div>
                        <div>
                            <TextInput
                                label="Email"
                                disabled={isLoading}
                                value={account?.email ?? ""}
                                onChange={(e) => handleChange("email", e.target.value)}
                                maxLength={150}
                            />
                        </div>
                        <div>
                            <TelephoneInput
                                label="Mobile Phone"
                                name={"MobilePhone"}
                                value={account?.mobilePhone ?? ""}
                                onChange={(e) => handleChange("mobilePhone", e.target.value)}
                                selectedCountry={selectedCountry}
                                setSelectedCountry={setSelectedCountry}
                            />
                        </div>
                        <div>
                            <NumberInput
                                label={"Preferred Billing Day"}
                                name={"preferredPaymentDayOfMonth"}
                                value={account?.preferredPaymentDayOfMonth}
                                disabled={isLoading}
                                onChange={(e) => {
                                    handleChange("preferredPaymentDayOfMonth", e.target.valueAsNumber || null)
                                }}
                                placeholder={1}
                                min={1}
                                max={28}
                            />
                        </div>
                        {/* Put fields here */}
                    </div>
                {/*</CollapsibleSection>*/}

                {/* Submit button */}
                <div className="mt-12 pb-4 px-4">
                    <PrimaryButton
                        text="Save"
                        onClick={handleSave}
                    />
                </div>
            </div>
        </div>
    );
}

export default AccountDetails;