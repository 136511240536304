import {useEffect, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { PrimaryButton, ToggleButton } from "../components/Buttons";
import { CheckboxInput, PasswordInput, TextInput } from "../components/Inputs";
import {useLoaderStore, useSystemTenantStore, useThemeConfig, useUserStore} from "../context";
import {useAlert, useApi, useTenant} from "../hooks";
import constants from "../utils/constants";
import storage from "../utils/storage";
import {useActiveTenant, useTenantActions, useTenantTheme} from "../context/useSystemTenantStore";
import {tryGetSubdomain} from "../utils/helpers";
import {useCookies} from "react-cookie";

export default function Login({isAdmin}) {
  const navigate = useNavigate();
  const alert = useAlert();
  const { post } = useApi();

  const [username, setUsername] = useState(
    storage.getItem(storage.ITEMS.username) ?? ""
  );
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const { login } = useUserStore();
  const { setShowLoader } = useLoaderStore();
  const activeTenant = useActiveTenant();
  const theme = useTenantTheme();
  const themePrimaryStyle = {color: theme?.primaryHexColor};
  const themeSecondaryStyle = {color: theme?.secondaryHexColor};
  const subdomain = tryGetSubdomain();

  const handleSignIn = async () => {
    if (!isValid()) return;
    setShowLoader(true);

    let endpoint = isAdmin ? "public/adminlogin" : "public/login"
    await post(endpoint, { username, password })
      .then((res) => {
        let systemTenants = res.systemTenants || [];
        let user = res.user || {};
        let token = res?.token || undefined;
        // if(systemTenants.length) {
        //   setSystemTenants(systemTenants);
        //   setActiveSystemTenant(systemTenants[0]);
        // }
        login(user, token, rememberMe);
        navigate("/");
      })
      .catch((err) => {
        alert("Unsuccessful login attempt", err.data.message || "Invalid credentials", "error");
        console.error(err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isValid = () => {
    if (!username || !username.trim() || username.trim().length <= 1) {
      alert("Invalid input", "Please enter a username", "warning");
      return false;
    }

    if (!password || !password.trim()) {
      alert("Invalid input", "Please enter a password", "warning");
      return false;
    }

    return true;
  };

  useEffect(() => {
    return () => {
      setPassword("");
      setUsername(storage.getItem(storage.ITEMS.username) ?? "");

    }
  }, []);

  return (
    <div className={"flex justify-items-center align-items-center w-full h-full sm:py-32"}>
      <div className={"w-2/5 h-2/5 m-auto"}>
        <h1 className="text-3xl font-bold text-primaryColor " style={theme ? themePrimaryStyle : null}>
          {theme
              ? `Sign In to ${activeTenant?.name || ''}`
          : `Sign In to ${constants.APP_NAME}`}
        </h1>
        <h5 className="mt-1 mb-3">
          Or create an
          <Link
              to={isAdmin ? "/adminRegistration" : "/register"}
              className="text-primaryColor hover:text-primaryColor/50"
              style={theme ? themePrimaryStyle : null}
          >
            &nbsp;account
          </Link>
        </h5>

        <div className="w-full space-y-6">
          <div>
            <TextInput
                label="Username or Email"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div>
            <PasswordInput
                label="Password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleSignIn();
                }}
            />
            <div className="flex justify-between mt-2">
              <CheckboxInput
                  label="Remember me"
                  name="rememberMe"
                  checked={rememberMe}
                  onChange={() => setRememberMe(x => !x)}
              />
              <Link
                  to="/forgotPassword"
                  className="text-primaryColor hover:text-primaryColor/50 text-sm"
                  style={{color: theme ? theme?.primaryHexColor : null}}
              >
                Forgot my password
              </Link>
            </div>
          </div>
          <PrimaryButton text="Sign In" onClick={handleSignIn}/>
        </div>
      </div>
    </div>
  );
}
