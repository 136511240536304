import { useEffect, useState } from "react";
import {useAlert, useApi, useTenant} from "../../hooks";
import { useLoaderStore, useThemeConfig, useUserStore } from "../../context";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "../../components/Layouts";
import { CollapsibleSection } from "../../components/Cards";
import { JsonTryParse, classNames, formatCurrency } from "../../utils/helpers";
import { RadioGroup } from "@headlessui/react";
import Skeleton from "react-loading-skeleton";
import dateHelpers from "../../utils/dateHelpers";
import _ from "lodash";
import PaymentMethod from "./PaymentMethod";
import AddNewPaymentMethod from "./AddNewPaymentMethod";
import PrimaryButton from "../../components/Buttons/CustomPrimaryButton";
import constants from "../../utils/constants";
import { PaymentMethodModal } from "../../components/Modals";
import {useActiveTenant, useTenantTheme} from "../../context/useSystemTenantStore";

function CustomerPurchase(){
    const navigate = useNavigate();
    let { productId, ownedProductId, isLicense } = useParams();
    const { fetch, post, del } = useApi();
    const activeTenant = useActiveTenant();
    const theme = useTenantTheme();
    const alert = useAlert();
    const { setShowLoader } = useLoaderStore();
    const { currentUser } = useUserStore();
    const [previewDetails, setPreviewDetails] = useState();
    const [parsedTag, setParsedTag] = useState();
    const [customerDetails, setCustomerDetails] = useState();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState(true);
    const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);
    const PRIMARY_COLOR = theme?.primaryHexColor ? `[${theme?.primaryHexColor}]` : undefined;
    const IS_UPGRADING = ownedProductId !== undefined;

    useEffect(()=> {
        setShowLoader(true);
        getCustomerDetails();
    }, []);

    function themeNotLoaded() {
        return !theme ? true : false;
    }

    function getCustomerDetails() {
        if(!currentUser || themeNotLoaded()) return;
        fetch(`User/CustomerDetails`)
        .then(res => {
            if(res.paymentMethods){
                let cardList = res.paymentMethods.paymentMethods.map(x => JsonTryParse(x?.json));
                setPaymentMethods(cardList);
            }
            if(res.customerDetails){
                setCustomerDetails(res.customerDetails);
                if (IS_UPGRADING) {
                    getPreviewUpgradeDetails();
                } else {
                    getPreviewPurchaseDetails(res.customerDetails.stripeCustomerId, res.customerDetails.defaultPaymentMethodId || null);
                }
            }
        })
        .catch(err => {
            alert("Server error", "Unable to fetch customer details", "error");
        })
        .finally(() => {
            setShowLoader(false);
            setIsLoadingPaymentMethods(false);
        });
    };

    function ParseTag(dto) {
        setParsedTag(JsonTryParse(dto.tag) || {});
    }

    function getPreviewPurchaseDetails(stripeCustomerId, paymentMethodId) {
        if(!currentUser || themeNotLoaded()) return;
        let payload = {
            tenantId: currentUser.id,
            productId: productId,
            qty: 1,
            autorenew: isLicense ? true : false,
            stripeCustomerId: stripeCustomerId,
            paymentMethodId: paymentMethodId,
            timezone: currentUser?.timezone,
        };
        post(`product/previewPurchaseProduct/${activeTenant?.id}`, payload)
        .then(res => {
            ParseTag(res);
            setPreviewDetails(res);
            if(res.warnings && res.warnings.length) {
                let warningMessage = res.warnings.join('\n');
                alert("Warning", warningMessage, "warning", false);
            }
        })
        .catch(err => {
            alert("Server error", "Unable to fetch purchase details", "error");
        })
        .finally(() => {
            setShowLoader(false);
        });
    };
    
    function purchaseProduct() {
        if(!currentUser || themeNotLoaded() || !previewDetails || !customerDetails) return;
        let payload = {
            TenantId: currentUser.id,
            ProductId: previewDetails.productId,
            Qty: 1,
            Autorenew: !!isLicense,
            StripeCustomerId: customerDetails.stripeCustomerId,
            PaymentMethodId: customerDetails.defaultPaymentMethodId,
            Timezone: currentUser?.timezone,
            NotifyTenantUsers: true
        };
       post(`product/purchaseProduct/${activeTenant?.id}`, payload)
       .then(res => {
        alert("Product purchased", "Successfully purchased product", "success");
        navigate("/");
       })
       .catch(err => {
            alert("Server error", "Unable to execute purchase", "error");
        })
    };

    function getPreviewUpgradeDetails() {
        if(!currentUser || themeNotLoaded()) return;
        fetch(`product/PreviewUpgradeSubscription/${ownedProductId}/${productId}/${activeTenant?.id}`, { autoRenew: true })
        .then(res => {
            ParseTag(res);
            setPreviewDetails(res);
            if(res.warnings && res.warnings.length) {
                let warningMessage = res.warnings.join('\n');
                alert("Warning", warningMessage, "warning", false);
            }
        })
        .catch(err => {
            alert("Server error", "Unable to fetch purchase details", "error");
        })
        .finally(() => {
            setShowLoader(false);
        });
    };

    function upgradeProduct() {
        if(!currentUser || themeNotLoaded() || !previewDetails || !customerDetails) return;
        let payload = {
            TenantId: currentUser.id,
            OwnedProductId: ownedProductId,
            UpgradeProductId: productId,
            Qty: 1,
            Autorenew: isLicense ? true : false,
            StripeCustomerId: customerDetails.stripeCustomerId,
            PaymentMethodId: customerDetails.defaultPaymentMethodId,
            Timezone: currentUser?.timezone,
            NotifyTenantUsers: true
        };
       post(`product/UpgradeSubscription/${activeTenant?.id}`, payload)
       .then(res => {
        alert("Subscription Upgraded", "Successfully upgraded subscriptions", "success");
        navigate("/");
       })
       .catch(err => {
            alert("Server error", "Unable to execute purchase", "error");
        })
    };

    const savePaymentMethodToCustomer = (paymentMethodId, setAsDefault) => {
        setShowLoader(true);
        post(
          `user/SavePaymentMethod/${activeTenant?.id}`,{
            CustomerId: customerDetails.stripeCustomerId,
            PaymentMethodId: paymentMethodId,
            SetAsDefault: paymentMethods.length === 0 ? true : setAsDefault // if no existing payment methods, auto select default (regardless of user input)
          }
        )
          .then(() => {
            alert("Success", "Saved payment method", "success");
            getCustomerDetails();
            setPaymentMethodModalOpen(false);
          })
          .catch((err) => {
            alert(
              "Error",
              err.message || "An error occurred while attaching the payment method",
              "error"
            );
          })
          .finally(() => {
            setShowLoader(false);
          });
    };

    const removePaymentMethod = (paymentMethodId) => {

        post(`user/DeletePaymentMethod/${activeTenant?.id}`, {
            CustomerId: customerDetails.stripeCustomerId,
            PaymentMethodId: paymentMethodId
        })
          .then(() => {
            getCustomerDetails();
          })
          .catch((err) => {
            alert(
              "Could not remove payment method",
              err?.message || "Error occurred while removing payment method",
              "error"
            );
        });
    };

    const changeToDefaultPaymentMethod = (paymentMethodId) => {
        let payload = {
        SetAsDefault: true,
        PaymentMethodId: paymentMethodId,
        CustomerId: customerDetails.stripeCustomerId
        };
        post(`user/SetPaymentMethodAsDefault/${activeTenant?.id}`, payload)
        .then((res) => {
            getCustomerDetails();
        })
        .catch((err) => {
            alert(
            "Could not set as default payment method",
            err?.message || "Error occurred while saving payment method",
            "error"
            );
        });
    };

    function handlePurchase() {
        if (IS_UPGRADING) {
            upgradeProduct();
            return;
        }
        purchaseProduct();
    }

    return(
    <div>
        <div>
            <PageHeader title="Confirm Purchase" />
        </div>
        {previewDetails &&
        (<CollapsibleSection title={"Purchase Details"}>
            <div className="grid grid-cols-1 gap-4 p-4 bg-white shadow-lg">
                <DetailLine label={"Product Type:"} value={previewDetails.productType}/>
                <DetailLine label={"Product Name:"} value={previewDetails.productName}/>
                <DetailLine label={"Charge Message:"} value={previewDetails.chargeMessage}/>
                <DetailLine label={"Starts Date:"} value={dateHelpers.localDateTime(previewDetails.effectiveAt)}/>
                <DetailLine label={"Expiration Date:"} value={dateHelpers.localDateTime(previewDetails.effectiveThrough)}/>
                <DetailLine label={"Price:"} value={formatCurrency(previewDetails.extendedPrice, true)}/>
                <DetailLine label={"Description:"} value={parsedTag?.description}/>
                <DetailLine label={"Auto-Renew:"} value={previewDetails.autorenew ? "Yes" : "No"}/>
                {/* <AutoRenewalButton theme={theme} previewDetails={previewDetails} setPreviewDetails={setPreviewDetails} /> */}
            </div>
        </CollapsibleSection>)}
        <CollapsibleSection title={"Payment Instruments"}>
            <div className="grid grid-cols-3 gap-4 p-4 bg-white shadow-lg">
                {isLoadingPaymentMethods
                ? _.times(3, (i) => (
                    <Skeleton key={`skeleton-payment=${i}`} height="150px" className="col-span-1"/>
                ))
                : paymentMethods.map((x, i) => (
                    <PaymentMethod
                    key={`payment-method-${i}`}
                    className="col-span-1"
                    paymentMethod={x}
                    isDefault={x.id === customerDetails.defaultPaymentMethodId}
                    refreshData={() => {
                        getCustomerDetails();
                    }}
                    removePaymentMethod={removePaymentMethod}
                    changeToDefaultPaymentMethod={changeToDefaultPaymentMethod}
                    />
                ))}
                {(paymentMethods.length === 0 ||
                paymentMethods.length < constants.MAX_PAYMENT_TYPES) && (
                    <div className="col-span-1">
                        <AddNewPaymentMethod
                            setPaymentMethodModalOpen={setPaymentMethodModalOpen}
                        />
                    </div>)}
            </div>
        </CollapsibleSection>
        { customerDetails?.defaultPaymentMethodId &&
        (<div className="">
            <button 
                className={`marker:inline-flex items-center min-w-24 h-[38px] mix-blend-color",
                "px-5 border border-transparent shadow-sm rounded-lg shadow-gray-300",
                "justify-center text-md font-medium focus:outline-none sm:w-full lg:full bg-primaryColor text-white`}
                style={{backgroundColor: theme?.primaryHexColor}}
                onClick={handlePurchase}
            >
                Confirm
            </button>
        </div>)}   
        {paymentMethodModalOpen && (
            <PaymentMethodModal
                modalOpen={paymentMethodModalOpen}
                existingPaymentMethodCount={paymentMethods?.length || 0}
                refreshData={() => {
                    getCustomerDetails();
                }}
                setModalOpen={setPaymentMethodModalOpen}
                savePaymentMethod={savePaymentMethodToCustomer}
            />
        )}
    </div>);
}

function DetailLine({label, value}) {
    if (!value) return null;
    return (<div className="grid grid-cols-3 gap-4 border-b">
        <div className="lg:col-span-1 sm:col-span-3 font-semibold">{label}</div>
        <div className="lg:col-span-2 sm:col-span-3">{value}</div>
    </div>);
}

function AutoRenewalButton({theme, previewDetails, setPreviewDetails}) {
    return (
        <div className="grid grid-cols-3 gap-4 border-b">
            <div className="lg:col-span-1 sm:col-span-3 font-semibold">Auto-renewal</div>
            <div className="lg:col-span-2 sm:col-span-3">
                <div className="inline-block w-1/10 rounded-lg" onClick={() => {
                        setPreviewDetails({
                        ...previewDetails,
                        ["autorenew"]: !previewDetails.autorenew
                        });
                    }}>
                <RadioGroup
                    value={previewDetails.autorenew}
                    
                    className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white"
                >
                    <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
                    <RadioGroup.Option
                        key={"ro-true"}
                        value={true}
                        className={({ checked }) =>
                            classNames(checked ? `bg-[${theme?.primaryHexColor}]` : `text-[${theme.primaryHexColor}]`, 'cursor-pointer rounded-full px-2.5 py-1 shadow-sm')
                        }
                    >
                        {({ checked }) => (<span className={classNames(checked ? `` : `text-gray-500`, 'cursor-pointer rounded-full px-2.5 py-1')}>True</span>)}
                    </RadioGroup.Option>
                    <RadioGroup.Option
                        key={"ro-false"}
                        value={false}
                        className={({ checked }) =>
                            classNames(checked ? `bg-[${theme?.primaryHexColor}]` : `text-[${theme.primaryHexColor}]`, 'cursor-pointer rounded-full px-2.5 py-1 shadow-sm')
                        }
                    >
                        {({ checked }) => (<span className={classNames(checked ? `` : `text-gray-500`, 'cursor-pointer rounded-full px-2.5 py-1')}>False</span>)}
                    </RadioGroup.Option>
                </RadioGroup>
                </div>
            </div>
        </div>
    );
}
export default CustomerPurchase;