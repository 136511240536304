import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Dispatch, SetStateAction } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { PrimaryButton } from "../../components/Buttons";
import { ToggleInput } from "../../components/Inputs";
import { useLoaderStore, useUserStore } from "../../context";
import { useAlert, useApi, useTenant } from "../../hooks";
import constants from "../../utils/constants";
import { classNames, formatCurrency } from "../../utils/helpers";
import React from "react";
import { ConfirmationModal } from "../../components/Modals";
import { useActiveTenant } from "../../context/useSystemTenantStore";

const { DURATION_TYPES } = constants;

export default function Products({
  products,
  productDetails,
  currentSubscription,
  defaultPaymentMethod,
  selectedDurationType,
  setSelectedDurationType,
  refreshData
}) {
  const alert = useAlert();
  const { setShowLoader } = useLoaderStore();
  // const { activeSystemTenant, activeAccount } = useUserStore();
  const activeTenant = useActiveTenant();
  const { fetch, post } = useApi();
  const { theme } = useTenant();

  const purchaseSubscription = (productId) => {
    if (currentSubscription || !isChangeSubscriptionValid()) return;
      console.log("active account  ----->", activeTenant)
    setShowLoader(true);
    let payload = {
      ProductId: productId,
      Qty: 1,
      Autorenew: true,
      NotifyTenantUsers: true,
    };
    post(
      `account/PurchaseAccountSubscription/${activeTenant?.accountId}`, payload
    )
      .then(() => {
        alert(
          "Successfully purchased subscription",
          "Your subscription has been purchased",
          "success"
        );
        refreshData();
      })
      .catch((err) => {
        console.error(err);
        alert(
          "Could not purchase subscription",
          err?.data?.message || "Unable to complete transaction",
          "error"
        );
      })
      .finally(() => {
        setShowLoader(false);
      });
  }

  const upgradeSubscription = (upgradeProductId) => {
    if (!currentSubscription || !isChangeSubscriptionValid()) return;

    setShowLoader(true);
    let payload = {
      OwnedProductId: currentSubscription.ownedProductId,
      UpgradeProductId: upgradeProductId,
      PaymentMethodId: defaultPaymentMethod.id,
      Qty: 1,
      Autorenew: true,
      NotifyTenantUsers: true,
      // StripeCustomerId: stripeCustomerId, // GETTING FROM SERVER
      // TenantId: tenantId, // GETTING FROM SERVER
    };
    post(`account/UpgradeAccountSubscription/${activeTenant?.accountId}`, payload).then(() => {
        alert(
          "Successfully upgraded subscription",
          "Your subscription has been upgraded",
          "success"
        );
        refreshData();
      })
      .catch((err) => {
        console.error(err);
        alert(
          "Could not upgrade subscription",
          err?.data?.message || "Unable to complete transaction",
          "error"
        );
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const switchSubscription = (renewalProductId) => {
    if (!currentSubscription || !isChangeSubscriptionValid()) return;

    setShowLoader(true);
    post(
      `account/ChangeAccountSubscription/${currentSubscription.ownedProductId}/${renewalProductId}`
    )
      .then(() => {
        alert(
          "Successfully switched subscription",
          "Your subscription has been changed",
          "success"
        );
        refreshData();
      })
      .catch((err) => {
        alert(
          "Could not switch subscription",
          err?.data?.message || "Unable to complete transaction",
          "error"
        );
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const cancelSubscription = () => {
    if (!currentSubscription || !isChangeSubscriptionValid()) return;

    setShowLoader(true);
    post(
      `account/CancelAccountSubscription/${currentSubscription.ownedProductId}`
    )
      .then(() => {
        alert(
          "Successfully cancelled subscription",
          "Your subscription has been cancelled and will not renew at the end of the period",
          "success"
        );
        refreshData();
      })
      .catch((err) => {
        alert(
          "Could not switch subscription",
          err?.data?.message || "Unable to complete transaction",
          "error"
        );
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const isChangeSubscriptionValid = () => {
    if (defaultPaymentMethod === undefined) {
      alert(
        "No default payment method",
        "Create a payment method and set it as your default",
        "warning"
      );
      return false;
    }
    return true;
  };

  const resolveButtonText = (isCurrentSubscription, canUpgrade) => {
    if (isCurrentSubscription) return "Your current plan";
    if (!currentSubscription) return "Purchase";
    return canUpgrade
      ? "Upgrade"
      : "Switch"
  }

  const resolveSystemTenantLimit = (details) => {
    if (typeof details.numberOfSystemTenants === "number"){
      if (details.numberOfSystemTenants === 1) return `${details.numberOfSystemTenants} System Tenant`
      return `Up to ${details.numberOfSystemTenants} System Tenants`;
    }
    return "Unlimited System Tenants";
  }

  return (
    <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
      <div className="flex justify-center mb-12 gap-x-2">
        <ToggleInput
          enabled={selectedDurationType === "Months"}
          setEnabled={() =>
            setSelectedDurationType((x) => (x === "Years" ? "Months" : "Years"))
          }
        />
        {selectedDurationType === "Years" ? "Annual" : "Monthly"}
      </div>
      <div className="grid grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
        {products
          .filter(
            (x) =>
              x.durationType === selectedDurationType//DURATION_TYPES.find(d => d.label === selectedDurationType)?.value //DURATION_TYPES.BY_NAME[selectedDurationType]
          )
          .sort((a, b) => a.price - b.price)
          .map((x, i) => {
            const canUpgrade =
              currentSubscription?.price !== undefined &&
              x.price > currentSubscription.price;
            const isCurrentSubscription = currentSubscription 
              && currentSubscription?.productId === x.productId;
            const details = productDetails && productDetails.length && productDetails.find(p => p.productId === x.productId);
            return (
              <div
                key={x.productId}
                className="py-0 lg:px-8 xl:px-14 lg:py-4 border-2 rounded-lg"
              >
                <div className="flex flex-row justify-between">
                  <h3 className="text-base font-semibold leading-7 text-gray-900">
                    {x.name}
                  </h3>
                  {isCurrentSubscription && (<div className="flex flex-row text-base text-error hover:cursor-pointer hover:text-red-400" onClick={cancelSubscription}>
                      <XCircleIcon className="h-6 w-6 me-1"/>
                      Cancel
                    </div>)}
                </div>
                <p className="text-sm leading-6 text-gray-500">
                  {x.description}
                </p>
                <p className="mt-6 mb-12 flex items-baseline gap-x-1">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">
                    {x.price === 0
                      ? "Free"
                      : formatCurrency(x.price, true)}
                  </span>
                  {x.price > 0 && (
                    <span className="text-sm font-semibold leading-6 text-gray-600">
                      /{selectedDurationType === "Years" ? "year" : "month"}
                    </span>
                  )}
                </p>

                <PrimaryButton
                  text={resolveButtonText(isCurrentSubscription, canUpgrade)}
                  disabled={isCurrentSubscription}
                  onClick={() => {
                    if (isCurrentSubscription || x.productId === undefined)
                      return;
                    else if (!currentSubscription) purchaseSubscription(x.productId);
                    else if (canUpgrade) upgradeSubscription(x.productId);
                    else switchSubscription(x.productId);
                  }}
                />

                <div
                  className={classNames(
                    "flex justify-end mt-0.5 text-xs text-gray-400",
                    isCurrentSubscription ? "invisible" : ""
                  )}
                >
                  <div className="w-3/4 text-right">
                    {canUpgrade ||
                    x.priceInPennies === currentSubscription?.priceInPennies
                      ? "You will be immediately placed onto this plan"
                      : "You will be placed onto this plan at the end of your current subscription period"}
                  </div>
                </div>
                {details && (
                  <ul
                    role="list"
                    className="mt-6 space-y-3 text-sm leading-6 text-gray-600"
                  >
                    <li className="flex gap-x-3">
                      <CheckCircleIcon className="h-6 w-5 flex-none text-primaryColor" style={{color: theme?.primaryHexColor}} />
                      {resolveSystemTenantLimit(details)}
                    </li>
                    <li className="flex gap-x-3">
                      <CheckCircleIcon className="h-6 w-5 flex-none text-primaryColor" style={{color: theme?.primaryHexColor}} />
                      {`${details.transactionFeeAmount}% Transaction fee `}
                    </li>
                  </ul>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}
