import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLayoutStore } from "../../context";
import { classNames } from "../../utils/helpers";
import {
  ShoppingBagIcon,
  SwatchIcon,
  UsersIcon,
  UserGroupIcon,
  HomeIcon,
  Cog8ToothIcon,
  BuildingStorefrontIcon
} from "@heroicons/react/24/solid";
import {useTenant, useTheme} from "../../hooks";
import {useTenantTheme} from "../../context/useSystemTenantStore";

const NAVIGATION_ITEMS = [
  { name: "Dashboard", icon: <HomeIcon />, href: "/", isHome: true },
  { name: "Users", icon: <UsersIcon />, href: "/users" },
  { name: "System Tenants", icon: <BuildingStorefrontIcon />, href: "/systemTenants" },
  { name: "Products", icon: <ShoppingBagIcon />, href: "/products" },
  { name: "Customers", icon: <UserGroupIcon />, href: "/customers" },
  //  { name: "AccountSettings", icon: <QuestionMarkCircleIcon />, href: "/accounts" },
  // Add more nav items here
  // { name: "Developer", icon: <CodeBracketIcon />, href: "/demo" },
  // {
  //   name: "Dropdown",
  //   icon: <ArrowDownCircleIcon />,
  //   href: "/dropdown",
  //   subItems: [
  //     {
  //       name: "Sub category 1",
  //       href: "/dropdown/sub1",
  //     },
  //     {
  //       name: "Sub category 2",
  //       href: "/dropdown/sub2",
  //     },
  //     {
  //       name: "Sub category 3",
  //       href: "/dropdown/sub3",
  //     },
  //   ],
  // },
];

const NavItem = ({ item }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTenantTheme();

  const [showSubItems, setShowSubItems] = useState(false);

  const [sidebarMenuOpen, setSidebarMenuOpen] = useLayoutStore((state) => [
    state.sidebarMenuOpen,
    state.setSidebarMenuOpen,
  ]);

  const isActive = item.isHome
    ? location.pathname === "/"
    : location.pathname.startsWith(item.href) && location.pathname !== "/";

  useEffect(() => {
    if (!sidebarMenuOpen) setShowSubItems(false);
  }, [sidebarMenuOpen]);

  return (
    <Fragment key={item.name + item.href}>
      <button
          title={item.name}
        onClick={() => {
          if (item.subItems) {
            setSidebarMenuOpen(true);
            setShowSubItems((show) => !show);
          } else navigate(item.href);
        }}
        key={item.name}
        className={classNames(
          isActive ? "bg-gray-100" : "text-gray-600 hover:text-gray-700",
          "group flex items-center p-6 h-8 text-sm font-large relative w-full mt-4"
        )}
      >
        {/* Left decoration rectangle*/}
        {isActive && (
          <div style={{backgroundColor: theme.tertiaryHexColor}}
              className=" p-1 absolute left-0 h-full" />
        )}
        {/* Icon */}
        <div
          title={item.name}
          style={{color: isActive ? theme.primaryHexColor : null}}
          className={classNames(
            isActive
              ? "text-gray-700"
              : "text-gray-400 group-hover:text-gray-500",
            "ml-2 mr-3 flex-shrink-0 h-6 w-6"
          )}
          aria-hidden="true"
        >
          {item.icon}
        </div>
        {/* Item name */}
        {sidebarMenuOpen && (
          <div className="mx-2 whitespace-nowrap">{item.name}</div>
        )}
      </button>

      {/* Submenu Items */}
      {showSubItems &&
        item.subItems &&
        item.subItems.map((subItem, i) => {
          const isActiveSubmenu = location.pathname.startsWith(subItem.href);

          return (
            <Link
              key={subItem.href + i}
              to={subItem.href}
              className={classNames(
                isActiveSubmenu ? "text-gray-900" : "",
                "justify-center flex text-sm text-gray-400 hover:text-gray-700 h-6 p-2 items-center"
              )}
            >
              {subItem.name}
            </Link>
          );
        })}
    </Fragment>
  );
};

export default function SideNavMenu() {
  const [sidebarMenuOpen] = useLayoutStore((state) => [state.sidebarMenuOpen]);


  return (
    <Fragment>
      <div
        className={classNames(
          sidebarMenuOpen ? "w-64" : "w-20",
          "fixed top-16 flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white float-left z-10",
            "h-[calc(100vh-4rem)] transform duration-200 shadow-md shadow-r-0.5 shadow-black shadow-spread-0.5",
        )}
      >
        <div className="flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          <nav className="flex-1 space-y-3 bg-white" aria-label="Sidebar">
            {NAVIGATION_ITEMS.map((item, i) => (
              <NavItem key={item.href + i} item={item} />
            ))}
            {/*<NavItem key={'account-settings'}/>*/}
          </nav>
        </div>
        <hr />
        <div>
          <NavItem key={'settings'} item={{name: 'Settings', href: '/settings', icon: <Cog8ToothIcon />}} />
        </div>
      </div>
    </Fragment>
  );
}
