import {SketchPicker, SwatchesPicker} from "react-color";
import {useEffect, useState} from "react";

export default function ColorPicker({color, onChange}) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [intColor, setIntColor] = useState(color);

  function handleClick() {
    setDisplayColorPicker(true);
  }

  function handleClose() {
    setDisplayColorPicker(false);
  }

  function handleChange(color, event) {
      setIntColor(color);
      onChange(color);
  }

    useEffect(() => {
        setIntColor(color);
    }, [color]);


  return (
    <div>
      <div
        onClick={handleClick}
        className={"w-14 h-6 bg-white rounded flex justify-center items-center px-1 py-1 "}
      >
        <div style={{backgroundColor: intColor || null}} className={"w-full h-full rounded"} />
      </div>
      {displayColorPicker ? (
        <div className={"absolute z-10"}>
          <div
            className={"fixed top-0 right-0 bottom-0 left-0"}
            onClick={handleClose}
          />
            <SwatchesPicker onChange={handleChange} color={intColor} />
          {/*<SketchPicker color={intColor} onChange={handleChange} disableAlpha onChangeComplete={(color, event) => console.log(color)}/>*/}
        </div>
      ) : null}
    </div>
  );
}
