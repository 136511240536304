import dateHelpers from "../../utils/dateHelpers";
import { formatCurrency } from "../../utils/helpers";

export default function AmountDue({ amountInPennies, dueOn }) {
  return (
    <div className="p-6 rounded-lg border bg-white border-gray-200 shadow-sm sm:w-1/2 lg:w-1/3">
      <div className="text-4xl font-bold mb-2">
        {formatCurrency(amountInPennies, true)}
      </div>
      <div className="text-gray-500">
        Due by {dateHelpers.toDateString(dueOn)}. Your default payment method
        will be autocharged near this date.
      </div>
    </div>
  );
}
