import {useAlert, useApi, useTenant} from "../../hooks";
import {useNavigate} from "react-router-dom";
import {useLoaderStore} from "../../context";
import {useEffect, useState} from "react";
import {isNil} from "lodash/lang";
import useSWR from "swr";
import Skeleton from "react-loading-skeleton";
import {times} from "lodash";
import {useCookies} from "react-cookie";
import {useActiveTenant} from "../../context/useSystemTenantStore";
import { formatCurrency } from "../../utils/helpers";
import dateHelpers from "../../utils/dateHelpers";



function InvoiceRow({invoice}) {
    return (
        <tr key={invoice.name + invoice.effectiveAt}>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                {invoice.name}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{invoice.productName}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatCurrency(invoice.price, true)}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{dateHelpers.localDateTime(invoice.effectiveAt)}</td>
        </tr>
    );
}

function InvoiceRowLoading({index}) {
    return (
        <tr key={index}>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                <Skeleton/>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <Skeleton/>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <Skeleton/>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <Skeleton/>
            </td>
        </tr>
    );
}

function InvoiceRowNoResults({colSpan}) {
    return (
        <tr key={'no-results-invoice'}>
            <td className="whitespace-nowrap py-4 text-sm text-center font-medium text-gray-900 sm:pl-0" colSpan={colSpan}>
                No Recent Invoices.
            </td>
        </tr>
    );
}

const recentInvoicesUrl = 'systemTenant/RecentInvoices';

export default function RecentInvoices(props) {
    const navigate = useNavigate();
    const alert = useAlert();
    const {post, fetch} = useApi();
    const {setShowLoader} = useLoaderStore();
    const activeTenant = useActiveTenant();
    const [cookie] = useCookies(['activeSystemTenantId']);
    const [canFetchInvoices, setCanFetchInvoices] = useState(false);
    const {data: invoiceData, isLoading, mutate, error} = useSWR(canFetchInvoices ? recentInvoicesUrl : null,
        fetch, {
            revalidateIfStale: true,
            revalidateOnFocus: false,
        });
    const [invoices, setInvoices] = useState([]);
    const SYSTEM_TENANT_ID = activeTenant?.id;
    
    useEffect(()=>{
      mutate();
    }, [activeTenant]);

    useEffect(() => {
        if (!isNil(invoiceData)) {
            setInvoices(invoiceData.list);
        }
    }, [invoiceData]);


    useEffect(() => {
        if(cookie?.activeSystemTenantId && !canFetchInvoices){
            setCanFetchInvoices(true);
        }
    }, [cookie]);


    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Recent Invoices
            </h1>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Customer
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Product
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                {isLoading
                    ? times(5, (index) => <InvoiceRowLoading key={index} index={index}/>)
                    : invoices && invoices.length
                        ? invoices.map((invoice) => (
                        <InvoiceRow key={invoice.id} invoice={invoice} />
                    ))
                        : <InvoiceRowNoResults colSpan={"4"} />
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
}
