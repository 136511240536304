import { CheckCircleIcon, PlusIcon } from "@heroicons/react/24/solid";
import {clsx} from "clsx";

function AddButton({ onClicked, text, icon }) {
  return (
    <button
      type="button"
      onClick={onClicked}
      className={clsx("h-9 w-fit inline-flex items-center gap-x-1.5 border",
          "rounded-md bg-gradient-to-br from-slate-300 to-gray-100",
          "px-2.5 py-0.5 text-sm font-semibold text-gray-700 shadow-sm hover:bg-indigo-500",
          "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
          "focus-visible:outline-indigo-600"
      )}
    >
      {icon ? (
        icon
      ) : (
        <PlusIcon
          className="-ml-0.5 h-4 w-5 text-gray-700"
          aria-hidden="true"
        />
      )}
      {text}
    </button>
  );
}

export default AddButton;
