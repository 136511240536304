import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import {useTenant} from "../../hooks";

export default function CollapsibleSection({
  title,
  startOpen = true,
  headerColor = "primaryColor",
  children,
}) {
  const [isOpen, setIsOpen] = useState(startOpen);
    const { theme } = useTenant();
  return (
    <div className="pb-2">
      <h4
        className={`flex justify-between px-4 py-2 text-white font-semibold bg-${headerColor} cursor-pointer`}
        style={{backgroundColor: theme?.primaryHexColor}}
        onClick={() => setIsOpen((x) => !x)}
      >
        {title}
        {isOpen ? (
          <ChevronDownIcon className="w-5 text-white cursor-pointer" />
        ) : (
          <ChevronUpIcon className="w-5 text-white cursor-pointer" />
        )}
      </h4>
      {isOpen && children}
    </div>
  );
}
