import { LineChart, ProgressBar } from "../../components/Charts";
import constants from "../../utils/constants";
import dateHelpers from "../../utils/dateHelpers";
import {useTenant} from "../../hooks";

export default function CurrentSubscriptionInfo({
  name,
  description,
  maxSystemTenants,
  systemTenantCount,
  isLoading,
  message,
  paymentSummaryData,
  systemTenant,
  selectedInterval,
  setSelectedInterval,
  accountName,
})  {
  const {theme} = useTenant();
  const selectStyle = `z-10 ring-2 ring-[${theme.primaryHexColor}] text-[${theme.primaryHexColor}]`;
  return (
    <div className="flex flex-col justify-between p-6 rounded-lg border bg-white border-gray-200 shadow-sm">
      <div>
        <div>{name}</div>
        <div className="italic text-gray-500 mb-2">{description}</div>

        <div className="space-y-2">
          <ProgressBar
            color="primaryColor"
            count={systemTenantCount}
            max={maxSystemTenants}
            entityDescription="system tenants"
            isLoading={isLoading}
          />
        </div>
      </div>
      <div className="mt-3 grid grid-cols-1 gap-y-3">
        <div className="text-lg font-semibold underline">{`${accountName || ""} Payment Summary`}</div>
        <div className="">
          {paymentSummaryData &&
            (<LineChart 
              data={Object.keys(paymentSummaryData).map(paymentDate => {
                if(!paymentDate) return;
                let label = paymentDate;
                let value = paymentSummaryData[label] / 100;
                return ({ 
                  x: dateHelpers.utcToLocalShortDate(paymentDate), 
                  y: value
                });
              })}
              dataPointLabel={"Payments"} 
            />)}
        </div>
        <div className="inline-flex rounded-md mb-5">
          {constants.INTERVAL_TYPES.map((interval, idx) => {
            let isSelected = interval.value === selectedInterval;
            if(idx === 0) {
              return (
                <button 
                  type="button"
                  className={`rounded-l-lg border-t border-b border-l border-gray-200 bg-white text-sm font-medium px-4 py-2 hover:bg-gray-100 hover:text-primaryColor ${isSelected ? selectStyle : ""}`}
                  onClick={()=>{setSelectedInterval(interval.value)}}
                >
                  {interval.label}
                </button>
              );
            }
            else if(idx === constants.INTERVAL_TYPES.length - 1) {
              return (
                <button 
                  type="button" 
                  className={`rounded-r-lg border border-gray-200 bg-white text-sm font-medium px-4 py-2 hover:bg-gray-100 hover:text-primaryColor ${isSelected ? selectStyle : ""}`}
                  onClick={()=>{setSelectedInterval(interval.value)}}
                >
                  {interval.label}
                </button>
              );
            }
            return (
              <button 
                type="button"
                className={`border-t border-b border-l border-gray-200 bg-white text-sm font-medium px-4 py-2 hover:bg-gray-100 hover:text-primaryColor ${isSelected ? selectStyle : ""}`}
                onClick={()=>{setSelectedInterval(interval.value)}}
              >
              {interval.label}
            </button>
          )})}
        </div>
      </div>
      {message && (
        <div className="flex mt-6 justify-end text-gray-500">{message}</div>
      )}
    </div>
  );
}