import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function PieChart({ data }) {
  const sorted = data.sort((a, b) => b.value - a.value);

  return (
    <Pie
      data={{
        labels: sorted.map((x) => x.label + " (" + x.value + ")"),
        datasets: [
          {
            data: sorted.map((x) => x.value),
            backgroundColor: [
              "rgba(59, 130, 246, 0.2)",
              "rgba(112, 161, 127, 0.2)",
              "rgba(234, 179, 8, 0.2)",
              "rgba(217, 70, 239, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "#3b82f6",
              "#70a17f",
              "#eab308",
              "#d946ef",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      }}
      options={{
        responsive: true,
        plugins: { legend: { position: "bottom" } },
      }}
    />
  );
}

export default PieChart;
