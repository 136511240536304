import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useAdminRegistrationStepStore, useLoaderStore } from "../context";
import { useAlert, useApi } from "../hooks";
import constants from "../utils/constants";
import { useRegistrationStore } from "../context";
import {
  UserRegistrationForm,
  SystemTenantRegistrationForm,
  AccountRegistrationForm,
} from "../components/Forms";
import { useState } from "react";
import { clsx } from "clsx";
import { create } from "zustand";
import { StepStatus } from "../context/useAdminRegistrationStepStore";
import {CheckIcon} from "@heroicons/react/24/solid";
import {ArrowLeftIcon} from "@heroicons/react/20/solid";

function RegistrationStepsHeader() {
  const { steps, currentStep, changeStep } = useAdminRegistrationStepStore();



  function handleStepChange(nextStep) {

    if(nextStep !== currentStep) {
      changeStep(nextStep);
    }
  }

  return (
    <nav aria-label="Progress" className={"w-full"}>
      <ol
        role="list"
        className="divide-y divide-gray-300 border border-gray-300 xs:flex md:divide-y-0"
      >
        {steps.map((step, stepIdx) => {
          const isComplete = step.status === StepStatus.complete;
          const isCurrent = step.status === StepStatus.current;
          const isIncomplete = step.status === StepStatus.incomplete;

          return (
            <li key={step.name} className={clsx("relative xs:flex xs:flex-1", {"cursor-pointer hover:": !isIncomplete})}>
              {!isCurrent ? (
                <div
                    onClick={() => step.status === StepStatus.complete
                        ? handleStepChange(step)
                        : null}
                  className={clsx("group flex items-center", {
                    "w-full": isComplete,
                  })}
                >
                  <span
                    className={clsx(
                      "flex items-center px-2 md:px-6 py-4 text-sm md:text-md  font-medium",
                    )}
                  >
                    <span
                      className={clsx(
                        "flex xs:h-8 xs:w-8 sm:h-10 sm:w-10 flex-shrink-0 items-center justify-center rounded-full",
                        {
                          "bg-primary-600 group-hover:bg-primary-800":
                            isComplete,
                        },
                        {
                          "border-2 border-gray-300 cursor-default":
                            !isComplete,
                        },
                      )}
                    >
                      {isComplete ? (
                        <CheckIcon
                          className="h-3 w-3 md:h-6 md:w-6 text-white"
                          aria-hidden="true"
                        />
                      ) : (
                        <span className="text-gray-500 cursor-default">
                          {step.id}
                        </span>
                      )}
                    </span>
                    <span
                      className={clsx(
                        "ml-2 md:ml-4 text-sm",
                        { "text-primaryColor font-bold": isComplete },
                        {
                          "text-gray-500 font-medium cursor-default":
                            !isComplete,
                        },
                      )}
                    >
                      {step.name}
                    </span>
                  </span>
                </div>
              ) : (
                <div
                  onClick={() => step.status === StepStatus.complete
                      ? handleStepChange(step)
                      : null}
                  className="flex items-center px-2 md:px-6 py-4 text-sm font-medium"
                  aria-current="step"
                >
                  <span className="flex h-8 w-8 md:h-10 md:w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-primary-600">
                    <span className="text-primary-600 font-bold">
                      {step.id}
                    </span>
                  </span>
                  <span className="ml-2 md:ml-4 text-sm font-bold text-primary-600">
                    {step.name}
                  </span>
                </div>
              )}

              {stepIdx !== steps.length - 1 ? (
                <>
                  {/* Arrow separator for lg screens and up */}
                  <div
                    className="absolute right-0 top-0 hidden h-full w-5 md:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-full w-full text-gray-300"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

export default function AdminRegistration() {
  const alert = useAlert();
  const navigate = useNavigate();
  const { post } = useApi();
  const { setShowLoader } = useLoaderStore();
  const { registrationUser, registrationAccount } = useRegistrationStore();
  const { currentStep } = useAdminRegistrationStepStore();
  const RegistrationSteps = [];



  return (
    <div className="bg-white">
      {/* Background color split screen for large screens */}
      {/*<div*/}
      {/*  className="fixed left-0 top-0 h-full w-1/2 bg-white lg:block md:hidden"*/}
      {/*  aria-hidden="true"*/}
      {/*/>*/}
      {/*<div*/}
      {/*  className="fixed right-0 top-0 h-full w-1/2 md:bg-green-500 lg:bg-primaryColor lg:block md:hidden"*/}
      {/*  aria-hidden="true"*/}
      {/*/>*/}
      {/*<header*/}
      {/*    className="relative mx-auto max-w-7xl bg-indigo-900 py-6 lg:grid lg:grid-cols-2 lg:gap-x-16 lg:bg-transparent lg:px-8 lg:pb-10 lg:pt-16">*/}
      {/*    <div className="mx-auto flex max-w-2xl px-4 lg:w-full lg:max-w-lg lg:px-0">*/}

      {/*    </div>*/}
      {/*</header>*/}
      <main className="relative grid w-full grid-cols-1">
        <section
          aria-labelledby="registration-content"
          className="lg:col-start-1 lg:row-start-1  lg:pb-24 lg:pt-0"
        >
          <div
            className={
              "h-11 md:h-15 text-3xl font-bold text-onPrimary bg-primaryColor flex place-items-center justify-center md:justify-start"
            }
          >
            <h3 className={"ml-8"}>{constants.APP_NAME}</h3>
          </div>
          <div
            className={clsx(
              "xs:h-20 md:h-40 flex place-items-start justify-stretch md:w-full",
            )}
          >
            <RegistrationStepsHeader />
          </div>
          <div className={clsx("flex ml-4 items-center space-x-2 py-2 cursor-pointer w-fit group")} onClick={() => navigate('/')}>
            <ArrowLeftIcon className="w-5 h-5 text-primaryColor group-hover:text-primary-800" />
            <span className={'text-primaryColor text-lg  group-hover:text-primary-800'}> Return to Login </span>
          </div>
          {/*<div>*/}
          {/*    Insert back to login*/}
          {/*</div>*/}
          <div className={clsx("flex place-items-center mx-auto w-full")}>
            <div
              className={clsx(
                "flex xs:w-full md:w-1/2 px-0 md:px-4 justify-center place-items-center mx-auto",
              )}
            >
              {currentStep.component}
            </div>
          </div>
        </section>
      </main>
    </div>
    // {/*<div className="flex min-h-full flex-1">*/}
    // {/*    <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">*/}
    // {/*        <div className=" w-full max-w-full lg:w-full">*/}
    // {/*            <div className="absolute top-10 w-full">*/}
    //
    // {/*            </div>*/}
    //
    // {/*            */}
    // {/*        </div>*/}
    // {/*    </div>*/}
    // {/*    <div className="relative hidden w-0 flex-1 lg:block">*/}
    // {/*        <img*/}
    // {/*            className="absolute inset-0 h-full w-full object-cover"*/}
    // {/*            src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"*/}
    // {/*            alt=""*/}
    // {/*        />*/}
    // {/*    </div>*/}
    // {/*    /!*<div className="w-2/4 mt-1 px-2 mx-auto">*!/*/}
    // {/*    /!*  *!/*/}
    // {/*    /!*</div>*!/*/}
    // {/*    /!*<div className="w-2/5 h-4/5">*!/*/}
    // {/*    /!*    <h1 className="text-3xl font-bold text-primaryColor">*!/*/}
    // {/*    /!*        Register with {constants.APP_NAME}*!/*/}
    // {/*    /!*    </h1>*!/*/}
    // {/*    /!*    <h5 className="mt-1 mb-6">*!/*/}
    // {/*    /!*        Or{" "}*!/*/}
    // {/*    /!*        <Link to="/" className="text-primaryColor hover:text-primaryColor/50">*!/*/}
    // {/*    /!*            go to sign in page*!/*/}
    // {/*    /!*        </Link>*!/*/}
    // {/*    /!*    </h5>*!/*/}
    // {/*    /!*    <div>*!/*/}
    // {/*    /!*    *!/*/}
    // {/*    /!*    </div>*!/*/}
    // {/*    /!*</div>*!/*/}
    // {/*</div>*/}
  );
}
