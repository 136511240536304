import { Infinity } from "react-bootstrap-icons";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { classNames } from "../../utils/helpers";
import {useTenant} from "../../hooks";

export default function ProgressBar({
  color,
  entityDescription,
  max,
  count,
  isLoading,
}) {
  const  {theme} = useTenant();
  const isUnlimited = typeof max !== "number";
  const percentage =
    isUnlimited || max <= 0 ? 100 : Math.min((count / max) * 100, 100);
  const percentBarStyle = ``
  return (
    <div>
      <div>
        {count} / {typeof max === "number" ? max : "unlimited"}{" "}
        {entityDescription}
      </div>

      {isLoading ? (
        <Skeleton className="h-4 rounded-md w-full" />
      ) : (
        <div
          className={classNames(
            "h-4 w-full rounded-md shadow-sm bg-backgroundColor border border-gray-200"
          )}
        >
          <div
            style={{ width: `${percentage}%`, backgroundColor: theme?.tertiaryHexColor }}
            className={`h-full rounded-md flex justify-center items-center`}
          >
            {isUnlimited ? <Infinity className="w-4 h-4 text-white" /> : ""}
          </div>
        </div>
      )}
    </div>
  );
}
